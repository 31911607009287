import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import "react-dropzone-uploader/dist/styles.css";

import "./contact.scss";
import GroupImage from "../../assets/image/group-7.svg";
import DropUpload from "../dropupload/dropupload";
import config from "../../config";
import SuccessIcon from "../../assets/image/undraw-confirmation.svg";
import PhoneInput from "../forms/phone-input";
import CustomButton from "../button/button";
import ProductCard from "../product-card/product-card"
import ProductsSelectors from "../../selectors/products";
import { isPhoneValid } from "../../utils/inputs";
import { formatParagraphs } from "../../utils/strings";
import { useLocale } from "../../locale";

function Contact(props) {
    const locales = useLocale();
    const dispatch = useDispatch();

    const { productId } = useParams();

	const contactProduct = useSelector(ProductsSelectors.byId(productId));

    const [phone, setPhone] = useState("");
    const [phoneAreaCode, setPhoneAreaCode] = useState("");

    const [filesUploaded, setFilesUploaded] = useState(true);
    const [contactFiles, setContactFiles] = useState([]);

    const contactForm = useRef();
    const firstnameInput = useRef();
    const lastnameInput = useRef();
    const addressInput = useRef();
    const descriptionInput = useRef();
    const filesInput = useRef();

    const [isSuccess, setIsOpen] = React.useState(false);

    const [isLoading, setLoading] = React.useState(false);

    function setSuccess() {
        setIsOpen(true);
    }

    function openLoading() {
        setLoading(true);
    }

    function closeLoading() {
        setLoading(false);
    }

    function onSubmit(e) {
        const firstname = firstnameInput.current.value;
        const lastname = lastnameInput.current.value;

        if (!firstname || !lastname || !isPhoneValid(phone, phoneAreaCode)) {
            dispatch({
                type: "SET_ERROR",
                value: locales.errors.required.generic,
            });
            e.preventDefault();
            return;
        }

        openLoading();
        setIsOpen(false);

        const files = contactFiles;

        let formData = new FormData();
        formData.append("firstname", firstname);
        formData.append("lastname", lastname);
        formData.append("address", addressInput.current.value);
        formData.append("phone", phone);
        formData.append("description", descriptionInput.current.value);

        if (productId) {
            formData.append("type", "QUOTATION");
            formData.append("productId", +productId);
        }

        for (let i = 0; i < files.length; i++) {
            formData.append("file" + i, files[i]);
        }

        axios
            .post(`${config.api.base_url}/quotes/save/true`, formData, {
                Accept: "*/*",
                "Content-Type": "multipart/form-data",
            })
            .then((res) => {
                closeLoading();
                setSuccess();
                contactForm.current.scrollIntoView();
            });

        e.preventDefault();
    }

    const askNewQuote = () => {
        setIsOpen(false);
        contactForm.current.scrollIntoView();
    };

    return (
        <div
            id="contact"
            ref={contactForm}
            className={
                props.pageData.title !== "landinig"
                    ? "image-contact-background"
                    : ""
            }
        >
            <div className="page-container">
                <div className="row" id="contact-title">
                    <div className="left-image">
                        <img src={GroupImage} alt="Group" />
                    </div>
                    <div className="back-bar image-bar" />
                    <div className="center-block">
                        <h3>{locales.quote.suptitle_web}</h3>
                        <h1>{locales.quote.title_web}</h1>
                    </div>
                    <div className="back-bar" />
                </div>
            </div>
            <div className="page-container">
                <div className="row">
                    <div
                        className={`${
                            props.pageData.title === "landinig"
                                ? "image-background" : ""} main-area ${!!productId ? "no-top-padding" : ""
                        }`}
                    >
                        {contactProduct ? (
                            <ProductCard state={contactProduct} hideControls />
                        ) : null}
                        <div className="form-area">
                            {!isSuccess ? (
                                <form
                                    onSubmit={onSubmit}
                                    encType="multipart/form-data"
                                >
                                    <div className="row">
                                        <div className="form-group half">
                                            <label htmlFor="lastname" required>
                                                {locales.common.name} *
                                            </label>
                                            <input
                                                ref={lastnameInput}
                                                className="form-control"
                                                placeholder="Do"
                                                name="lastname"
                                                id="lastname"
                                            />
                                        </div>
                                        <div className="form-group half">
                                            <label htmlFor="firstname">
                                                {locales.common.firstname} *
                                            </label>
                                            <input
                                                ref={firstnameInput}
                                                className="form-control"
                                                placeholder="Franck"
                                                name="firstname"
                                                id="firstname"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <label htmlFor="address">
                                                {locales.common.address}
                                            </label>
                                            <input
                                                ref={addressInput}
                                                className="form-control"
                                                placeholder={
                                                    locales.common.address
                                                }
                                                name="address"
                                                id="address"
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <label htmlFor="phone">
                                                {
                                                    locales.common
                                                        .main_phone_number
                                                }{" "}
                                                *
                                            </label>
                                            <PhoneInput
                                                value={phone}
                                                onChange={(phone, areaCode) => {
                                                    setPhone(phone);
                                                    setPhoneAreaCode(areaCode);
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <label htmlFor="description">
                                                {
                                                    locales.quote.request
                                                        .description
                                                }
                                            </label>
                                            <textarea
                                                ref={descriptionInput}
                                                name="description"
                                                id="description"
                                                className="form-control"
                                                placeholder={
                                                    locales.quote.request
                                                        .instruction1
                                                }
                                            />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group">
                                            <label>
                                                {
                                                    locales.quote.request
                                                        .instruction2
                                                }
                                            </label>
                                            <DropUpload
                                                name="files"
                                                customRef={filesInput}
                                                accept="image/*,.pdf"
                                                onUpload={(value) =>
                                                    setFilesUploaded(value)
                                                }
                                                files={contactFiles}
                                                setFiles={setContactFiles}
                                            />
                                        </div>
                                    </div>
                                    <div className="text-center">
                                        {filesUploaded ? (
                                            <CustomButton
                                                type="primary"
                                                height="48px"
                                                isSubmit
                                                loading={isLoading}
                                            >
                                                {locales.shop.ask_quote}
                                            </CustomButton>
                                        ) : null}
                                    </div>
                                </form>
                            ) : (
                                <div className="success-form">
                                    <div className="icon-area">
                                        <div className="icon-content">
                                            <img src={SuccessIcon} alt="" />
                                        </div>
                                    </div>
                                    <div className="text-area">
                                        {formatParagraphs(
                                            locales.quote.result.info1
                                        )}
                                    </div>
                                    <div className="button-area">
                                        <button
                                            onClick={askNewQuote}
                                            className="btn-primary"
                                        >
                                            {locales.quote.result.ask_new_quote}
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
