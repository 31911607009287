import { createSelector } from "reselect";

const getQuotes = state => state.user.quotes;
const getQuotesSort = state => state.user.filters.quotes.sort;

export default {
	applyFilters: createSelector(
		getQuotes,
		getQuotesSort,
		(quotes, quotesSort) => {
			const filteredQuotes = quotes.filter(quote => quote.type === "FROM_CART");
			if (quotesSort === "DATE_DESC") {
				return [...filteredQuotes].sort((a, b) => a.creationDate > b.creationDate ? -1 : a.creationDate < b.creationDate ? 1 : 0);
			} else if (quotesSort === "DATE_ASC") {
				return [...filteredQuotes].sort((a, b) => a.creationDate < b.creationDate ? -1 : a.creationDate > b.creationDate ? 1 : 0);
			} else {
				return filteredQuotes;
			}
		}
	)
};
