import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./choice.scss";
import CustomButton from "../../button/button";
import MasterCard from "../../../assets/image/master-card.svg";
import ExpressCard from "../../../assets/image/american-express.svg";
import Visa from "../../../assets/image/visa.svg";
import VisaElectron from "../../../assets/image/visa-electron.svg";
import Routes, { get } from "../../../services/api";
import OrdersSelectors from "../../../selectors/orders";
import { EUR_TO_XOF } from "../../../constants/payment";
import { useLocale } from "../../../locale";

export default function PaymentChoice(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const user = useSelector(state => state.user);
	const orderData = useSelector(state => OrdersSelectors.orderData(state));

	useEffect(() => {
		if (user.data.token) {
			get(Routes.users.get.creditData(user.data.userId), data => {
				dispatch({
					type: "SET_USER_CREDIT_DATA",
					value: data.creditData
				});
			});
		}
	}, []);

	return (
		<div id="payment-choice">
			<h1 className="page-title">{locales.payment.mode.title}</h1>
			<p className="page-description">{locales.payment.mode.instruction1}</p>
			<div className="form-container">
				{user.data.token && user.creditData.hasCreditAccount ? (
					<CustomButton
						type="primary"
						width="100%"
						height="48px"
						onClick={props.handles.nextAction}
						value="paymentReview"
					>
						{locales.payment.credit_balance.title}
					</CustomButton>
				) : null}
				<CustomButton
					type="primary"
					width="100%"
					height="48px"
					onClick={props.handles.nextAction}
					value="payment"
				>
					{locales.payment.card.title}
				</CustomButton>
				{orderData.totalTTC < Math.floor(500 * EUR_TO_XOF) ? (
					<CustomButton
						type="primary"
						width="100%"
						height="48px"
						onClick={props.handles.nextAction}
						value="mobile"
					>
						{locales.payment.mobile.title}
					</CustomButton>
				) : null}
				<CustomButton
					type="primary"
					width="100%"
					height="48px"
					onClick={props.handles.nextAction}
					value="confirm"
				>
					{locales.payment.shop.title}
				</CustomButton>
			</div>
			<div className="payment-label">
				<img
					src={Visa}
					style={{ paddingLeft: "5px", paddingRight: "5px" }}
					alt=""
				/>
				<img src={MasterCard} alt="" />
				<img
					src={VisaElectron}
					style={{ paddingLeft: "5px", paddingRight: "5px" }}
					alt=""
				/>
			</div>
		</div>
	);
}
