import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import "./devis.scss";
import OrdersFilter from "../../quotes-filter/orders-filter";
import HourglassHalfIcon from "../../../assets/image/hourglass-half.svg";
import CustomButton from "../../button/button";
import Step from "./step";
import Button from "@material-ui/core/Button";
import Routes, { get } from "../../../services/api";
import OrdersSelectors from "../../../selectors/orders";
import { useLocale } from "../../../locale";
import { getOrderData } from "../../../utils/orders";
import { formatPrice } from "../../../utils/numeric";
import { formatDate } from "../../../utils/dates";
import config from "../../../config";

const StatusBadge = ({ status }) => {
    const locales = useLocale();

    return (
        <div>
            {
                {
                    AWAITING: (
                        <span className="badge badge-primary">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;{locales.quotes.badges.awaiting}
                        </span>
                    ),
                    PREPARING: (
                        <span className="badge badge-warning">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;{locales.quotes.badges.processing}
                        </span>
                    ),
                    DELIVERING: (
                        <span className="badge badge-green">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;{locales.orders.badges.delivering}
                        </span>
                    ),
                    DELIVERED: (
                        <span className="badge badge-light-green">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            {locales.orders.badges.delivered}
                        </span>
                    ),
                }[status]
            }
        </div>
    );
};

export default function Order(props) {
    const dispatch = useDispatch();
    const locales = useLocale();

    const user = useSelector((state) => state.user.data);
    const orders = useSelector((state) => OrdersSelectors.applyFilters(state));

    const [currentOrderId, setCurrentOrderId] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    const [displayedOrdersCount, setDisplayedOrdersCount] = useState(20);
    const [hasMoreOrders, setHasMoreOrders] = useState(orders.length > 20);

    useEffect(() => {
        get(Routes.orders.get.byUser(user.userId), (data) => {
            dispatch({ type: "SET_USER_ORDERS", value: data.orders });
        });
    }, []);

    useEffect(() => {
        setCurrentOrderId(orders[0] ? orders[0].orderId : null);
    }, [orders]);

    const currentOrder = orders.find(
        (order) => order.orderId === currentOrderId
    );

    const displayedOrders = orders.slice(0, displayedOrdersCount);

    const showMoreOrders = () => {
        if (displayedOrders.length >= orders.length) {
            setHasMoreOrders(false);
        } else {
            setDisplayedOrdersCount((prev) => prev + 10);
        }
    };

    const orderData = currentOrder
        ? getOrderData(
              currentOrder.products || [],
              currentOrder.coupon,
              currentOrder
          )
        : null;

    const deliveryTypeLabel = currentOrder
        ? {
              TO_ADDRESS: locales.delivery.to_address.title,
              PICKUP_SHOP: locales.delivery.pickup_shop.title,
              PICKUP_WORKSHOP: locales.delivery.pickup_workshop.title,
              CLICK_AND_COLLECT: locales.delivery.click_and_collect.title,
          }[currentOrder.deliveryType] || ""
        : "";

    return (
        <div id="devis">
            <div className="page-container">
                <div className="row">
                    {orders.length >= 1 && (
                        <div className="left">
                            <div className="right-nav-area">
                                <CustomButton
                                    onClick={() =>
                                        setShowFilters((prev) => !prev)
                                    }
                                    width="100%"
                                    height="48px"
                                    type="glass"
                                >
                                    {locales.common.sort}
                                </CustomButton>
                                {showFilters && <OrdersFilter />}
                                <div className="nav-actions">
                                    <InfiniteScroll
                                        dataLength={displayedOrders.length}
                                        next={showMoreOrders}
                                        hasMore={hasMoreOrders}
                                    >
                                        {displayedOrders.map((order, key) => (
                                            <Button
                                                key={key}
                                                onClick={() =>
                                                    setCurrentOrderId(
                                                        order.orderId
                                                    )
                                                }
                                                style={
                                                    order.orderId ===
                                                    currentOrderId
                                                        ? {
                                                              background:
                                                                  "rgba(0,0,0,0.04)",
                                                          }
                                                        : {}
                                                }
                                            >
                                                <div className="action-button">
                                                    <div>
                                                        <h4>
                                                            {
                                                                locales.common
                                                                    .order
                                                            }{" "}
                                                            #{order.orderId}
                                                        </h4>
                                                        {Number.isFinite(
                                                            order.totalTTC
                                                        ) ||
                                                        Number.isFinite(
                                                            order.totalEstimate
                                                        ) ? (
                                                            <p>
                                                                {formatDate(
                                                                    order.datetime
                                                                )}{" "}
                                                                –{" "}
                                                                {formatPrice(
                                                                    order.totalTTC
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                {formatDate(
                                                                    order.datetime
                                                                )}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <StatusBadge
                                                        status={
                                                            order.deliveryStatus
                                                        }
                                                    />
                                                </div>
                                            </Button>
                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="right">
                        {currentOrder ? (
                            <div className="billing-cart">
                                <StatusBadge
                                    status={currentOrder.deliveryStatus}
                                />
                                <h3 className="cart-title">
                                    {locales.common.order} #
                                    {currentOrder.orderId}
                                </h3>
                                {currentOrder.deliveryStatus === "AWAITING" ||
                                currentOrder.paymentStatus !== "AUTHORISED" ? (
                                    <p className="cart-description">
                                        {
                                            locales.dashboard.orders.unpaid
                                                .instruction1_no_address
                                        }
                                    </p>
                                ) : (
                                    <Step
                                        currentStep={[
                                            "DELIVERED",
                                            "AWAITING",
                                            "PREPARING",
                                            "DELIVERING",
                                        ].indexOf(currentOrder.deliveryStatus)}
                                        deliveryType={currentOrder.deliveryType}
                                        deliveryAddress={{
                                            lat:
                                                currentOrder.deliveryAddressLat,
                                            lng:
                                                currentOrder.deliveryAddressLng,
                                        }}
                                    />
                                )}
                                <div className="product-detail">
                                    <h6 className="section-title">
                                        {locales.orders.your_order}
                                    </h6>
                                    <div className="section-content">
                                        {orderData
                                            ? orderData.products.map(
                                                  (item, key) => (
                                                      <div
                                                          key={key}
                                                          className="item"
                                                      >
                                                          <div className="item-left">
                                                              <Button
                                                                  style={{
                                                                      padding:
                                                                          "0px",
                                                                  }}
                                                                  disabled
                                                              >
                                                                  <img
                                                                      src={`${config.files.productImages}/${item.image1}`}
                                                                      alt=""
                                                                  />
                                                              </Button>
                                                              <div>
                                                                  <p className="product-title">
                                                                      {
                                                                          item.name
                                                                      }
                                                                  </p>
                                                                  <p className="product-detail">
                                                                      <img
                                                                          className="brand-image"
                                                                          src={`${config.files.brands}/${item.logo}`}
                                                                          alt=""
                                                                      />
                                                                      <span className="reference">
                                                                          REF:{" "}
                                                                          {
                                                                              item.reference
                                                                          }
                                                                      </span>
                                                                  </p>
                                                              </div>
                                                          </div>
                                                          <div className="item-right">
                                                              <span className="quantity">
                                                                  {item.quantity.toString()}
                                                                  x
                                                              </span>
                                                              &nbsp;
                                                              <span className="price">
                                                                  {formatPrice(
                                                                      item.effectivePrice,
                                                                      true,
                                                                      null
                                                                  )}
                                                                  <small>
                                                                      {" "}
                                                                      XOF
                                                                  </small>
                                                              </span>
                                                          </div>
                                                      </div>
                                                  )
                                              )
                                            : null}
                                    </div>
                                </div>
                                <div className="order-detail">
                                    <h6 className="section-title">
                                        {deliveryTypeLabel}
                                    </h6>
                                    <div className="section-content">
                                        <div className="address-content">
                                            {currentOrder.address
                                                .userAddressName ? (
                                                <h4 className="address-title">
                                                    {
                                                        currentOrder.address
                                                            .userAddressName
                                                    }
                                                </h4>
                                            ) : null}
                                            <p className="address">
                                                {currentOrder.address.address}
                                            </p>
                                            {currentOrder.address.phone1 ? (
                                                <p className="phone-number">
                                                    {locales.common.number1} :{" "}
                                                    {
                                                        currentOrder.address
                                                            .phone1
                                                    }
                                                </p>
                                            ) : null}
                                            {currentOrder.address.phone2 ? (
                                                <p className="phone-number">
                                                    {locales.common.number2} :{" "}
                                                    {
                                                        currentOrder.address
                                                            .phone2
                                                    }
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                <div className="billing-detail">
                                    <div className="price-row">
                                        <span>
                                            {locales.orders.delivery_fees}
                                        </span>
                                        <p className="dark">
                                            {locales.common.free}
                                        </p>
                                    </div>
                                    {currentOrder.coupon ? (
                                        <div className="price-row border-row">
                                            <div>
                                                <span>
                                                    {locales.orders.coupon}
                                                </span>
                                                <p>
                                                    {
                                                        currentOrder.coupon
                                                            .couponName
                                                    }
                                                </p>
                                            </div>
                                            <p className="dark">
                                                -{" "}
                                                {formatPrice(
                                                    orderData.couponEffectiveDiscount,
                                                    true
                                                )}
                                            </p>
                                        </div>
                                    ) : null}
                                    <div className="price-row">
                                        <span>
                                            {locales.orders.total.to_pay}
                                        </span>
                                        <p>{formatPrice(orderData.totalTTC)}</p>
                                    </div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
