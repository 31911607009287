import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "./checkout.scss";

import CheckoutHeader from "./header/header";
import Shipping from "./shipping/shipping";
import ShippingAddress from "./shipping/address";
import CollectShipping from "./shipping/collect-shipping";
import Pickup from "./shipping/pickup";
import Withdrawal from "./shipping/withdrawal";
import OrderReview from "./payment/order-review";
import PaymentChoice from "./payment/choice";
import Payment from "./payment/payment";
import PaymentConfirm from "./payment/confirm";
import PaymentMobile from "./payment/mobile";
import PaymentReview from "./payment/payment-review";
import PaymentSuccess from "./payment/success";

const Steps = {
	shipping: {},
	address: {
		source: "shipping"
	},
	collectShipping: {
		source: "shipping"
	},
	pickup: {
		source: "shipping"
	},
	withdrawal: {
		source: "shipping"
	},
	orderReview: {},
	paymentChoice: {
		source: "orderReview"
	},
	payment: {
		source: "paymentChoice"
	},
	confirm: {
		source: "paymentChoice"
	},
	mobile: {
		source: "paymentChoice"
	},
	paymentReview: {
		source: "paymentChoice"
	},
	paymentSuccess: {}
};

export default function Checkout(props) {
	const dispatch = useDispatch();
	const history = useHistory();

	const [state, setState] = useState({
		currentScreen: props.initialScreen || "shipping"
	});

	const handles = {
		nextAction: event => {
			const deliveryType = {
				address: "TO_ADDRESS",
				pickup: "PICKUP_SHOP",
				withdrawal: "PICKUP_WORKSHOP",
				collectShipping: "CLICK_AND_COLLECT"
			}[event.target.value];

			if (deliveryType) {
				dispatch({ type: "SET_DELIVERY_TYPE", value: deliveryType });

				if (["PICKUP_SHOP", "PICKUP_WORKSHOP"].includes(deliveryType)) {
					dispatch({
						type: "SET_DELIVERY_ADDRESS",
						value: {
							address: "COCODY, RIVIERA PALMERAIE SAINT VIATEUR, Abidjan, Cote d'ivoire",
							phone1: "0786460320"
						}
					});
				}
			}

			setState({ currentScreen: event.target.value });
		},
		setCurrentScreen: (newCurrentScreen) => {
			setState({ currentScreen: newCurrentScreen });
		},
		goBack: () => {
			const step = Steps[state.currentScreen];
			if (step && step.source) {
				setState({ currentScreen: step.source });
			} else {
				history.push("/");
			}
		}
	};

	return (
		<div id="checkout">
			<CheckoutHeader onGoBack={handles.goBack} />
			<div className="page-container">
				{
					{
						shipping: <Shipping handles={handles} state={state} />,
						address: <ShippingAddress handles={handles} state={state} />,
						collectShipping: (
							<CollectShipping handles={handles} state={state} />
						),
						pickup: <Pickup handles={handles} state={state} />,
						withdrawal: <Withdrawal handles={handles} state={state} />,
						orderReview: <OrderReview handles={handles} state={state} />,
						paymentChoice: <PaymentChoice handles={handles} state={state} />,
						payment: <Payment handles={handles} state={state} />,
						confirm: <PaymentConfirm handles={handles} state={state} />,
						mobile: <PaymentMobile handles={handles} state={state} />,
						paymentReview: <PaymentReview handles={handles} state={state} />,
						paymentSuccess: <PaymentSuccess handles={handles} state={state} />
					}[state.currentScreen]
				}
			</div>
		</div>
	);
}
