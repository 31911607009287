import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Header1 from "../components/header/header1";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";
import BlogCart from "../components/blog-cart/blog-cart";
import CustomButton from "../components/button/button";
import ProductsTile from "../components/products-tile/products-tile";
import NewsSelectors from "../selectors/news";
import { useProductsSearch } from "../utils/products";
import { useLocale } from "../locale";

export default function ArticleCategoryPage() {
    const locales = useLocale();

    const newsCategories = useSelector((state) =>
        NewsSelectors.categories(state)
    );

    const { search, products } = useProductsSearch();

    return search ? (
        <>
            <Header1 />
            <ProductsTile products={products} style={{ marginTop: 40 }} />
            <Footer />
        </>
    ) : (
        <>
            <Header1 />
            <Breadcrumb />
            <div
                id="products"
                style={{ marginBottom: "35px", marginTop: "-15px" }}
            >
                <div className="page-container">
                    <div className="row">
                        <div className="center-block">
                            <h3>{locales.news.suptitle_web}</h3>
                            <h1>{locales.news.title_web}</h1>
                        </div>
                        <div className="back-bar" />
                    </div>
                </div>
            </div>
            {newsCategories.map((category, key) => (
                <div key={key}>
                    <div
                        style={{
                            paddingLeft: "16px",
                            paddingRight: "16px",
                            marginBottom: "41px",
                        }}
                    >
                        <div className="page-container">
                            <div
                                className="row"
                                style={{
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <h2
                                    style={{
                                        fontWeight: "bold",
                                        margin: "10px",
                                    }}
                                >
                                    {category.newsCategoryName}
                                </h2>
                                <Link
                                    to={`/sub-actualites/${category.newsCategoryId}-${category.newsCategorySlug}`}
                                >
                                    <CustomButton
                                        type="transparent"
                                        width="172px"
                                        height="26px"
                                        style={{
                                            fontSize: "12px",
                                            margin: "10px",
                                        }}
                                    >
                                        {locales.news.see_all}
                                    </CustomButton>
                                </Link>
                            </div>
                            <div
                                className="row"
                                style={{ justifyContent: "center" }}
                            >
                                {category.news.map((item, key) => (
                                    <BlogCart key={key} item={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            <IconActions />
            <Footer />
        </>
    );
}
