import React from 'react';
import Header1 from '../components/header/header1';
import Addresses from '../components/account/addresses/addresses';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';

export default function AccountAddressesPage() {
	return (
		<>
			<Header1 />
			<Addresses />
			<IconActions />
			<Footer />
		</>
	)
}