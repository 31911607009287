import React from "react";
import "./iconActionSmall.scss";

export default function IconActionSmall(props) {
    return (
        <div className="icon-action-small">
            <img src={props.item.icon} alt="" />
            <p>{props.item.title1}</p>
            {props.item.title2 ? <p>{props.item.title2}</p> : null}
        </div>
    );
}
