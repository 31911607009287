import React from "react";
import { Link } from "react-router-dom";
import "./header.scss";

import LogoImage from "../../../assets/image/group-60.svg";
import BackIcon from "../../../assets/image/icons-back-black.svg";
import { IconButton } from "@material-ui/core";

export default function CheckoutHeader({ onGoBack }) {
    return (
        <div id="checkout-header">
            <div className="page-container">
                <div className="position-left">
                    <IconButton onClick={onGoBack}>
                        <img src={BackIcon} alt="" width="32" />
                    </IconButton>{" "}
                    <span className="back-description">Étape précédente</span>
                </div>
                <Link to="/">
                    <div className="logo">
                        <img src={LogoImage} alt="" />
                    </div>
                </Link>
            </div>
        </div>
    );
}
