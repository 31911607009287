import React from 'react';
import Header1 from '../components/header/header1';
import AddressNew from '../components/account/address-new/address-new';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';
export default function AddressNewPage() {
	return (
		<>
			<Header1 />
			<AddressNew />
			<IconActions />
			<Footer />
		</>
	)
}