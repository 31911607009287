import React from "react";

const formatText = text =>
	text.split("\n").map((line, index, lines) => (
		<React.Fragment key={index}>
			{line}
			{index < lines.length - 1 && (<br />)}
		</React.Fragment>
	));

// make valid new lines and other operations if necessary
const renderAsTextComponent = (locale) => {
	return locale.split("\n").reduce((acc, textPart, index) => [...acc, ...(index === 0 ? [] : [<br key={index} />]), textPart], []);
};

const formatParagraphs = (text = "") => text.split("\n\n").map((textPart, index) => <p key={index}>{textPart}</p>);

const ellipsize = (text = "", maxLength) => `${text.substring(0, maxLength)}${text.length > maxLength ? "..." : ""}`

// example : emphasizeWord("a b c", "b") => a <strong>b</strong> c
const emphasizeWord = (text = "", wordToEmphasize) => {
	return text.split(" ").reduce((acc, word, index) => [
		...acc,
		index > 0 ? " " : "",
		word === wordToEmphasize ? (<strong key={index}>{word}</strong>) : word
	], "");
};

export {
	formatText,
	renderAsTextComponent,
	formatParagraphs,
	ellipsize,
	emphasizeWord
};
