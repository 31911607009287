import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./collect-shipping.scss";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import CustomButton from "../../button/button";

import Routes, { get } from "../../../services/api";
import { useLocale } from "../../../locale";

const CollectLabel = props => {
	return (
		<div className="label">
			<h3 className="label-title">{props.title}</h3>
			<p className="label-description">{props.description}</p>
		</div>
	);
};

export default function CollectShipping(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const selectedDeliveryPartnerId = useSelector(
		state => state.order.deliveryPartnerId
	);

	const [deliveryPartners, setDeliveryPartners] = useState([]);

	useEffect(() => {
		get(Routes.deliveryPartners.get.all(), data => {
			setDeliveryPartners(data.deliveryPartners);
		});
	}, []);

	const setSelectedDeliveryPartnerId = deliveryPartnerId => {
		dispatch({
			type: "SET_DELIVERY_PARTNER",
			value: parseInt(deliveryPartnerId)
		});
	};

	const goToNext = event => {
		const selectedDeliveryPartner = deliveryPartners.find(
			partner => partner.deliveryPartnerId === selectedDeliveryPartnerId
		);

		if (selectedDeliveryPartner) {
			dispatch({
				type: "SET_DELIVERY_ADDRESS",
				value: {
					address: selectedDeliveryPartner.address
				}
			});

			props.handles.nextAction(event);
		} else {
			dispatch({ type: "SET_ERROR", value: locales.delivery.click_and_collect.required });
		}
	};

	return (
		<div id="collect-shipping">
			<div className="form-container">
				<h1>{locales.delivery.click_and_collect.title}</h1>
				<p>
					{locales.delivery.click_and_collect.instruction1}
				</p>
				<div className="row">
					<FormControl component="fieldset">
						<RadioGroup
							row
							aria-label="position"
							name="position"
							defaultValue="top"
							value={selectedDeliveryPartnerId}
							onChange={e => setSelectedDeliveryPartnerId(e.target.value)}
						>
							{deliveryPartners.map((item, key) => (
								<FormControlLabel
									key={key}
									value={item.deliveryPartnerId}
									control={
										<Radio color="primary" style={{ color: "#eec400" }} />
									}
									label={
										<CollectLabel
											title={item.partnerName}
											description={item.address}
										/>
									}
									labelPlacement="start"
								/>
							))}
						</RadioGroup>
					</FormControl>
				</div>
				<div className="action-container">
					<CustomButton
						type="primary"
						width="362px"
						height="48px"
						onClick={goToNext}
						value="orderReview"
					>
						{locales.common.access_summary}
					</CustomButton>
				</div>
			</div>
		</div>
	);
}
