import axios from "axios";

import config from "../config";
import { store } from "../reducers/index";
import { getLocaleFromStringKey } from "../utils/locales";

const Routes = {
	products: {
		get: {
			all: () => ({
				url: "/products",
                noCache: true
			}),
			byIds: (ids) => ({
				url: `/products/?productIds=${ids.join(",")}`
			}),
            withDiscounts: userId => ({
				url: `/products?withDiscountsUserId=${userId}`,
                noCache: true
			})
		}
	},
	categories: {
		get: {
			all: () => ({
				url: "/categories"
			})
		}
	},
	subCategories: {
		get: {
			all: () => ({
				url: "/subcategories",
				data: {
					shop: "IVORIAN"
				}
			}),
			byCategory: categoryId => ({
				url: "/subcategories/by-category",
				data: {
					shop: "IVORIAN",
					categoryId
				}
			})
		}
	},
	brands: {
		get: {
			all: () => ({
				url: `/brands`
			}),
		}
	},
	reviews: {
		get: {
			all: () => ({
				url: `/reviews`
			}),
		}
	},
	users: {
		get: {
			addresses: userId => ({
				url: `/users/addresses?`,
				data: {
					userId
				}
			}),
			creditData: userId => ({
				url: `/users/credit?userId=${userId}`,
			})
		},
		post: {
			signup: data => ({
				url: `/users/signup`,
				data: {
					...data
				}
			}),
			login: data => ({
				url: `/users/login`,
				data: {
					...data
				}
			}),
			askResetPassword: data => ({
				url: `/users/ask-reset-password`,
				data: {
					...data
				}
			}),
			sendPhoneVerification: phone => ({
				url: `/users/send-phone-verification`,
				data: {
					phone
				}
			}),
			verifyPhone: (phone, code) => ({
				url: `/users/verify-phone`,
				data: {
					phone,
					code
				}
			}),
			address: userAddress => ({
				url: `/users/addresses/save`,
				data: {
					userAddress
				}
			}),
			creditOperation: (orderId, payAvailableBalance) => ({
				url: `/users/credit-operation`,
				data: {
					orderId,
					payAvailableBalance
				}
			}),
		},
		patch: {
			data: data => ({
				url: `/users/data`,
				data: {
					...data
				}
			}),
			password: (data) => ({
				url: `/users/password`,
				data: {
					...data
				}
			}),
			address: (userAddressId, userAddress) => ({
				url: `/users/addresses/${userAddressId}`,
				data: {
					userAddress
				}
			})
		}
	},
	coupons: {
		get: {
			couponByName: (name, userId) => ({
				url: `/coupons/by-name`,
				data: {
					name,
					userId
				}
			})
		}
	},
	deliveryPartners: {
		get: {
			all: () => ({
				url: `/delivery-partners`
			})
		}
	},
	orders: {
		get: {
			byUser: (userId) => ({
				url: `/orders/by-user?userId=${userId}`
			}),
			paymentStatus: (orderId) => ({
				url: `/orders/payment-status/${orderId}`
			})
		},
		post: {
			order: (order, orderItems) => ({
				url: `/orders/save`,
				data: {
					order,
					orderItems
				}
			}),
		},
		patch: {
			paymentMode: (orderId, paymentMode) => ({
				url: `/orders/payment-mode/${orderId}`,
				data: {
					paymentMode
				}
			}),
			contact: (orderId, contactMethod, contactValue) => ({
				url: `/orders/contact/${orderId}`,
				data: {
					contactMethod,
					contactValue
				}
			}),
		}
	},
	quotes: {
		get: {
			byUser: (userId) => ({ url: `/quotes/by-user?userId=${userId}` }),
			artisans: {
				types: () => ({ url: `/artisans/types` })
			}
		},
		export: (products, coupon, userId) => ({
			url: `/quotes/export`,
			data: {
				products,
				coupon,
				userId
			}
		}),
	},
	payment: {
		order: {
			generateSignature: (orderId) => ({
				url: `/payment/order/generate-signature`,
				data: {
					orderId
				}
			}),
		}
	},
	news: {
		get: {
			all: () => ({
				url: `/news`
			})
		}
	},
	services: {
		currency: {
			convert: (from, to, amount) => ({
				url: `https://data.fixer.io/api/convert`,
				data: {
					access_key: config.currency.key,
					from,
					to,
					amount
				},
				isRemote: true
			}),
			symbols: () => ({
				url: `https://data.fixer.io/api/symbols`,
				data: {
					access_key: config.currency.key
				},
				isRemote: true
			})
		},
	},
	webHelpQuestions: {
		get: {
			all: () => ({
				url: `/web-help-questions`
			})
		}
	},
	infos: {
		get: {
			all: () => ({
				url: `/infos`
			})
		}
	},
    homeCategories: {
        get: {
			all: () => ({
				url: `/homeCategories`
			})
		}
    },
    adminSettings: {
        get: {
			all: () => ({
				url: `/adminSettings`
			})
		}
    }
};

const noCache = {
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache',
    'Expires': '0',
};

const request = method => async (urlAndData, onSuccess, onError, onClose) => {
	const { user, locale: { language, keys: locales } } = store.getState();

	try {
		const data = {
			...urlAndData.data,
			...(method !== "get" && !urlAndData.isRemote ? { userId: user.data.userId } : {}),
			...(!urlAndData.isRemote ? { language } : {}),
		};

		const res = await axios({
			method,
			url: `${!urlAndData.isRemote ? config.api.base_url : ""}${urlAndData.url}`,
			data: method !== "get" ? data : null,
			params: method === "get" ? data : null,
			headers: {
				...(user.data.token && !urlAndData.isRemote
					? { Authorization: `Bearer ${user.data.token}` }
					: {}),
                ...(urlAndData.noCache ? noCache : {})
			}
		});

		if (res.status == 200) {
			if (onSuccess) {
				await onSuccess(res.data);
			}
		} else {
			if (onError && res.data && res.data.error) {
				await onError(getLocaleFromStringKey(locales, `api.${res.data.error}`) || locales.errors.generic);
			}
		}
	} catch (err) {
		if (onError && err && err.response && err.response.data && err.response.data.error) {
			await onError(getLocaleFromStringKey(locales, `api.${err.response.data.error}`) || locales.errors.generic);
		} else if (onError) {
			await onError(locales.errors.generic);
		}
	} finally {
		if (onClose) {
			await onClose();
		}
	}
};

const get = request("get");
const post = request("post");
const patch = request("patch");

export default Routes;

export { get, post, patch };
