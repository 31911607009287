import React from "react";
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng
} from "react-places-autocomplete";

import "./place-input.scss";
import { useLocale } from "../../locale";

export default function PlaceInput(props) {
	const locales = useLocale();

	const handleChange = newAddress => {
		props.onChangeCallback(newAddress);
	};

	const handleSelect = selectedAddress => {
		geocodeByAddress(selectedAddress)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				props.selectPlaceCallback(selectedAddress, latLng);
			})
			.catch(error => console.error("Error", error));
	};

	return (
		<div className="form-control">
			<PlacesAutocomplete
				value={props.address || ""}
				onChange={handleChange}
				onSelect={handleSelect}
			>
				{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
					<>
						<input
							{...getInputProps({
								placeholder: props.placeholder || "",
								className: "location-search-input"
							})}
							autoComplete="new-password"
						/>
						<div
							className="autocomplete-dropdown-container"
						>
							{loading && <div>{locales.common.searching}</div>}
							{suggestions.slice(0, 3).map((suggestion, index) => {
								const className = suggestion.active
									? "suggestion-item--active"
									: "suggestion-item";
								let style = suggestion.active
									? {
										backgroundColor: "#fafafa"
									}
									: {
										backgroundColor: "#ffffff"
									};
								if (index !== 2) {
									style.borderBottom = "none";
								}
								style.width = "100%";
								style.cursor = "pointer";
								style.border = "0.5px solid #C9D7E4";
								style.padding = "5px";

								return (
									<div
										{...getSuggestionItemProps(suggestion, {
											className,
											style
										})}
										key={index}
									>
										<span>{suggestion.description}</span>
									</div>
								);
							})}
						</div>
					</>
				)}
			</PlacesAutocomplete>
		</div>
	);
};
