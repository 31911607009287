import React from 'react';
import './partners.scss';
import Slider from "react-slick";

// static data
import BrandImage from '../../assets/image/1280-px-logo-legrand-svg@3x.png';

export default class Partners extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			partners: [
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
				{
					title: 'Legard',
					image: BrandImage
				},
			]
		}
		this.settings = {
			className: "center",
			centerMode: true,
			infinite: true,
			centerPadding: "0",
			slidesToShow: 4,
			speed: 500,
			rows: 2,
			slidesPerRow: 1,
			responsive: [
				{
					breakpoint: 1030,
					settings: {
						slidesToShow: 2,
					}
				},
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 1,
					}
				},
				{
					breakpoint: 480,
					settings: {
						slidesToShow: 1,
					}
				}
			]
		};
	}
	render() {
		return (
			<div id="partners">
				<div className="page-container">
					<div className="row">
						<div className="back-bar"></div>
						<div className="center-block">
							<h3>Pourquoi</h3>
							<h1>BTPMAT ?</h1>
						</div>
						<div className="back-bar"></div>
					</div>
					<div className="partners-container">
						<div>
							<Slider {...this.settings}>
								{
									this.state.partners.map((item, key) => (
										<div key={key}>
											<div className="item-container">
												<img src={item.image} alt={item.title} />
											</div>
										</div>
									))
								}
							</Slider>
						</div>
					</div>
				</div>
			</div>
		)
	}
}