import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Checkout from "../components/checkout/checkout";
import { resetOrderData } from "../utils/orders";

export default function CheckoutPage(props) {
	const dispatch = useDispatch();

    useEffect(() => {
		if (props.initialScreen === "paymentSuccess") {
			resetOrderData(dispatch);
		}
	}, []);

    return (
        <>
            <Checkout initialScreen={props.initialScreen} />
        </>
    );
}
