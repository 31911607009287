import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./account.scss";

import Button from "@material-ui/core/Button";
import CustomButton from "../button/button";

import LockerIcon from "../../assets/image/icons-locker.svg";
import TalkIcon from "../../assets/image/icons-talk.svg";
import PinIcon from "../../assets/image/icons-pin.svg";
import BagIcon from "../../assets/image/icons-bag-border.svg";
import Devis from "../../assets/image/icons-devis.svg";
import GroupImage from "../../assets/image/group-7.svg";
import Routes, { get } from "../../services/api";
import { formatPrice } from "../../utils/numeric";
import { formatDate } from "../../utils/dates";
import { useLocale } from "../../locale";
import config from "../../config";

export default function Account(props) {
    const dispatch = useDispatch();
    const locales = useLocale();

    const user = useSelector((state) => state.user);

    useEffect(() => {
        if (user.data.token) {
            get(Routes.users.get.creditData(user.data.userId), (data) => {
                dispatch({
                    type: "SET_USER_CREDIT_DATA",
                    value: data.creditData,
                });
            });
        }
    }, []);

    const logout = () => {
        dispatch({ type: "RESET_USER_STORE" });
    };

    return (
        <div id="account">
            <div className="page-container">
                <div className="row" id="account-title">
                    <div className="left-image">
                        <img src={GroupImage} alt="Group" />
                    </div>
                    <div className="back-bar image-bar" />
                    <div className="center-block">
                        <h3>{locales.dashboard.suptitle_web}</h3>
                        <h1>{locales.dashboard.title_web}</h1>
                    </div>
                    <div className="back-bar" />
                </div>
            </div>
            <div className="page-container">
                <div id="account-description">
                    <p>{locales.dashboard.description}</p>
                </div>
            </div>
            <div className="page-container">
                <div className="logout-wrapper">
                    <CustomButton
                        onClick={logout}
                        type="transparent"
                        width="240px"
                        height="48px"
                    >
                        {locales.dashboard.logout}
                    </CustomButton>
                </div>
                <div className="row">
                    <div className="account-cart">
                        <Link to="/espacepersonnel/informationspersonnelles">
                            <Button className="contain-button">
                                <div className="cart-content">
                                    <img src={LockerIcon} alt="" />
                                    <h3>
                                        {locales.dashboard.user_infos.title_web}
                                    </h3>
                                    <hr />
                                    <p>
                                        {
                                            locales.dashboard.user_infos
                                                .description
                                        }
                                    </p>
                                </div>
                            </Button>
                        </Link>
                    </div>

                    <div className="account-cart">
                        <Link to="/espacepersonnel/motdepasse">
                            <Button className="contain-button">
                                <div className="cart-content">
                                    <img src={LockerIcon} alt="" />
                                    <h3>
                                        {locales.dashboard.edit_password.title}
                                    </h3>
                                    <hr />
                                    <p>
                                        {
                                            locales.dashboard.edit_password
                                                .description
                                        }
                                    </p>
                                </div>
                            </Button>
                        </Link>
                    </div>

                    <div className="account-cart">
                        <Button className="contain-button" disabled>
                            <div className="cart-content">
                                <img src={TalkIcon} alt="" />
                                <h3>
                                    {locales.dashboard.preferred_contact.title}
                                </h3>
                                <hr />
                                <h3>M. AHMAD</h3>
                                <p className="mt-0">Tél. 99 999 999 999</p>
                                <p className="mt-0">
                                    <b>ahmad@btpmat.com</b>
                                </p>
                            </div>
                        </Button>
                    </div>

                    {user.creditData.hasCreditAccount ? (
                        <div className="account-cart text-center">
                            <div className="cart-content">
                                <h2>{locales.dashboard.credit_balance}</h2>
                                <p className="amount">
                                    {formatPrice(user.creditData.creditBalance)}
                                </p>
                                {user.creditData.lastOperationDate ? (
                                    <p>
                                        {
                                            locales.payment.credit_balance
                                                .last_update
                                        }{" "}
                                        {formatDate(
                                            user.creditData.lastOperationDate
                                        )}
                                    </p>
                                ) : null}
                                {user.creditData.accountsBook ? (
                                    <CustomButton
                                        href={`${config.files.accountsBooks}/${user.creditData.accountsBook}`}
                                        target="_blank"
                                        type="primary"
                                        width="100%"
                                        height="61px"
                                    >
                                        {
                                            locales.dashboard
                                                .download_accounts_book
                                        }
                                    </CustomButton>
                                ) : null}
                            </div>
                        </div>
                    ) : (
                        <div className="account-cart text-center">
                            <div className="cart-content">
                                <h2>{locales.dashboard.ask_credit_account}</h2>
                                <p>
                                    {locales.dashboard.credit_account_info_web}
                                </p>
                                <CustomButton
                                    href={`${config.api.base_url}/files/creditAccounts/creditAccountRequest.pdf`}
                                    target="_blank"
                                    type="primary"
                                    width="100%"
                                    height="61px"
                                >
                                    {
                                        locales.dashboard
                                            .download_credit_account_documents
                                    }
                                </CustomButton>
                            </div>
                        </div>
                    )}

                    <div className="account-cart">
                        <Link to="/espacepersonnel/carnetdadresses">
                            <Button className="contain-button">
                                <div className="cart-content">
                                    <img src={PinIcon} alt="" />
                                    <h3>
                                        {locales.dashboard.addresses.title_web}
                                    </h3>
                                    <hr />
                                    <p>
                                        {
                                            locales.dashboard.addresses
                                                .description
                                        }
                                    </p>
                                </div>
                            </Button>
                        </Link>
                    </div>

                    <div className="account-cart">
                        <Link to="/orders">
                            <Button className="contain-button">
                                <div className="cart-content">
                                    <img src={BagIcon} alt="" />
                                    <h3>
                                        {locales.dashboard.orders.title_web}
                                    </h3>
                                    <hr />
                                    <p>
                                        {locales.dashboard.orders.description}
                                    </p>
                                </div>
                            </Button>
                        </Link>
                    </div>
                    <div className="account-cart">
                        <Link to="/devis">
                            <Button className="contain-button">
                                <div className="cart-content">
                                    <img src={Devis} alt="" />
                                    <h3>
                                        <br />
                                        {locales.dashboard.quotes.title}
                                    </h3>
                                    <hr />
                                    <p>
                                        {locales.dashboard.quotes.description}
                                    </p>
                                </div>
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
