import React from 'react';
import './blog-cart.scss';
import { Link } from 'react-router-dom';

import { formatDatetime } from "../../utils/dates";
import config from "../../config";

export default function BlogCart(props) {
	return props.item.magazine ? (
		<a href={`${config.files.news}/${props.item.magazine}`} rel="noopener noreferrer" target="_blank">
			<div className="blog-cart">
				<div className="featured-image">
					<img src={`${config.files.news}/${props.item.image}`} alt="" />
				</div>
				<div className="cart-container">
					<h3 className="title">{props.item.magazineTitle}</h3>
					<p className="short">{props.item.short}</p>
					<p className="blog-date"><span>{formatDatetime(props.item.creationDate)}</span></p>
				</div>
			</div>
		</a>
	) : (
			<Link to={`/actualite/${props.item.newsId}-${props.item.slug}`}>
				<div className="blog-cart">
					<div className="featured-image">
						<img src={`${config.files.news}/${props.item.image}`} alt="" />
					</div>
					<div className="cart-container">
						<p className="short">{props.item.short}</p>
						<p className="blog-date"><span>{formatDatetime(props.item.creationDate)}</span></p>
					</div>
				</div>
			</Link>
		)
}