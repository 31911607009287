import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "./areas.scss";
import Area from "../area/area";
import CustomButton from "../button/button";
import { useLocale } from "../../locale";

function Areas() {
	const locales = useLocale();

	const areas = useSelector(state => state.shop.categories);

	return (
		<div id="products">
			<div className="page-container title-area">
				<div className="row">
					<div className="center-block">
						<h3>{locales.shop.categories_web.suptitle}</h3>
						<h1>{locales.shop.categories_web.title}</h1>
					</div>
					<div className="back-bar" />
				</div>
			</div>
			<div className="page-container">
				<div className="products-container">
					{areas.map((area, index) => <Area key={index} areaData={area} />)}
				</div>
			</div>
			<div className="page-container text-center">
				<Link to="/contact" className="action-link">
					<CustomButton type="primary" width="276px" height="48px">
						{locales.shop.ask_quote}
					</CustomButton>
				</Link>
			</div>
		</div>
	);
}

export default Areas;
