import React from "react";

import Header1 from "../components/header/header1";
import CurrencyConvert from "../components/currency-convert/currency-convert";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";
import ProductsTile from "../components/products-tile/products-tile";
import { useProductsSearch } from "../utils/products";

export default function CurrencyConvertPage() {
    const { search, products } = useProductsSearch();

    return (
        <div>
            <Header1 />
            {search ? (
                <ProductsTile products={products} style={{ marginTop: 40 }} />
            ) : (
                <>
                    <CurrencyConvert />
                    <IconActions />
                </>
            )}
            <Footer />
        </div>
    );
}
