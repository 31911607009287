import React from 'react';
import { useSelector } from "react-redux";
import { Link } from 'react-router-dom'

import './area.scss';
import config from "../../config";

function Area(props) {
	const subCategories = useSelector(state => state.shop.subCategories)
	let subs = [];
	subCategories.forEach((subCategory) => {
		if (subCategory.categoryId === props.areaData.categoryId) {
			subs.push(subCategory)
		}
	})

	return (
		<div className={subs.length > 8 ? "product-item" : "product-item half"} style={{ position: "relative" }}>
			<Link to={`/boutique/${props.areaData.categoryId}-${props.areaData.slug}`}>
				<div className="cart-main">
					<div className="icon-part">
						<img src={`${config.files.categoryIcons}/${props.areaData.icon}`} alt="" />
					</div>
					<div className="title-part">
						<h5>
							{props.areaData.name}
						</h5>
					</div>
				</div>
			</Link>
			<div className="sub-category-part">
				{
					subs.map((subCategory, key) => (
						<div key={key} className={'item' + key}>
							{
								subCategory.categoryId === props.areaData.categoryId &&
								<Link to={`/sub-category/${props.areaData.categoryId}-${props.areaData.slug}/${subCategory.subCategoryId}-${subCategory.slug}`}>
									{subCategory.name}
								</Link>
							}
						</div>
					))
				}
			</div>
		</div >
	);
}

export default Area