import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import SearchIcon from "./../../assets/icons/search";
import "./search.scss";
import { useLocale } from "../../locale";

export default function Search({ inputWidth = '362px', containerStyle = {} }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const locales = useLocale();

    const currentSearch = useSelector((state) => state.shop.filters.search);

    const setProductsSearch = (value) => {
        dispatch({ type: "SET_PRODUCTS_SEARCH", value });
    };

    useEffect(() => {
        setProductsSearch("");
    }, [location.pathname]);

    return (
        <div id="search-form" style={containerStyle}>
            <input
                style={{ width: inputWidth }}
                name="search"
                value={currentSearch}
                placeholder={locales.inputs.search}
                onChange={(e) => setProductsSearch(e.target.value)}
            />
            <button>
                <SearchIcon />
            </button>
        </div>
    );
}
