import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import './personal-info.scss';
import CustomButton from '../../button/button';
import PhoneInput from "../../forms/phone-input";
import Routes, { patch } from "../../../services/api";
import Patterns from "../../../constants/patterns";
import IconProfile from "../../../assets/image/icons-profile.svg";
import { useLocale } from "../../../locale";

export default function PersonalInfo(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const locales = useLocale();

	const user = useSelector(state => state.user.data);

	const [state, setState] = useState({
		email: user.email || "",
		phone: user.phone || "",
		firstname: user.firstname || "",
		lastname: user.lastname || "",
        profilePicture: user.profilePicture || null
	});
    const [uploadedProfilePicture, setUploadedProfilePicture] = useState(null)

	const [loading, setLoading] = useState(false);

    const toDataURL = url => fetch(url)
                            .then(response => response.blob())
                            .then(blob => new Promise((resolve, reject) => {
                                console.log('blob', blob)
                                const reader = new FileReader()
                                reader.onloadend = () => resolve(reader.result)
                                reader.onerror = reject
                                reader.readAsDataURL(blob)
                            }))

	const handles = {
		inputChange: (event) => {
			event.persist();
			setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
		},
		submit: async () => {
			if (
                (state.email && Patterns.email.test(state.email)) || 
                (state.phone && Patterns.phone.test(state.phone))
            ) {
				setLoading(true);

                let newProfilePicture = null;
                if (uploadedProfilePicture) {
                    newProfilePicture = await toDataURL(URL.createObjectURL(uploadedProfilePicture))
                }

				patch(Routes.users.patch.data({
					email: state.email,
					phone: state.phone,
					lastname: state.lastname,
					firstname: state.firstname,
                    ...(newProfilePicture ? { profilePicture: newProfilePicture } : {})
				}), data => {
					dispatch({
						type: "ADD_USER_DATA",
						value: {
							...data.user,
							name: data.user.lastname
						}
					});
					history.push("/espacepersonnel");
				}, error => {
					dispatch({ type: "SET_ERROR", value: error });
				}, () => {
					setLoading(false);
				});
			} else {
				dispatch({ type: "SET_ERROR", value: locales.errors.required.email_or_phone });
			}
		}
	}

    const onProfilePictureChange = event => setUploadedProfilePicture(event.target.files[0])

	return (
		<div id="personal-info">
			<div className="form-area">
                <div 
                    className="form-group" 
                    style={{ display: 'flex', justifyContent: 'center', marginBottom: '100px' }}
                >
                    <label htmlFor="profile-picture" id="profile-picture-container">
                        <div style={{ 
                            border: '2px solid #c1c8d4', 
                            borderRadius: '100%', 
                            overflow: 'hidden', 
                            width: '100px', 
                            height: '100px',
                            backgroundImage: `url(${
                                uploadedProfilePicture 
                                ? URL.createObjectURL(uploadedProfilePicture) 
                                : state.profilePicture || IconProfile
                            })`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '105px 105px',
                            backgroundPosition: 'center'
                        }}/>
                    </label>
                    <input 
                        type="file" 
                        name="profile-picture" 
                        id="profile-picture" 
                        style={{ display: 'none' }}
                        onChange={onProfilePictureChange}
                    />
                </div>
				<div className="form-group">
					<label>{locales.common.your_email}</label>
					<input type="email" name="email" className="form-control" value={state.email} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<label>{locales.common.your_phone_number}</label>
					<PhoneInput
						value={state.phone}
						onChange={phone => setState(prev => ({
							...prev,
							phone
						}))}
					/>
				</div>
				<div className="form-group">
					<label>{locales.common.name}</label>
					<input type="text" name="firstname" className="form-control" value={state.firstname} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<label>{locales.common.firstname}</label>
					<input type="text" name="lastname" className="form-control" value={state.lastname} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<CustomButton type="primary" width="100%" height="48px" onClick={handles.submit} loading={loading}>
						{locales.dashboard.user_infos.validate}
					</CustomButton>
				</div>
			</div>
		</div>
	)
}