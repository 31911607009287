import React from "react";
import "./order-total-header.scss";

import { formatPrice } from "../../../utils/numeric";
import { pluralize } from "../../../utils/locales";
import { useLocale } from "../../../locale";

export default function OrderTotalHeader(props) {
	const locales = useLocale();

	return (
		<div className="order-total-header" >
			<h5>
				{`${pluralize(locales.shop.cart_items_count, props.productsCount)}${props.productsCount >= 1 ? ` : ${locales.shop.products_total} ${formatPrice(props.totalPrice)}` : ""}`}
			</h5>
		</div>
	);
}
