const initialState = {
	newsCategories: [],
	news: []
};

export default function newsReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_NEWS_CATEGORIES':
			return { ...state, newsCategories: action.value };
		case 'SET_NEWS':
			return { ...state, news: action.value };
		default:
			return state;
	}
};
