import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import InnerImageZoom from 'react-inner-image-zoom';
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';

import "./single-product-gallery.scss";
import LeftArrow from "../../assets/image/left-arrow.svg";
import RightArrow from "../../assets/image/right-arrow.svg";
import PlayIcon  from '../../assets/image/play-icon.png'
import { useLocale } from "../../locale";

const Arrow = ({ className, style, onClick, src, alt }) =>  (
    <div className={className} style={{ ...style }} onClick={onClick}>
        <img src={src} alt={alt} />
    </div>
);

const Video = ({ src, isOnVideo }) => {
    const locales = useLocale();
    const videoRef = useRef(null);

    useEffect(() => {
        if (!isOnVideo) {
            videoRef?.current?.pause();
        }
    }, [isOnVideo])

    return (
        <video style={{ width: "100%" }} ref={videoRef} controls>
            <source src={src} />
            {locales.errors.unsupported.video_web}
        </video>
    );
}


export default function SingleProductGallery(props) {
    const [isOnVideo, setIsOnVideo] = useState(!!props.video)
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        useCSS: false,
        useTransform: false,
        prevArrow: <Arrow src={LeftArrow}  alt="previous" />,
        nextArrow: <Arrow src={RightArrow} alt="next" />,
        responsive: [
            {
                breakpoint: 400,
                settings: {
                    dots: true,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    useCSS: false,
                    useTransform: false,
                    centerMode: false,
                    swipeToSlide: true,
                    variableWidth: false
                },
            },
        ],
        beforeChange: (prevIndex, nextIndex) => {
            if (!props.video) return;
            if (prevIndex === 0) setIsOnVideo(false);
            if (nextIndex === 0) setIsOnVideo(true);
        }
    };

    const sliderRef = useRef();

    const onMiniatureClick = index => {
        sliderRef.current.slickGoTo(index === 'video' ? 0 : index || 0)
    }

    const hasMoreThanOneMedia = props.images.length > 1 || (props.video && props.images.length > 0)

    return (
        <div className="single-product-gallery">
            <div style={{ maxWidth: '350px' }}>
                <Slider ref={sliderRef} {...settings}>
                    {props.video ? (
                        <Video src={props.video} isOnVideo={isOnVideo}/>
                    ) : null}
                    {props.images.map((image, index) => (
                        <div key={index}>
                            <InnerImageZoom 
                                src={image} 
                                alt="product-image" 
                                zoomType="hover"
                            />
                        </div>
                    ))}
                </Slider>
            </div>
            
            {hasMoreThanOneMedia ? (
                <div className="miniatures-container">
                    {props.video ? (
                        <div 
                            className="miniatures" 
                            onClick={() => onMiniatureClick('video')}
                            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        >
                            <img src={PlayIcon} style={{ width: '40px', height: '40px' }}/>
                        </div>
                    ) : null}
                    
                    {props.images.map((image, index) => (
                        <img 
                            src={image}
                            key={'miniature-'+index}
                            className="miniatures" 
                            onClick={() => onMiniatureClick(index + (props.video ? 1  : 0))}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    );
}
