import React from 'react';
import logoImage from './image/BTPMATPEN.png';

function Logo({ width = "259" }) {
	return (
		<>
			<img src={logoImage} alt="Logo" width={width}/>
		</>
	);
}

export default Logo;