import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import "./payment.scss";
import CustomButton from "../../button/button";
import MasterCard from "../../../assets/image/master-card.svg";
import Visa from "../../../assets/image/visa.svg";
import VisaElectron from "../../../assets/image/visa-electron.svg";
import Routes, { post } from "../../../services/api";
import config from "../../../config";
import { encodePaymentParams } from "../../../utils/payment";
import { useLocale } from "../../../locale";

export default function Payment(props) {
    const locales = useLocale();

    const order = useSelector((state) => state.order);

    const [paymentData, setPaymentData] = useState({});

    useEffect(() => {
        post(Routes.payment.order.generateSignature(order.orderId), (data) => {
            setPaymentData({
                paymentUrl: data.paymentUrl,
                paymentParams: data.paymentParams,
            });
        });
    }, []);

    return (
        <div id="payment">
            <div className="page-container">
                <h1 className="page-title">{locales.payment.card.title}</h1>
                <p className="page-description">
                    {locales.payment.card.instruction1}
                </p>
                <div className="form-container">
                    <CustomButton
                        href={`${
                            config.api.base_url
                        }/payment/order/askForPayment/?${encodePaymentParams(
                            paymentData.paymentParams
                        )}`}
                        type="primary"
                        width="100%"
                        height="48px"
                    >
                        {locales.delivery.go_to_payment}
                    </CustomButton>
                </div>
                <div className="payment-label">
                    <img
                        src={Visa}
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                        alt=""
                    />
                    <img src={MasterCard} alt="" />
                    <img
                        src={VisaElectron}
                        style={{ paddingLeft: "5px", paddingRight: "5px" }}
                        alt=""
                    />
                </div>
            </div>
        </div>
    );
}
