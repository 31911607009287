import React from 'react'
import Header1 from '../components/header/header1';
import Breadcrumbs from '../components/breadcrumb/breadcrumb';
import Devis from '../components/account/devis/devis.design';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';

export default function ComponentPage() {
	return (
		<div>
			<Header1 />
			<Breadcrumbs />
			<Devis />
			<IconActions />
			<Footer />
		</div>
	)
}
