import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Header1 from "../components/header/header1";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import Blog from "../components/blog/blog";
import BlogRelated from "../components/blog-related/blog-related";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";
import ProductsTile from "../components/products-tile/products-tile";
import NewsSelectors from "../selectors/news";
import { ellipsize } from "../utils/strings";
import { useProductsSearch } from "../utils/products";

export default function ActualitesPage() {
    const { id: newsId } = useParams();

    const news = useSelector(NewsSelectors.bySlug(newsId));

    const { search, products } = useProductsSearch();

    return (
        <>
            <Header1 />
            {search ? (
                <ProductsTile products={products} style={{ marginTop: 40 }} />
            ) : (
                <>
                    <Breadcrumb
                        title={ellipsize(news.content, 40)}
                        style={{ paddingTop: "24px", paddingBottom: "32px" }}
                    />
                    <Blog news={news} />
                    <BlogRelated />
                    <IconActions />
                </>
            )}
            <Footer />
        </>
    );
}
