import React from "react";
import { useSelector } from "react-redux";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../routes";
import "./layouts.scss";

const AuthenticatedRoute = (props) => {
	const user = useSelector(state => state.user.data);

	return user.token ? (
		<Route {...props} />
	) : (
			<Redirect to="/" />
		);
};

export default function Layout() {
	return (
		<Switch>
			{routes.map((route, key) => {
				const RouteComponent = route.auth ? AuthenticatedRoute : Route;

				return (
					<RouteComponent
						key={route}
						exact={true}
						path={route.path}
						name={route.name}
						component={route.component}
					/>
				);
			})}
		</Switch>
	);
}
