import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import ProductsSelectors from "../../selectors/products";
import SubCategoriesSelectors from "../../selectors/subCategories";

import "./product-filter.scss";
import {
	FormControl,
	RadioGroup,
	FormControlLabel,
	Radio,
	Checkbox,
	Slider
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomButton from "../button/button";
import Button from "@material-ui/core/Button";
import { formatPrice } from "../../utils/numeric";
import { useLocale } from "../../locale";

const PrettoSlider = withStyles({
	root: {
		color: "#eec400",
		height: 8
	},
	thumb: {
		height: 24,
		width: 24,
		backgroundColor: "#eec400",
		border: "1px solid currentColor",
		marginTop: -10,
		marginLeft: -12,
		"&:focus, &:hover, &$active": {
			boxShadow: "inherit"
		}
	},
	active: {},
	valueLabel: {
		left: "calc(-50% + 7px)",
		width: 100
	},
	track: {
		height: 6,
		borderRadius: 0
	},
	rail: {
		height: 6,
		borderRadius: 0,
		opacity: 0.5,
		backgroundColor: "#bfbfbf"
	},
	mark: {
		height: 6,
		width: 2,
		marginTop: 0
	},
	markActive: {
		opacity: 1,
		backgroundColor: "currentColor"
	},
	markLabel: {
		top: 40,
		fontSize: 12,
		fontWeight: "bold",
		left: "calc(-50% + 7px)"
	}
})(Slider);

export default function ProductFilter({ showSubCategoriesFilter }) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const filters = useSelector(state => state.shop.filters);
	const products = useSelector(state => ProductsSelectors.byCategory(state));

	const subCategories = useSelector(state =>
		SubCategoriesSelectors.byCategory(state)
	);

	const productsPrices = products.map(
		p => (p.reducedPrice ? p.reducedPrice : p.originalPrice)
	);
	const maxPrice = Math.ceil(Math.max(Math.max(...productsPrices), 1000));

	const [state, setState] = useState({
		categories: filters.subCategories,
		discounts: filters.discountTypes,
		priceLowMin: 0,
		priceHighMax: maxPrice,
		priceLow: filters.price.min || 0,
		priceHigh: filters.price.max ? Math.min(filters.price.max, maxPrice) : maxPrice,
		priceLowOrder: true,
		priceHighOrder: false,
		currency: "XOF",
		marks: [
			{
				value: 0,
				label: formatPrice(0)
			},
			{
				value: maxPrice,
				label: formatPrice(maxPrice)
			}
		],
		isFilterActive: 0
	});

	useEffect(() => {
		dispatch({
			type: "SET_SUBCATEGORIES_FILTER",
			value: []
		});
		dispatch({
			type: "CLEAR_PRICE_FILTERS"
		});
	}, [filters.category]);

	useEffect(
		() => {
			setState(prev => ({
				...prev,
				priceLow: filters.price.min || 0,
				priceHigh: filters.price.max || maxPrice
			}));
		},
		[filters.price]
	);

    // useEffect(() => {
    //     return () => resetFilters();
    // });

	const toggleSubCategory = event => {
		const subCategoryId = parseInt(event.target.value);
		dispatch({
			type: "SET_SUBCATEGORIES_FILTER",
			value: event.target.checked
				? [...(filters.subCategories || []), subCategoryId]
				: (filters.subCategories || []).filter(sc => sc !== subCategoryId)
		});
	};

	const toggleDiscountType = event => {
		const discountType = event.target.value;
		dispatch({
			type: "SET_DISCOUNT_TYPES_FILTER",
			value: event.target.checked
				? [...(filters.discountTypes || []), discountType]
				: (filters.discountTypes || []).filter(dt => dt !== discountType)
		});
	};

	const handlePriceChange = (event, newValue) => {
		setState(prev => ({
			...prev,
			priceLow: newValue[0],
			priceHigh: newValue[1]
		}));
	};

	const applyPriceChange = () => {
		dispatch({
			type: "SET_PRICE_FILTER_RANGE",
			value: {
				min: state.priceLow,
				max: state.priceHigh
			}
		});
	};

	const handlePriceRule = event => {
		dispatch({
			type: "SET_PRICE_SORT",
			value: ["DESC", "ASC"][event.target.value]
		});
	};

	const handleFilterActive = () => {
		if (state.isFilterActive === 0) {
			setState(prev => ({ ...prev, isFilterActive: 1 }));
		} else if (state.isFilterActive === 1) {
			setState(prev => ({ ...prev, isFilterActive: 0 }));
		}
	};

	const resetFilters = () => {
		dispatch({ type: "RESET_FILTERS" });
	};

	const getCountBySubCategory = subCategoryId =>
		products.filter(product => product.subCategories.includes(subCategoryId))
			.length;

	const getCountByDiscountType = discountType =>
		products.filter(
			product =>
				(discountType === "DESTOCKING" && product.isDestocking) ||
				(discountType === "DISCOUNT" && Number.isFinite(product.reducedPrice))
		).length;

	const formatSliderValueLabel = value => {
		const thousands = Math.round(value / 1000);

		if (thousands < 1000) {
			return `${thousands}K`;
		} else {
			return `${(thousands / 1000).toFixed(1)}M`;
		}
	};

	return (
		<div id="product-filter">
			<div className="page-container">
				<div className="row filter-header">
					<div className="hidden-button">
						<button className="btn-primary" style={{ opacity: 0 }}>
							{locales.common.filter_and_sort}
						</button>
					</div>
					<div>
						{/* <button className="btn-primary btn-dropdown" value={state.isFilterActive} onClick={handleFilterActive}>
								Filtrer et trier &nbsp;&nbsp;&nbsp;&nbsp; {state.isFilterActive === 1 ? <>&#9650;</> : <>&#9660;</>}
							</button> */}
						<CustomButton
							width="160px"
							height="32px"
							type="primary"
							onClick={handleFilterActive}
						>
							{locales.common.filter_and_sort} &nbsp;&nbsp;{state.isFilterActive === 1 ? (
								<>&#9650;</>
							) : (
									<>&#9660;</>
								)}
						</CustomButton>
					</div>
					<div>
						<CustomButton
							onClick={resetFilters}
							width="155px"
							height="32px"
							type="transparent"
						>
							{locales.filters.clear_web}
						</CustomButton>
					</div>
				</div>
			</div>
			<div className="page-container">
				<div
					className={
						state.isFilterActive === 1
							? "row filter-content active"
							: "row filter-content"
					}
				>
					{showSubCategoriesFilter ? (
						<div className="column">
							<div className="filter-title-area">
								<h5 className="filter-item-title">{locales.filters.subcategories}</h5>
							</div>
							<div className="filter-body">
								<FormControl component="fieldset">
									{subCategories.map((subCategory, key) => (
										<FormControlLabel
											value={subCategory.subCategoryId}
											control={<Checkbox style={{ color: "#eec400" }} />}
											size="14px"
											label={`${subCategory.name} (${getCountBySubCategory(
												subCategory.subCategoryId
											)})`}
											checked={filters.subCategories.includes(
												subCategory.subCategoryId
											)}
											onChange={toggleSubCategory}
											key={key}
										/>
									))}
								</FormControl>
							</div>
						</div>
					) : null}
					<div className="column">
						<div className="filter-title-area">
							<h5 className="filter-item-title">{locales.filters.discount_types}</h5>
						</div>
						<div className="filter-body">
							<FormControl component="fieldset">
								<FormControlLabel
									value={"DISCOUNT"}
									control={<Checkbox style={{ color: "#eec400" }} />}
									size="14px"
									label={`${locales.filters.filter.discount_types.discount_web} (${getCountByDiscountType("DISCOUNT")})`}
									checked={filters.discountTypes.includes("DISCOUNT")}
									onChange={toggleDiscountType}
								/>
								<FormControlLabel
									value={"DESTOCKING"}
									control={<Checkbox style={{ color: "#eec400" }} />}
									size="14px"
									label={`${locales.filters.filter.discount_types.destocking} (${getCountByDiscountType("DESTOCKING")})`}
									checked={filters.discountTypes.includes("DESTOCKING")}
									onChange={toggleDiscountType}
								/>
							</FormControl>
						</div>
					</div>
					<div className="column" style={{ width: "350px" }}>
						<div className="filter-title-area">
							<h5 className="filter-item-title">{locales.filters.price}</h5>
						</div>
						<div className="filter-body">
							<PrettoSlider
								min={state.priceLowMin}
								max={state.priceHighMax}
								step={1}
								value={[state.priceLow, state.priceHigh]}
								valueLabelFormat={value => (
									<div>{formatSliderValueLabel(value)}</div>
								)}
								onChange={handlePriceChange}
								onChangeCommitted={applyPriceChange}
								// getAriaValueText={valuetext}
								marks={state.marks}
								valueLabelDisplay="auto"
							/>
							<ul>
								<Button value="0" onClick={handlePriceRule}>
									<li
										className={filters.price.sort === "DESC" ? "selected" : ""}
									>
										{locales.filters.sort.price.desc_web}
									</li>
								</Button>
								<Button value="1" onClick={handlePriceRule}>
									<li
										className={filters.price.sort === "ASC" ? "selected" : ""}
									>
										{locales.filters.sort.price.asc_web}
									</li>
								</Button>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
