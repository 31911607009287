import React, { useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import "./header-mobile-static.scss";
import Search from "../forms/search";
import Logo from "../../assets/logo";
import CloseIcon from "../../assets/image/arrow-left-white.svg";
import ShoppingBagIcon from "../../assets/icons/shopping-bag";
import IconProfileActive from "../../assets/image/icons-profile-active.svg";
import ProductsSelectors from "../../selectors/products";
import { useLocale } from "../../locale";
import BoutiqueMenuMobile from "./boutique-menu-mobile";

function HeaderMobileStatic(props) {
    const dispatch = useDispatch();
    const locales = useLocale();

    const user = useSelector((state) => state.user.data);
	const cart = useSelector(ProductsSelectors.cart());
	const categories = useSelector(state => state.shop.categories);
	const subCategories = useSelector(state => state.shop.subCategories);

    const history = useHistory();
    const location = useLocation();

    const [state, setState] = useState({ isToggleOn: false });

    const activeSide = () => {
        setState((state) => ({
            ...state,
            isToggleOn: true,
        }));
    };

    const deactiveSide = () => {
        setState((state) => ({
            ...state,
            isToggleOn: false,
        }));
    };

    const resetProductsSearch = () => {
        dispatch({ type: "SET_PRODUCTS_SEARCH", value: "" });
    };

    const showSearchInput = !/^\/(espacepersonnel|devis|orders|mentions-legales)/.test(location.pathname);

    return (
        <>
            <div className="mobile-header" id="mobileHeaderStatic">
                <div className="page-container">
                    <div className="row">
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', flex: 1 }}>
                            <button
                                className="show-side"
                                id="hamburger"
                                onClick={activeSide}
                            >
                                <svg
                                    version="1.1"
                                    x="0px"
                                    y="0px"
                                    width="122.879px"
                                    height="103.609px"
                                    viewBox="0 0 122.879 103.609"
                                >
                                    <g>
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.368,0h102.144c5.703,0,10.367,4.665,10.367,10.367v0 c0,5.702-4.664,10.368-10.367,10.368H10.368C4.666,20.735,0,16.07,0,10.368v0C0,4.665,4.666,0,10.368,0L10.368,0z M10.368,82.875 h102.144c5.703,0,10.367,4.665,10.367,10.367l0,0c0,5.702-4.664,10.367-10.367,10.367H10.368C4.666,103.609,0,98.944,0,93.242l0,0 C0,87.54,4.666,82.875,10.368,82.875L10.368,82.875z M10.368,41.438h102.144c5.703,0,10.367,4.665,10.367,10.367l0,0 c0,5.702-4.664,10.368-10.367,10.368H10.368C4.666,62.173,0,57.507,0,51.805l0,0C0,46.103,4.666,41.438,10.368,41.438 L10.368,41.438z"
                                        />
                                    </g>
                                </svg>
                            </button>
                            <Link
                                to="/"
                                className="home-link"
                                onClick={resetProductsSearch}
                            >
                                <Logo width="180px"/>
                            </Link>
                        </div>
                        <nav className="side-nav-mobile">
                            <li>
                                <div
                                    style={{ height: '100%', display: 'flex', justifyContent: 'center', flex: 1,  marginLeft: '20px' }}
                                    className={
                                        "espacePersonnel" + 
                                        (/(\/espacepersonnel.*|\/orders|\/devis)/.test(location.pathname) ? ' active' : '')}
                                    aria-label="delete"
                                    size="small"
                                    onClick={user.token ? () => history.push("/espacepersonnel") : props.handles.openModal}
                                >
                                    {!!user.profilePicture ? (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <img src={user.profilePicture} style={{ width: 32, height: 32, borderRadius: '100%', margin: '0 10px' }} /> 
                                        </div>
                                    ) : (
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                            <img src={IconProfileActive} alt="dashboard" width="32"/>
                                            <span style={{ color: 'white', fontSize: '12px' }}>
                                                Connexion
                                            </span>
                                        </div>
                                    )}
                                </div>
                            </li>
                            <li>
                                <div
                                    aria-label="cart"
                                    size="small"
                                    onClick={props.handles.miniCartHandleOpen}
                                    style={{ 
                                        height: '100%', display: 'flex',  
                                        justifyContent: 'center', alignItems: 'center',  marginRight: '10px', flex: 1 
                                    }}
                                >
                                    {/* when the cart item is bigger than 1 you can add the active */}
                                    <ShoppingBagIcon active={cart.length >= 1} />
                                    <span style={{ color: 'white' }}>
                                        {locales.cart.title}
                                    </span>
                                </div>
                            </li>
                        </nav>
                    </div>
                    {showSearchInput ? (
                        <div className="row">
                            <Search inputWidth={'100%'} containerStyle={{ width: '98%' }}/>
                        </div>
                    ) : null}
                </div>
            </div>
            <div
                id="sidebar"
                className={state.isToggleOn ? "side-active" : "hidden"}
            >
                <div className="side-header">
                    <div className="close-side" onClick={deactiveSide}>
                        <img src={CloseIcon} alt="" />
                    </div>
                    <Logo />
                </div>
                <div className="">
                    <nav>
                        <ul>
                            <li>
                                
                                <BoutiqueMenuMobile
                                    categories={categories}
                                    subCategories={subCategories}
                                />
                            </li>
                            <li>
                                <Link to="/contact" style={{ color: 'white' }}>
                                    {locales.menus.quote_web}
                                </Link>
                            </li>
                            <li>
                                <Link to="/actualites" style={{ color: 'white' }}>
                                    {locales.menus.news}
                                </Link>
                            </li>
                            <div className="text-center row">
                                <Link
                                    className="btn-primary"
                                    to="/"
                                >
                                    {locales.common.back_to_home}
                                </Link>
                            </div>
                        </ul>
                    </nav>
                </div>
            </div>
            <div
                id="overlay"
                className={state.isToggleOn ? "shown" : "hidden"}
                onClick={deactiveSide}
            />
        </>
    );
}

export default HeaderMobileStatic;
