import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import './edit-password.scss';
import CustomButton from '../../button/button';
import Routes, { patch } from "../../../services/api";
import Patterns from "../../../constants/patterns";
import { useLocale } from "../../../locale";

export default function EditPassword(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const locales = useLocale();

	const [state, setState] = useState({
		currentPassword: "",
		newPassword: "",
		newPasswordConfirmation: "",
	});

	const [loading, setLoading] = useState(false);

	const handles = {
		inputChange: (event) => {
			event.persist();
			setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
		},
	}

	const changePassword = () => {
		if (Patterns.password.test(state.currentPassword) &&
			Patterns.password.test(state.newPassword)) {
			if (state.newPassword === state.newPasswordConfirmation) {
				setLoading(true);
				patch(
					Routes.users.patch.password(state),
					(data) => {
						history.push("/espacepersonnel");
					},
					(error) => {
						dispatch({ type: "SET_ERROR", value: error });
					},
					() => {
						setLoading(false);
					}
				);
			} else {
				dispatch({ type: "SET_ERROR", value: locales.errors.invalid.new_password_confirmation });
			}
		} else {
			dispatch({ type: "SET_ERROR", value: locales.errors.patterns.password });
		}
	};

	return (
		<div id="edit-password">
			<div className="form-area">
				<div className="form-group">
					<label>{locales.inputs.current_password}</label>
					<input type="password" name="currentPassword" className="form-control" value={state.currentPassword} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<label>{locales.inputs.new_password}</label>
					<input type="password" name="newPassword" className="form-control" value={state.newPassword} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<label>{locales.inputs.new_password_confirmation}</label>
					<input type="password" name="newPasswordConfirmation" className="form-control" value={state.newPasswordConfirmation} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<CustomButton type="primary" width="100%" height="48px" onClick={changePassword} loading={loading}>
						{locales.common.validate}
					</CustomButton>
				</div>
			</div>
		</div>
	)
}