import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import "./info-page.scss";
import Header1 from "../components/header/header1";
import Footer from "../components/footer/footer";
import InfosSelectors from "../selectors/infos";
import { formatParagraphs } from "../utils/strings";

export default function InfoPage(props) {
    const { infoSlug } = useParams();

    const info = useSelector(InfosSelectors.bySlug(infoSlug));

    return (
        <>
            <Header1 />
            {info ? (
                <div id="info" className="page-container">
                    {formatParagraphs(info.content)}
                </div>
            ) : null}
            <Footer />
        </>
    );
}
