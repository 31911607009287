import React, { useState } from "react";
import "./success.scss";

import SuccessIcon from "../../../assets/image/undraw-confirmation.svg";
import CustomButton from "../../button/button";
import { useLocale } from "../../../locale";

export default function Success(props) {
	const locales = useLocale();

	const [exporting, setExporting] = useState(false);

	const onResume = () => {
		if (props.miniCartFlow === "export") {
			setExporting(true);
			props.onSuccess(null, () => setExporting(false));
		} else {
			props.onSuccess();
		}
	};

	const message = {
		'forget-result-email': locales.onboarding.forgotten_password.result.email,
		'forget-result-phone': locales.onboarding.forgotten_password.result.sms,
		'success': locales.onboarding.signup.result.info1,
		'login-result': locales.onboarding.login.result.info1,
	}[props.state.currentScreen || 'success'];

	const resumeLabel = {
		order: locales.cart.go_to_order.resume,
		export: locales.cart.export_quote.resume
	}[props.miniCartFlow];

	return (
		<div id="success">
			<div className="icon-area">
				<div>
					<img src={SuccessIcon} alt="" />
				</div>
			</div>
			<div className="msg-area">
				<p>{message}</p>
			</div>
			<div className="action-area">
				{["success", "login-result"].includes(props.state.currentScreen) ? (
					<>
						<CustomButton
							href="/espacepersonnel"
							type="primary"
							width="100%"
							height="48px"
						>
							{locales.onboarding.signup.result.go_to_dashboard}
						</CustomButton>
						<div style={{ height: "8px" }} />
						<CustomButton
							href="/contact"
							type="transparent"
							width="100%"
							height="48px"
						>
							{locales.shop.ask_quote}
						</CustomButton>
						{(props.onSuccess && props.inMiniCart && props.miniCartFlow) && (
							<CustomButton
								onClick={onResume}
								type="transparent"
								width="100%"
								height="48px"
								loading={exporting}
							>
								{resumeLabel}
							</CustomButton>
						)}
					</>
				) : (
						<CustomButton
							href="/boutique"
							type="primary"
							width="100%"
							height="48px"
						>
							{locales.shop.go_back_to_shop}
						</CustomButton>
					)}
			</div>
		</div>
	);
}
