import React, { useState, useEffect } from 'react';
import Slider from "react-slick";
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import './supplier.scss';
import GroupImage from '../../assets/image/group-7.svg';
import Routes, { get } from "../../services/api";
import config from "../../config";
import { useLocale } from "../../locale";

function Supplier() {
	const locales = useLocale();

	const [brands, setBrands] = useState([]);

	useEffect(() => {
		get(Routes.brands.get.all(), data => {
			setBrands([
				...data.brands["IVORIAN"],
				...data.brands["INTERNATIONAL"]
			]);
		});
	}, []);

	var settings = {
		dots: false,
		infinite: false,
		speed: 500,
		slidesToShow: 5,
		slidesToScroll: 5,
		initialSlide: 0,
		// autoplay: true,
		// autoplaySpeed: 4000,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};
	return (
		<div id="supplier">
			<div className="page-container">
				<div className="row">
					<div className="left-image">
						<img src={GroupImage} alt="Group" />
					</div>
					<div className="center-block">
						<h3>{locales.shop.brands_web.suptitle}</h3>
						<h1>{locales.shop.brands_web.title}</h1>
					</div>
					<div className="back-bar"></div>
				</div>
			</div>
			<div className="page-container">
				<div className="row">
					<div className="carousel-content">
						<Slider {...settings}>
							{brands.map(brand => (
								<div key={brand.brandId}>
									<img src={`${config.files.brands}/${brand.logo}`} alt={brand.name} />
								</div>
							))}
						</Slider>
					</div>

				</div>
			</div>
			<div className="page-container">
				<p className="bottom-action">{locales.shop.brands_web.more}</p>
			</div>
		</div>
	);
}

export default Supplier