import React, { useState, useEffect } from 'react';

import './currency-convert.scss'
import CustomButton from './../button/button'
import { useLocale } from "../../locale";
import Routes, { get } from "../../services/api";
import { formatNumber } from "../../utils/numeric";

export default function CurrencyConvert(props) {
	const locales = useLocale();

	const [state, setState] = useState({
		selectedCurrency: 'USD',
		targetCurrency: 'EUR',
		amount: 1,
	});

	const [currenciesData, setCurrenciesData] = useState([]);
	const [result, setResult] = useState(null);
	const [loading, setLoading] = useState(false);

	const onChangeHandler = e => {
		e.persist();
		if (e.target.name === "selected") {
			setState(prev => ({
				...prev,
				selectedCurrency: e.target.value
			}))
		} else {
			setState(prev => ({
				...prev,
				targetCurrency: e.target.value
			}))
		}
	};

	const onInputHandler = e => {
		e.persist();
		setState(prev => ({
			...prev,
			amount: e.target.value
		}))
	};

	const onClickHandler = () => {
		convert();
	};

	const convert = () => {
		if (state.amount && state.selectedCurrency && state.targetCurrency) {
			setLoading(true);
			get(Routes.services.currency.convert(state.selectedCurrency, state.targetCurrency, state.amount), data => {
				setLoading(false);
				setResult(data.result);
			});
		}
	};

	useEffect(() => {
		setLoading(true);
		get(Routes.services.currency.symbols(), data => {
			setCurrenciesData(data.symbols);
			setLoading(false);
		});
	}, []);

	useEffect(() => {
		setResult(null);
	}, [state.selectedCurrency, state.targetCurrency, state.amount]);

	const currenciesOptions = currenciesData ? Object.keys(currenciesData).map((key, index) => ({
		value: key,
		label: `${currenciesData[key]} (${key})`
	})) : [];

	const rate = result / state.amount;

	return (
		<div id="currency-convert">
			<div className="page-container">
				<div className="form-area">
					<div className="form-group">
						<label>{locales.common.amount}</label>
						<input type="number" name="amount" min="0" placeholder="1" onChange={onInputHandler} value={state.amount} />
					</div>
					<div className="form-group">
						<label>{locales.common.from}</label>
						<select value={state.selectedCurrency} onChange={onChangeHandler} name="selected">
							{currenciesOptions.map(currency => (
								<option key={currency.value} value={currency.value}>{currency.label}</option>
							))}
						</select>
					</div>
					<div className="form-group">
						<label>{locales.common.to}</label>
						<select value={state.targetCurrency} onChange={onChangeHandler} name="target">
							{currenciesOptions.map(currency => (
								<option key={currency.value} value={currency.value}>{currency.label}</option>
							))}
						</select>
					</div>
					<CustomButton type="primary" width="100%" height="48px" onClick={onClickHandler} loading={loading}>
						{locales.common.convert}
					</CustomButton>
				</div>
				<div className="result-area">
					<div className="result-card ">
						<h5 style={{ textTransform: 'uppercase' }}>{formatNumber(state.amount, false, 6)} {state.selectedCurrency} =</h5>
						<h1 style={{ textTransform: 'uppercase' }}>{formatNumber(result, false, 6)} {state.targetCurrency}</h1>
						<p style={{ textTransform: 'uppercase' }}>1 {state.selectedCurrency} = {formatNumber(rate, false, 6)} {state.targetCurrency}</p>
						<p style={{ textTransform: 'uppercase' }}>1 {state.targetCurrency} = {formatNumber(1 / rate, false, 6)} {state.selectedCurrency}</p>
					</div>
				</div>
			</div>
		</div>
	);
}