import React, { useState, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import "./dropupload.scss";
import Dropzone from "react-dropzone-uploader";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import Cloud from "../../assets/image/cloud.svg";
import { useLocale } from "../../locale";

const Input = ({
	name,
	customRef,
	accept,
	onFiles,
	files,
	getFilesFromEvent
}) => {
	const locales = useLocale();

	const text = files.length > 0 ? locales.common.add_other_file : locales.common.browse_files;

	return (
		<>
			<p>
				<img src={Cloud} alt="" />
			</p>
			<p>{locales.common.drop_files}</p>
			<p>{locales.common.or}</p>
			<label
				style={{
					backgroundColor: "#007bff",
					color: "#fff",
					cursor: "pointer",
					padding: 15,
					borderRadius: 3
				}}
			>
				{text}
				<input
					name={name}
					ref={customRef}
					style={{ display: "none" }}
					type="file"
					accept={accept}
					multiple
					onChange={e => {
						getFilesFromEvent(e).then(chosenFiles => {
							onFiles(chosenFiles);
						});
					}}
				/>
			</label>
		</>
	);
};

// const Preview = ({ meta }) => {
// 	console.log(meta)
// 	const { name, percent, status } = meta
// 	return (
// 		<span style={{ alignSelf: 'flex-start', margin: '10px 3%', fontFamily: 'Helvetica' }}>
// 			{name}, {Math.round(percent)}%, {status}
// 		</span>
// 	)
// }

function DropUpload({ name, customRef, accept, onUpload, files, setFiles }) {
	const [currentFiles, setCurrentFiles] = useState([]);
	const [currentFilesUpdated, setCurrentFilesUpdated] = useState(null);

	const setFileUploadStatus = (name, size, status) => {
		setCurrentFiles(prev =>
			prev.map(
				f =>
					f.name === name && f.size === size
						? { ...f, uploadStatus: status }
						: f
			)
		);
	};

	const areAllFilesUploaded = () =>
		currentFiles.every(
			f => f.uploadStatus === "done" || f.uploadStatus === "aborted"
		);

	useEffect(
		() => {
			if (areAllFilesUploaded()) {
				onUpload(true);
			} else {
				onUpload(false);
			}
		},
		[currentFilesUpdated]
	);

	const getUploadParams = () => {
		return { url: "https://httpbin.org/post" };
	};

	const handleChangeStatus = ({ meta }, status) => {
		setFileUploadStatus(meta.name, meta.size, status);
		setCurrentFilesUpdated(Math.random());
		if (status === "done" || status === "aborted") {
			if (meta) {
				const newFile = currentFiles.find(
					f => f.name === meta.name && f.size === meta.size
				);
				if (newFile) {
					setFiles(prev => [...prev, newFile.fileObject]);
				}
			}
		} else {
			onUpload(false);
		}
	};

	const handleSubmit = (files, allFiles) => {
		console.log("handleSubmit", files.map(f => f.meta));
		allFiles.forEach(f => f.remove());
	};

	const getFilesFromEvent = e => {
		return new Promise(resolve => {
			getDroppedOrSelectedFiles(e).then(chosenFiles => {
				setCurrentFiles(chosenFiles);
				resolve(chosenFiles.map(f => f.fileObject));
			});
		});
	};

	return (
		<Dropzone
			getUploadParams={getUploadParams}
			onChangeStatus={handleChangeStatus}
			InputComponent={props => (
				<Input {...props} name={name} customRef={customRef} />
			)}
			onSubmit={handleSubmit}
			getFilesFromEvent={getFilesFromEvent}
			accept={accept}
			onFiles={files => { }}
		// PreviewComponent={Preview}
		/>
	);
}

export default DropUpload;
