import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import config from "../../config";


import CloseIcon from "../../assets/image/arrow-left-white.svg";

import ArrowRightIcon from "../../assets/icons/arrow-right";

const BoutiqueMenu = ({ categories, subCategories, stickLeft = false, stickLeftActive }) => {
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedSubCategories, setSelectedSubCategories] = useState([]);
    return (
        <div
            className={
                stickLeft 
                ? `multi-menu-stick-left multi-menu-stick-left-${stickLeftActive ? 'active' : 'inactive'}`
                : "multi-menu-parent"
            }
        >
            <div style={{ width: '361px', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
                <div style={{ width: '361px', display: 'flex', overflow: 'hidden' }}>
                    <div style={{ minWidth: '361px', position: 'relative', left: selectedCategory ? '-361px' : '0' }}>
                        {categories.map((category, key) => (
                                <div key={key} className="nav-item" style={{ 
                                    margin: '5px 10px', padding: '5px',
                                    background: '#414141',
                                    borderRadius: '3px',
                                    minHeight: "60px",
                                    display: 'flex', alignItems: 'center'
                                }}>
                                    <div 
                                        onClick={() => {
                                            setSelectedCategory(category);
                                            setSelectedSubCategories(subCategories.filter(subCategory => category.categoryId === subCategory.categoryId))
                                        }}
                                        style={{ display: 'flex', alignItems: 'center', color: 'white', flex: 1 }}
                                    >
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <div className="icon-part" style={category.icon ? { } : { width: '20px' }}>
                                                <img style={{ maxWidth: '30px' }} src={`${config.files.categoryIcons}/${category.icon}`} alt="" />
                                            </div>
                                        </div>
                                        <div style={{ flex: 3, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                            {category.name} 
                                        </div>
                                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <ArrowRightIcon />
                                        </div>
                                    </div>
                                </div>
                        ))}
                    </div>
                    
                    <div style={{ minWidth: '361px', position: 'relative', left: !selectedCategory ? '361px' : '-361px', maxHeight: '800px', overflowY: 'scroll' }}>
                        <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0 20px 0' }}>
                            <div className="close-side" onClick={() => {
                                setSelectedCategory(null);
                                setSelectedSubCategories([])
                            }}>
                                <img src={CloseIcon} alt="" width="20" style={{ margin: '3px 10px 0 10px', color: 'white' }}/>
                            </div>
                            <span style={{ color: 'white' }}>
                                {selectedCategory?.name} 
                            </span>
                        </div>
                        {
                            selectedSubCategories.map((subCategory, key) => (
                                selectedCategory?.categoryId === subCategory.categoryId &&
                                <NavLink 
                                    key={key} 
                                    to={`/sub-category/${selectedCategory?.categoryId}-${selectedCategory?.slug}/${subCategory.subCategoryId}-${subCategory.slug}`} 
                                    className="nav-item" style={{ 
                                        margin: '5px 10px', padding: '5px',
                                        background: '#414141',
                                        borderRadius: '3px',
                                        height: "60px",
                                        display: 'flex', alignItems: 'center',
                                        color: 'white'
                                    }}
                                >
                                    <div className="icon-part" style={
                                        subCategory.icon ? { } : { width: '20px', marginRight: '10px'}
                                    }>
                                        <img style={{ maxWidth: '20px', marginRight: '10px' }} src={`${config.files.categoryIcons}/${subCategory.icon}`} alt="" />
                                    </div>
                                    {subCategory.name}
                                </NavLink>
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoutiqueMenu