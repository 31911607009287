import React from 'react';
import './hero.scss';

import LeftTopImage from '../../assets/image/1599-px-flag-of-co-te-d-ivoire-svg.png';
import WhiteLogo from '../../assets/image/BTPMATPEN.png';
import { useLocale } from "../../locale";


function Hero() {
	const locales = useLocale();

	return (
		<div id="hero">
			<div className="left-top-absolute">
				<img src={LeftTopImage} alt="leftTopImage" width="200" />
			</div>
			<div className="page-container" style={{ paddingLeft: '32px', paddingRight: '32px' }}>
				<div className="row">
					<div className="hero-action">
						<img src={WhiteLogo} className="hero-logo" alt="" />
						<h1>{"ACHETER DIFFEREMENT\nCONSTRUIRE AUTREMENT"}</h1>
						<p>{locales.home.subtitle}</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Hero;