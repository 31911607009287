import React from 'react';
import './button.scss';

import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const ButtonLoader = ({ style }) => (
	<CircularProgress
		style={{
			position: "absolute",
			top: 9,
			right: 20,
			width: 30,
			height: 30,
			color: "#ffffff",
			...style
		}}
	/>
);

export default class CustomButton extends React.Component {
	PrimaryButton = withStyles((theme) => ({
		root: {
			background: "linear-gradient(to right, #e8832f 0%, #e8592b 100%)",
			width: this.props.width,
			height: this.props.height,
			fontSize: "16px",
			boxShadow: "none",
			color: "#ffffff",
			'&:hover': {
				background: "linear-gradient(to right, #e8832f 0%, #e8592b 100%)",
			},
		},
	}))(Button);
	TransparentButton = withStyles((theme) => ({
		root: {
			background: "#ffffff",
			width: this.props.width,
			height: this.props.height,
			fontSize: "16px",
			border: "1px solid #e8592b",
			boxShadow: "none",
			'&:hover': {
				background: "#ffffff",
				border: "none",
			},
		},
	}))(Button);
	GlassButton = withStyles((theme) => ({
		root: {
			background: "#ffffff",
			width: this.props.width,
			height: this.props.height,
			fontSize: "16px",
			border: "1px solid #eee",
			boxShadow: "none",
			color: "#5a657d",
			'&:hover': {
				background: "#ffffff",
				border: "none",
			},
		},
	}))(Button);
	GlassDarkButton = withStyles((theme) => ({
		root: {
			background: "#eef2f5",
			width: this.props.width,
			height: this.props.height,
			fontSize: "16px",
			border: "none",
			boxShadow: "none",
			color: "#5a657d",
			'&:hover': {
				border: "none",
				background: "#eef2f5",
			},
		},
	}))(Button);
	HiddenButton = withStyles((theme) => ({
		root: {
			visibility: "hidden",
			width: this.props.width,
			height: this.props.height,
			boxShadow: "none"
		},
	}))(Button);
	CrystalButton = withStyles((theme) => ({
		root: {
			background: "none",
			width: this.props.width,
			height: this.props.height,
			fontSize: "16px",
			border: "none",
			boxShadow: "none",
			color: this.props.color,
			'&:hover': {
				border: "none",
				background: "none",
			},
		},
	}))(Button);
	render() {
		if (this.props.hidden) {
			return (
				<this.HiddenButton variant="contained" href={this.props.href} target={this.props.target} value={this.props.value} className={this.props.className} style={{ ...this.props.style }}>
				</this.HiddenButton>
			);
		}

		if (this.props.type === "primary") {
			return (
				<this.PrimaryButton
					variant="contained"
					{...(this.props.isSubmit ? { type: "submit" } : {})}
					onClick={this.props.onClick}
					disabled={this.props.loading}
					href={this.props.href}
					target={this.props.target}
					value={this.props.value}
					className={this.props.className}
                    title={this.props.title}
					style={{ ...this.props.style }}
				>
					{this.props.loading && <ButtonLoader />}
					{this.props.children}
				</this.PrimaryButton>
			)
		} else if (this.props.type === "transparent") {
			return (
				<this.TransparentButton variant="contained" onClick={this.props.onClick} disabled={this.props.loading} href={this.props.href} target={this.props.target} value={this.props.value} className={this.props.className} style={{ ...this.props.style }}>
					<span style={{ color: "#e8592b", fontFamily: "AktivGrotesk", fontWeight: "bold", letterSpacing: "-0.3px", textTransform: "none" }}>
						{this.props.loading && <ButtonLoader style={{ color: "#e8592b" }} />}
						{this.props.children}
					</span>
				</this.TransparentButton>
			)
		} else if (this.props.type === "glass") {
			return (
				<this.GlassButton variant="contained" onClick={this.props.onClick} disabled={this.props.loading} href={this.props.href} target={this.props.target} value={this.props.value} className={this.props.className} style={{ ...this.props.style }}>
					<span style={{ fontFamily: "AktivGrotesk", fontWeight: "bold", letterSpacing: "-0.3px", textTransform: "none" }}>
						{this.props.loading && <ButtonLoader />}
						{this.props.children}
					</span>
				</this.GlassButton>
			)
		} else if (this.props.type === "glassDark") {
			return (
				<this.GlassDarkButton variant="contained" onClick={this.props.onClick} disabled={this.props.loading} href={this.props.href} target={this.props.target} value={this.props.value} className={this.props.className} style={{ ...this.props.style }}>
					<span style={{ fontFamily: "AktivGrotesk", fontWeight: "bold", letterSpacing: "-0.3px", textTransform: "none" }}>
						{this.props.loading && <ButtonLoader />}
						{this.props.children}
					</span>
				</this.GlassDarkButton>
			)
		} else {
			return (
				<this.CrystalButton onClick={this.props.onClick} disabled={this.props.loading} href={this.props.href} target={this.props.target} value={this.props.value} className={this.props.className} style={{ ...this.props.style }}>
					<span style={{ fontFamily: "AktivGrotesk", fontWeight: "bold", letterSpacing: "-0.3px", textTransform: "none" }}>
						{this.props.loading && <ButtonLoader />}
						{this.props.children}
					</span>
				</this.CrystalButton>
			)
		}
	}
}