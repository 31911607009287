import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { useSwipeable } from "react-swipeable";

import "./products-carousel.scss";
import CustomButton from "../../components/button/button";
import ProductCard from "../product-card/product-card";
import LeftArrow from "../../assets/image/left-arrow.svg";
import RightArrow from "./../../assets/image/right-arrow.svg";
import { useResize } from "../../utils/layout";
import { useLocale } from "../../locale";

function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <img src={RightArrow} alt="next" />
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className={className} style={{ ...style }} onClick={onClick}>
            <img src={LeftArrow} alt="previous" />
        </div>
    );
}

export default function ProductsCarousel(props) {
    const { products = [] } = props;

    const [currentProductIndex, setCurrentProductIndex] = useState(0);

    const sliderContainer = useRef();

    const { width: sliderContainerWidth } = useResize(sliderContainer);
    const sliderItemWidth =
        sliderContainerWidth <= 480 ? 156 : sliderContainerWidth <= 768 ? 170 : 287;

    const displayedProducts = products.slice(0, currentProductIndex + 10);

    const sliderItemsWidth = displayedProducts.length * sliderItemWidth;

    const sliderOffset =
        sliderItemsWidth < sliderContainerWidth
            ? 0
            : Math.min(
                  sliderItemsWidth - sliderContainerWidth,
                  sliderItemWidth * currentProductIndex
              );

    const doIncrementCurrentProductIndex = () => {
        return sliderOffset < sliderItemsWidth - sliderContainerWidth;
    };

    const goPrev = () => setCurrentProductIndex(x => Math.max(0, x - 1));
    const goNext = () => setCurrentProductIndex(x => Math.min(displayedProducts.length - 1, x + 1));

    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (doIncrementCurrentProductIndex()) {
                goNext();
            }
        },
        onSwipedRight: goPrev,
    });

    const locales = useLocale();

    return (
        <div id={props.id} className="product-carousel">
            <div className="page-container">
                <div className="title">
                    <h3>{props.title ? props.title : props.category.name}</h3>
                    {props.showSeeAll && (
                        <Link
                            to={
                                props.seeAllLink
                                    ? props.seeAllLink
                                    : `/sub-category/${props.parentCategory.categoryId}-${props.parentCategory.slug}/${props.category.subCategoryId}-${props.category.slug}`
                            }
                        >
                            <CustomButton
                                width="182px"
                                height="32px"
                                type="transparent"
                                style={{ fontWeight: "500", fontSize: "12px" }}
                            >
                                {locales.shop.see_all_products}
                            </CustomButton>
                        </Link>
                    )}
                </div>
                <div className="row" />
            </div>
            <div ref={sliderContainer} className="slider-container" {...swipeHandlers}>
                <div
                    className="slider-items"
                    style={{
                        marginLeft: -sliderOffset,
                    }}
                >
                    {displayedProducts.map((item, key) => (
                        <div key={key} className="slider-item">
                            <ProductCard state={item} />
                        </div>
                    ))}
                </div>
                {currentProductIndex >= 1 ? (
                    <SamplePrevArrow className="slider-prev" onClick={goPrev} />
                ) : null}
                {doIncrementCurrentProductIndex() ? (
                    <SampleNextArrow className="slider-next" onClick={goNext} />
                ) : null}
            </div>
        </div>
    );
}
