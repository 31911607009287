import React from 'react';
import './banner.scss';

export default function Banner(props) {
	return (
		<div id="banner">
			<div className="page-container">
				<div className="brand-mark">
					<img src={"https://s3.eu-west-3.amazonaws.com/btpmat-category-icons/" + props.image} alt={props.title} />
				</div>
				<h1>{props.title}</h1>
			</div>
		</div>
	)
}