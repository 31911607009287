import React from 'react';
import './home-categories.scss'
import { useSelector } from "react-redux";

import ProductCard from "../product-card/product-card";
import ProductsSelectors from "../../selectors/products";


const HomeCategorie  = ({ title, products, banner = null, bannerLink }) => {
    return (
        <div className="column-home-categories">
            <div className="opacity-title">
                <h2>{title}</h2>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column'
            }}>
                <div className="home-categories-products-container">
                    {products.map((pr, i) => {
                        return (
                            <div className="home-category-product-wrapper">
                                <ProductCard state={pr} />
                            </div>
                        )
                    })}
                </div>
                {banner ? (
                    <a href={bannerLink} style={{ 
                        display: 'flex', justifyContent: 'center', alignItems: 'center',
                        padding: '10px 20px'
                    }}>
                        <img 
                        src={`https://s3.eu-west-3.amazonaws.com/btpmat-ads/${banner}`} 
                        style={{ 
                            width: '100%', 
                            maxWidth: '1192px',  
                            borderRadius: '10px' 
                        }}
                        />
                    </a>
                ) : null}
            </div>
        </div>
    )
}

const HomeCategories = () => {
    const homeCategories = useSelector(ProductsSelectors.homeCategories());
	const language = useSelector(state => state.locale.language);

    return (
        <div 
            id="home-categories-container"
            style={{ 
                background: ` rgba(230,230,230, 0.1) url('/background.png')`,
                backgroundRepeat: 'repeat',
                paddingTop: '30px'
            }}
        >
            {homeCategories.map((hc, i) => (
                <HomeCategorie
                    title={hc[(language === 'fr' ? 't' : (language + 'T')) + 'itle']}
                    products={hc.products}
                    banner={hc.banner || null}
                    bannerLink={hc.bannerLink}
                    key={`HomeCategorie__${i}`}
                />
            ))}
        </div>
    )
}

export default HomeCategories