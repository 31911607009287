import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

export default ({ value, onChange }) => (
	<PhoneInput
		country={"ci"}
		value={value}
		onChange={(newValue, country) => onChange(`+${newValue}`, country.dialCode, (country.countryCode || "").toUpperCase())}
		inputStyle={{
			borderRadius: 0
		}}
		buttonStyle={{
			top: 10,
			bottom: 10,
			borderRadius: 3
		}}
        enableLongNumbers={10}
	/>
);
