import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import './addresses.scss';

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import CustomButton from '../../button/button';
import ArrowRightIcon from '../../../assets/image/arrow-right.svg';
import Routes, { get } from "../../../services/api";
import { useLocale } from "../../../locale";

export default function Addresses(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const userStore = useSelector(state => state.user);
	const user = userStore.data;
	const addresses = userStore.addresses;

	useEffect(() => {
		if (user.token) {
			get(Routes.users.get.addresses(user.userId), data => {
				dispatch({
					type: "SET_USER_ADDRESSES",
					value: data.userAddresses
				});
			});
		}
	}, []);

	return (
		<div id="addresses">
			<div className="page-container">
				<div className="flex-end">
					<Link to="/espacepersonnel/carnetdadresses-new">
						<CustomButton type="transparent" width="228px" height="48px">+  {locales.dashboard.addresses.go_to_new}</CustomButton>
					</Link>
				</div>
				<div className="row">
					{addresses.map(address => (
						<Link key={address.userAddressId} to={`/espacepersonnel/carnetdadresses/${address.userAddressId}`}>
							<Button>
								<div className={`address-card ${address.isDefaultAddress ? "active" : ""}`}>
									<div className="pretail" />
									<h3>{address.userAddressName}{address.isDefaultAddress ? ` (${locales.common.default})` : ""}</h3>
									<p>{address.address}</p>
									<img src={ArrowRightIcon} alt="" />
								</div>
							</Button>
						</Link>
					))}
				</div>
			</div>
		</div >
	)
}