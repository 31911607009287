import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import './register.scss';
import BackIcon from '../../../assets/image/icons-back-black.svg';
import Authentication from '../../authentication/authentication';
import CustomButton from '../../button/button';
import { Button } from '@material-ui/core';
import { useLocale } from "../../../locale";

export default function Register(props) {
	const history = useHistory();
	const locales = useLocale();

	const [authenticationModalState, setAuthenticationModalState] = useState({
		isMiniCart: false,
		isAuthenticationModal: true,
		isRegister: false,
		isOverlay: false,
		isModal: false
	});

	const [exporting, setExporting] = useState(false);

	const authenticationModalHandles = {
		openModal: (isRegister) => {
			setAuthenticationModalState(prev => ({ ...prev, isModal: true, isOverlay: true, isRegister }));
		},
		closeModal: () => {
			setAuthenticationModalState(prev => ({ ...prev, isModal: false, isOverlay: false, isMiniCart: false }));
			props.setStep("default");
		}
	};

	const callback = {
		auth: () => history.push("/checkout"),
		auth_export: (event, onClose) => {
			setExporting(true);
			props.exportAsQuote(() => {
				if (onClose) {
					onClose();
				}
				setExporting(false);
			})
		}
	}[props.step] || (() => { });

	return (
		<>
			<div className="order-register-modal">
				<div className="order-overlay" />
				<div className="order-register">
					<div className="head">
						<div className="back-button">
							<Button onClick={() => props.setStep("default")}>
								<img src={BackIcon} alt="" />
							</Button>
						</div>
						<div>
							<h4>
								{locales.cart.go_to_order.instruction1}
							</h4>
						</div>
					</div>
					<div className="action-buttons">
						<CustomButton onClick={() => authenticationModalHandles.openModal(true)} type="primary" width="100%" height="48px" className="action">
							{locales.onboarding.signup.go_to_email}
						</CustomButton>
						<CustomButton onClick={() => authenticationModalHandles.openModal(true)} type="primary" width="100%" height="48px" className="action">
							{locales.onboarding.signup.go_to_phone}
						</CustomButton>
						<CustomButton onClick={callback} type="transparent" width="100%" height="48px" className="action" loading={exporting && !authenticationModalState.isModal}>
							{locales.common.later}
						</CustomButton>
						<CustomButton onClick={() => authenticationModalHandles.openModal(false)} type="Crystal" color="#fec200" width="100%" height="48px" className="action">
							{locales.cart.go_to_login}
						</CustomButton>
					</div>
				</div>
			</div>
			<Authentication
				state={authenticationModalState}
				handles={authenticationModalHandles}
				onSuccess={callback}
				inMiniCart
				miniCartFlow={{
					auth: "order",
					auth_export: "export"
				}[props.step] || null}
			/>
		</>
	)
}
