import React from 'react';
import './review.scss';
import config from "../../config";


function Review(props) {
	return (
		<div className="review-cart">
			<img src=
			{`${config.files.reviews}/${props.data.avatar}`} className="avatar" alt="" />
			<div>
				<img src={`${config.files.reviews}/${props.data.logo}`} alt="" />
				<h5>{props.data.title}</h5>
				<p>{props.data.content}</p>
			</div>
		</div>
	);
}

export default Review