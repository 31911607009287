const initialState = {
	categories: [],
	subCategories: [],
	products: [],
	filters: {
		search: "",
		price: {
			min: null,
			max: null,
			sort: "ASC" // ASC ou DESC
		},
		category: null,
		subCategories: [],
		discountTypes: [] // "DISCOUNT", "DESTOCKING"
	},
    homeCategories: [],
    homeAds: []
};

const setHomeCategories = (state, action) => ({ ...state, homeCategories: action.value });
const setHomeAds = (state, action) => ({ ...state, homeAds: action.value });

const setProductAttribute = (state, action, key, valueKey) => {
	return {
		...state,
		products: state.products.map(
			product =>
				product.productId === action.value.productId
					? {
						...product,
						[key]: action.value[valueKey]
					}
					: product
		)
	};
};

const assignProducts = (state, newProducts) => {
	return {
		...state,
		products: state.products.map(
			product => {
				const newProduct = newProducts.find(p => p.productId === product.productId)
				return newProduct
					? {
						...product,
						...newProduct
					}
					: product
			})
	};
};

const setPriceFilter = (state, action, key) => {
	return {
		...state,
		filters: {
			...state.filters,
			price: {
				...state.filters.price,
				[key]: action.value
			}
		}
	};
};

const setPriceRange = (state, action) => {
	return {
		...state,
		filters: {
			...state.filters,
			price: {
				...state.filters.price,
				min: action.value.min,
				max: action.value.max
			}
		}
	};
};

export default (state = initialState, action) => {
	switch (action.type) {
		case "SET_CATEGORIES":
			return { ...state, categories: action.value };
		case "SET_SUBCATEGORIES":
			return { ...state, subCategories: action.value };
		case "SET_PRODUCTS":
			// keep quantities in cart
			const cartProducts = state.products.filter(
				product => product.quantityInCart && product.quantityInCart >= 1
			);
			const newProducts = action.value.map(product => {
				const cartProduct = cartProducts.find(
					cp => cp.productId === product.productId
				);
				return {
					...product,
					...(cartProduct ? { quantityInCart: cartProduct.quantityInCart } : {})
				};
			});
			return { ...state, products: newProducts };
		case "SET_PRODUCT_QUANTITY_IN_CART":
			return setProductAttribute(state, action, "quantityInCart", "quantity");
		case "ASSIGN_PRODUCTS":
			return assignProducts(state, action.value);
		case 'RESET_CART':
			return {
				...state,
				products: state.products.map(product => {
					const newProduct = { ...product };
					delete newProduct.quantityInCart;
					return newProduct;
				})
			};
		case "SET_PRODUCTS_SEARCH":
			return {
				...state,
				filters: {
					...state.filters,
					search: action.value
				}
			};
		case "SET_CATEGORY_FILTER":
			return {
				...state,
				filters: {
					...state.filters,
					category: action.value
				}
			};
		case "SET_SUBCATEGORIES_FILTER":
			return {
				...state,
				filters: {
					...state.filters,
					subCategories: action.value
				}
			};
		case "SET_DISCOUNT_TYPES_FILTER":
			return {
				...state,
				filters: {
					...state.filters,
					discountTypes: action.value
				}
			};
		case "SET_MIN_PRICE_FILTER":
			return setPriceFilter(state, action, "min");
		case "SET_MAX_PRICE_FILTER":
			return setPriceFilter(state, action, "max");
		case "SET_PRICE_FILTER_RANGE":
			return setPriceRange(state, action);
		case "SET_PRICE_SORT":
			return setPriceFilter(state, action, "sort");
		case "CLEAR_PRICE_FILTERS":
			return {
				...state,
				filters: {
					...state.filters,
					price: { ...state.filters.price, min: null, max: null }
				}
			};
		case "RESET_FILTERS":
			return {
				...state,
				filters: {
					...state.filters,
					price: initialState.filters.price,
					subCategories: initialState.filters.subCategories,
					discountTypes: initialState.filters.discountTypes
				}
			};
        case "SET_HOME_CATEGORIES": 
            return setHomeCategories(state, action)
        case "SET_HOME_ADS":
            return setHomeAds(state, action)
		default:
			return state;
	}
};
