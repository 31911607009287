import { VAT } from "../constants/payment";

const formatNumber = (n, useGrouping, decimals = 0) => {
	let parsed = parseFloat(n);
	if (!Number.isFinite(parsed)) {
		console.error("Erreur lors de l'affichage du nombre", n);
		return "0";
	}
	let formatted = parsed.toFixed(decimals);
	if (useGrouping) {
		formatted = formatted.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
	}
	return formatted.replace(".", ",");
};

const formatPrice = (price, toTTC = false, unit = "XOF") => {
	const value = Math.round(parseFloat(price) * (toTTC ? 1 + VAT : 1))
		.toString()
		.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");

	return `${value}${unit ? ` ${unit}` : ""}`;
};

export { formatNumber, formatPrice };
