import React from 'react';
import './iconAction.scss';

export default function IconAction(props) {
	return (
		<div className="icon-action">
			<img src={props.item.icon} alt="" />
			<p>{props.item.title1}</p>
		</div>
	);
}