import React, { useState, useEffect } from 'react';
import Modal from '../modal/modal';
import Login from './login/login';
import Forget from './forget/forget';
import Register from './register/register';
import Success from './success/success';

export default function Authentication(props) {
	const [state, setState] = useState({
		currentScreen: props.state.isRegister ? 'role' : 'login'
	});

	useEffect(() => {
		setState(prev => ({
			...prev,
			currentScreen: props.state.isRegister ? 'role' : 'login'
		}))
	}, [props.state.isRegister]);

	const renderSwitch = (param) => {
		switch (param) {
			case 'login':
				return <Login stateSwitch={stateSwitch} setCurrentScreen={setCurrentScreen} onSuccess={props.onSuccess} />;
			case 'login-result':
				return <Success stateSwitch={stateSwitch} state={state} onSuccess={props.onSuccess} inMiniCart={props.inMiniCart} miniCartFlow={props.miniCartFlow} />;
			case 'forget':
				return <Forget stateSwitch={stateSwitch} setCurrentScreen={setCurrentScreen} />;
			case 'forget-result-email':
				return <Success stateSwitch={stateSwitch} state={state} mode="email" />;
			case 'forget-result-phone':
				return <Success stateSwitch={stateSwitch} state={state} mode="phone" />;
			case 'user':
				return <Register stateSwitch={stateSwitch} setCurrentScreen={setCurrentScreen} state={state} />;
			case 'password':
				return <Register stateSwitch={stateSwitch} setCurrentScreen={setCurrentScreen} state={state} />;
			case 'verification':
				return <Register stateSwitch={stateSwitch} setCurrentScreen={setCurrentScreen} state={state} />;
			case 'role':
				return <Register stateSwitch={stateSwitch} state={state} />;
			case 'success':
				return <Success stateSwitch={stateSwitch} state={state} onSuccess={props.onSuccess} inMiniCart={props.inMiniCart} miniCartFlow={props.miniCartFlow} />;
			default:
				return <Login stateSwitch={stateSwitch} onSuccess={props.onSuccess} />;
		}
	}
	const stateSwitch = (event) => {
		var param = event.target.value;
		setState(prev => ({ ...prev, currentScreen: param }));
	}
	const setCurrentScreen = currentScreen => {
		setState(prev => ({ ...prev, currentScreen }));
	}
	return (
		<>
			<Modal state={props.state} handles={props.handles}>
				{
					renderSwitch(state.currentScreen)
				}
			</Modal>
		</>
	)
}