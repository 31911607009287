import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import Header1 from "../components/header/header1";
import ProductsTile from "../components/products-tile/products-tile";
import Footer from "../components/footer/footer";
import ProductsSelectors from "../selectors/products";

function ProductsPage() {
    const { feature } = useParams();

    const products = useSelector(
        state =>
            ({
                nouveautes: ProductsSelectors.novelty(state),
                promotions: ProductsSelectors.promotions(state),
                'top-ventes': ProductsSelectors.topSales(state)
            }[feature] || [])
    );

    return (
        <>
            <Header1 />
            <ProductsTile products={products} style={{ marginTop: 40 }} />
            <Footer />
        </>
    );
}

export default ProductsPage;
