import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import ProductsSelectors from "../selectors/products";
import CategoriesSelectors from "../selectors/categories";
import SubCategoriesSelectors from "../selectors/subCategories";

import Header1 from "../components/header/header1";
import SimpleBreadcrumbs from "../components/breadcrumb/breadcrumb";
import Banner from "../components/banner/banner";
import ProductFilter from "../components/product-filter/product-filter";
import ProductsTile from "../components/products-tile/products-tile";
import LeftImageAction from "../components/left-image-action/left-image-action";
import Spacer from "../components/spacer/spacer";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";

export default function SubCategoryPage(props) {
	const { categoryId, subCategoryId } = useParams();

	const category = useSelector(CategoriesSelectors.bySlug(categoryId));
	const subCategory = useSelector(SubCategoriesSelectors.bySlug(subCategoryId));
	const products = useSelector(state => ProductsSelectors.applyFilters(state));

	const subCategoryFormatted = {
		...subCategory,
		products: products.filter(product =>
			product.subCategories.includes(subCategory.subCategoryId)
		)
	};

	return (
		<>
			<Header1 />
			{
				subCategoryId && <>
					<SimpleBreadcrumbs
						title={subCategory.name}
						style={{ background: "#fafbfc" }}
					/>
					<Banner image={category.icon} title={subCategory.name} />
				</>
			}
			{subCategoryId ?
				<>
					<ProductFilter />
					<ProductsTile
						key={subCategoryFormatted.subCategoryId}
						category={{ name: subCategoryFormatted.name }}
						products={subCategoryFormatted.products}
					/>
				</> :
				<>No sub category</>
			}
			<Spacer height="30" />
			<LeftImageAction />
			<IconActions />
			<Footer />
		</>
	);
}
