import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import "./footer.scss";
import Logo from "../../assets/image/BTPMATPEN-dark.png";
import MasterCard from "../../assets/image/master-card.svg";
import ExpressCard from "../../assets/image/american-express.svg";
import Visa from "../../assets/image/visa.svg";
import VisaElectron from "../../assets/image/visa-electron.svg";
import AppStoreImage from "../../assets/image/appstore.png";
import PlayStoreImage from "../../assets/image/playstore.png";
import WhatsAppIcon from "../../assets/icons/social/whatsapp.svg";

import Button from "@material-ui/core/Button";
import InfosSelectors from "../../selectors/infos";
import { formatParagraphs } from "../../utils/strings";
import { useLocale } from "../../locale";

function Footer() {
    const locales = useLocale();

    const infos = useSelector((state) => state.app.infos);
    const adminSettings = useSelector(state => state.adminSettings.adminSettings);

    const summaryTitle = useSelector(
        InfosSelectors.contentFromType("TITRE_RESUME")
    );
    const summaryContent = useSelector(
        InfosSelectors.contentFromType("CONTENU_RESUME")
    );

    return (
        <footer id="footer">
            <div className="footer-top">
                <hr />
                <div className="page-container">
                    <div className="logo-container">
                        <img src={Logo} width="266" alt="" />
                    </div>
                </div>
            </div>
            <div className="footer-main">
                <div className="page-container">
                    <div className="row">
                        <div className="column">
                            <div className="row">
                                <div className="column">
                                    <div className="block">
                                        <h3>{locales.contact.office}</h3>
                                        <p>
                                            COCODY, RIVIERA PALMERAIE SAINT
                                            VIATEUR
                                        </p>
                                    </div>
                                    <div className="block">
                                        <h3>{locales.contact.phone}</h3>
                                        <p>
                                            {
                                                adminSettings.phoneFooter 
                                                ? adminSettings.phoneFooter 
                                                : "+ 225 69 22 22 22"
                                            }
                                        </p>
                                    </div>
                                </div>
                                <div className="column">
                                    <div className="block">
                                        <h3>{locales.payment.methods.title}</h3>
                                        <div className="payment-logo">
                                            <img src={MasterCard} alt="" />
                                            <img src={ExpressCard} alt="" />
                                            <img
                                                src={Visa}
                                                style={{
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px",
                                                }}
                                                alt=""
                                            />
                                            <img
                                                src={VisaElectron}
                                                style={{
                                                    paddingLeft: "5px",
                                                    paddingRight: "5px",
                                                }}
                                                alt=""
                                            />
                                        </div>
                                        <p>{locales.payment.methods.info1}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="column">
                            <div className="app-button-area">
                                <Button href="https://apps.apple.com/ca/app/btpmat/id1507413020?ign-mpt=uo%3D2">
                                    <img src={AppStoreImage} alt="" />
                                </Button>
                                <Button href="https://play.google.com/store/apps/details?id=ci.btpmat.app">
                                    <img src={PlayStoreImage} alt="" />
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-container footer-bottom">
                <nav className="footer-nav">
                    <section>
                        <h2>{locales.info.infos.title}</h2>
                        <div className="footer-nav-links">
                            <Link to="/faq">{locales.info.faq.title}</Link>
                            {infos
                                .filter(
                                    (info) =>
                                        ![
                                            "TITRE_RESUME",
                                            "CONTENU_RESUME",
                                        ].includes(info.dataType)
                                )
                                .map((info) => (
                                    <Link
                                        key={info.infoId}
                                        to={`/info/${info.slug}`}
                                    >
                                        {info.type}
                                    </Link>
                                ))}
                        </div>
                    </section>
                </nav>
                <div className="summary">
                    <h2>{summaryTitle}</h2>
                    {formatParagraphs(summaryContent)}
                </div>
                <p className="copyright">
                    &copy; BTPMAT - Tous droits réservés
                </p>
            </div>
            <a
                target="_blank"
                className="whatsapp-link"
                href="https://wa.me/2250769222222"
            >
                <img src={WhatsAppIcon} alt="WhatsApp" />
            </a>
        </footer>
    );
}

export default Footer;
