import { VAT } from "../constants/payment";

export const resetOrderData = dispatch => {
	dispatch({ type: "RESET_CART" });
	dispatch({ type: "RESET_PAYMENT_DATA" });
	dispatch({ type: "RESET_COUPON" });
};

export const toTTC = amount => Math.floor(amount * (1 + VAT));

export const withDiscount = (amount, discountPercentage) => amount * (1 - discountPercentage / 100);

export const computeProductCouponDiscountedPrice = (product, effectivePrice, coupon) =>
	(coupon && coupon.discountPercentage &&
		((coupon.categories && coupon.categories.some(c => product.categories.includes(c)))
			|| (coupon.products && coupon.products.includes(product.productId))))
		? withDiscount(effectivePrice, coupon.discountPercentage)
		: effectivePrice;

export const isCouponGlobal = (coupon) =>
	(!coupon.categories || !coupon.categories.length) && (!coupon.products || !coupon.products.length)

export const getOrderData = (products, coupon, quote) => {
	let couponEffectiveDiscount = 0;

	const enrichedProducts = products.map((product, index) => {
		const price = product.reducedPrice ? product.reducedPrice : product.originalPrice;
		const discountedPrice = computeProductCouponDiscountedPrice(product, price, coupon);
		couponEffectiveDiscount += price - discountedPrice;

		return {
			...product,
			effectivePrice: price,
			discountedPrice
		};
	});

	let totalCartPrice = enrichedProducts.reduce((sum, product) =>
		sum + product.quantity * product.discountedPrice, 0
	);

	if (quote && quote.totalEstimate) {
		totalCartPrice += quote.totalEstimate;
	}

	let totalPrice = totalCartPrice;

	if (coupon && isCouponGlobal(coupon)) {
		couponEffectiveDiscount = totalCartPrice * coupon.discountPercentage / 100;
		totalPrice = totalCartPrice - couponEffectiveDiscount;
	}

	const totalTTC = toTTC(totalPrice);

	return {
		totalHT: totalPrice,
		totalTTC,
		couponEffectiveDiscount,
		products: enrichedProducts
	};
}
