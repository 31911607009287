import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import './mobile.scss';
import CustomButton from '../../button/button';
import PhoneInput from "../../forms/phone-input";
import Routes, { patch } from "../../../services/api";
import { resetOrderData } from "../../../utils/orders";
import { useLocale } from "../../../locale";

export default function PaymentMobile(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const user = useSelector(state => state.user.data);
	const order = useSelector(state => state.order);

	const [email, setEmail] = useState(user.email || "");
	const [phone, setPhone] = useState(user.phone || "");

	const setContactMethod = (contactMethod, contactValue) => {
		patch(Routes.orders.patch.contact(order.orderId, contactMethod, contactValue), data => {
			patch(Routes.orders.patch.paymentMode(order.orderId, "MOBILE"), data => {
				resetOrderData(dispatch);
				props.handles.setCurrentScreen("paymentSuccess");
			});
		});
	};

	return (
		<div id="payment-mobile">
			<div className="page-container">
				<h1 className="page-title">{locales.payment.mobile.title}</h1>
				<p className="page-description">{locales.payment.mobile.instruction1}</p>
				<div className="form-container">
					<h3>+225 (07) 69 22 22 22<br />+225 (07) 79 44 44 44</h3>
					<p className="explain">{locales.payment.mobile.instruction2}</p>
					<div className="form-group">
						<label htmlFor="email">{locales.common.your_email}</label>
						<input type="email" name="email" className="form-control" value={email} onChange={e => setEmail(e.target.value)} />
					</div>
					<CustomButton
						type="primary"
						width="100%"
						height="48px"
						onClick={() => setContactMethod("EMAIL", email)}
						value="paymentReview"
					>
						{locales.payment.mobile.go_to_email}
					</CustomButton>
					<div className="form-group">
						<label htmlFor="phone">{locales.common.your_phone_number}</label>
						<PhoneInput
							value={phone}
							onChange={phone => setPhone(phone)}
						/>
					</div>
					<CustomButton
						type="primary"
						width="100%"
						height="48px"
						onClick={() => setContactMethod("PHONE", phone)}
						value="paymentReview"
					>
						{locales.payment.mobile.go_to_phone}
					</CustomButton>
					{/*<CustomButton type="primary" width="100%" height="48px" onClick={props.handles.nextAction} value="paymentReview">Par WhatApps</CustomButton>*/}
				</div>
			</div>
		</div>
	)
}