import React from 'react';
import './header1.scss';

import HeaderMain from './header-main';
import HeaderMobileStatic from './header-mobile-static';
import MiniCart from '../mini-cart/mini-cart';
import Authentication from '../authentication/authentication';
class Header1 extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isMiniCart: false,
			isAuthenticationModal: true,
			isOverlay: false,
			isModal: false,
			cart: {
				items: [],
				subTotal: 0,
			},
			account: {
				login: {

				}
			}
		}
		this.handles.miniCartHandleOpen = this.handles.miniCartHandleOpen.bind(this);
		this.handles.openModal = this.handles.openModal.bind(this);
		this.handles.closeModal = this.handles.closeModal.bind(this);
	}

	handles = {
		miniCartHandleOpen: () => {
			this.setState({ isMiniCart: true, isOverlay: true });
		},
		openModal: () => {
			this.setState({ isModal: true, isOverlay: true })
		},
		closeModal: () => {
			this.setState({ isModal: false, isOverlay: false, isMiniCart: false, })
		}
	}

	render() {
		return (
			<>
                <header id="header1">
                    {/* <HeaderTop /> */}
                    <HeaderMain state={this.state} handles={this.handles} />
                    <HeaderMobileStatic state={this.state} handles={this.handles} />
                    <MiniCart state={this.state} handles={this.handles} />
                    <Authentication state={this.state} handles={this.handles} />
                </header >
                <div className="fixed-fixed"/>
            </>
		);
	}
}

export default Header1