import React from "react";

import "./step.scss";
import UndrawCollecting from "./../../../assets/image/undraw-collecting.svg";
import IconsFlag from "./../../../assets/image/icons-flag.svg";
import IconsLivreur from "./../../../assets/image/icons-livreur.svg";
import IconDelivered from "./../../../assets/image/undraw-delivered.svg";
import GoogleMapReact from "google-map-react";
import { useLocale } from "./../../../locale";
import { BtpmatPosition } from "./../../../constants/geo";
import config from "./../../../config";

const defaultProps = {
	center: BtpmatPosition,
	zoom: 11
};

// props.currentStep 0: livrée avec succès, 1: En cours, 2: Collecte, 3: Livraison
export default function Step(props) {
	const locales = useLocale();

	const Marker = props => {
		const { type } = props;
		return (
			<div className="marker">
				{type === "start" ? (
					<img src={IconsLivreur} alt="Start Point" />
				) : (
					<img src={IconsFlag} alt="Start Point" />
				)}
			</div>
		);
	};

	return (
		<div
			className={
				props.currentStep > 2 ? "order-steps-area map" : "order-steps-area"
			}
		>
			{props.currentStep > 2 && (
				<div style={{ height: "241px", width: "100%" }}>
					{["TO_ADDRESS", "CLICK_AND_COLLECT"].includes(props.deliveryType) ? (
						<GoogleMapReact
							bootstrapURLKeys={{
								key: config.googleApiKey
							}}
							defaultCenter={defaultProps.center}
							defaultZoom={defaultProps.zoom}
						>
							<Marker
								lat={BtpmatPosition.lat}
								lng={BtpmatPosition.lng}
								type="start"
							/>
							<Marker
								lat={props.deliveryAddress.lat}
								lng={props.deliveryAddress.lng}
								type="end"
							/>
						</GoogleMapReact>
					) : (
						<GoogleMapReact
							bootstrapURLKeys={{
								key: config.googleApiKey
							}}
							defaultCenter={defaultProps.center}
							defaultZoom={defaultProps.zoom}
						>
							<Marker
								lat={BtpmatPosition.lat}
								lng={BtpmatPosition.lng}
								type="end"
							/>
						</GoogleMapReact>
					)}
				</div>
			)}
			<div
				className={
					props.currentStep > 2 ? "order-steps box-shadow" : "order-steps"
				}
			>
				{props.currentStep < 3 && (
					<div className="step-image">
						{
							{
								0: <img src={IconDelivered} alt="" />,
								1: <img src={UndrawCollecting} alt="" />,
								2: <img src={UndrawCollecting} alt="" />,
								default: <></>
							}[props.currentStep]
						}
					</div>
				)}
				{props.currentStep !== 0 ? (
					<div className="step-area">
						<div className={props.currentStep > 0 ? "step active" : "step"}>
							<div className="progress" />
							<p>{locales.orders.steps.awaiting}</p>
						</div>
						<div className={props.currentStep > 1 ? "step active" : "step"}>
							<div className="progress" />
							<p>{locales.orders.steps.preparing}</p>
						</div>
						<div className={props.currentStep > 2 ? "step active" : "step"}>
							<div className="progress" />
							<p>
								{["TO_ADDRESS", "CLICK_AND_COLLECT"].includes(
									props.deliveryType
								)
									? locales.orders.steps.delivering.to_address
									: locales.orders.steps.delivering.click_and_collect}
							</p>
						</div>
					</div>
				) : (
					<div className="text-center">
						<p className="success-string">
							{locales.dashboard.orders.delivered.info1}
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
