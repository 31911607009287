import React from 'react';
import { useSelector } from "react-redux";
import './home-offers.scss'

import Carousel from 'nuka-carousel';

import ProductsSelectors from "../../selectors/products";

import ChevronRight from '../../assets/image/chevron-right.png';
import ChevronLeft from '../../assets/image/chevron-left.png';

const buttonContainerStyle = {
    background: 'white',
    width: '40px', height: '40px',
    display: 'flex', justifyContent: 'center', alignItems: 'center',
    borderRadius: '50%'
}

const HomeOffers = () => {
    const homeAds = useSelector(ProductsSelectors.homeAds());
    return (
        <div id="home-carousel-container">
            <div id="home-carousel-sub-container">
                <Carousel
                    autoplay={true}
                    pauseOnHover={true}
                    renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
                        <div 
                            onClick={previousSlide} 
                            disabled={previousDisabled}
                            style={{ 
                                 ...buttonContainerStyle,
                                 paddingRight: '4px',
                                 marginLeft: '10px'
                            }}
                        >
                            <img src={ChevronLeft} style={{ width: '20px', height: '20px' }}/>
                        </div>
                    )}
                    renderCenterRightControls={({ nextDisabled, nextSlide }) => (
                        <div 
                            onClick={nextSlide} 
                            disabled={nextDisabled}
                            style={{ 
                                ...buttonContainerStyle,
                                marginRight: '10px'
                            }}
                        >
                            <img src={ChevronRight} style={{ width: '20px', height: '20px' }}/>          
                        </div>
                    )}
                >
                    {homeAds.map((ha, i) => (
                        <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <a  href={ha.link || '/'} 
                                key={"homead__"+ i}
                                style={{ 
                                    display: 'flex',  
                                    justifyContent: 'center', 
                                    alignItems: 'center',
                                    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px', 
                                    borderRadius: '10px',
                                    overflow: 'hidden'
                                }}>
                                <img 
                                    style={{ minWidth: '100%', maxWidth: '100%' }} 
                                    src={`https://s3.eu-west-3.amazonaws.com/btpmat-ads/${ha.image}`}
                                />
                            </a>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    )
}

export default HomeOffers;