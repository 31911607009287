import React from "react";
import "./button-feedback.scss";

import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";

export default class ButtonFeedback extends React.Component {
    StyledButtonFeedback = withStyles((theme) => ({
        root: {
            width: this.props.width,
            height: this.props.height,
			marginTop: this.props.marginTop,
            fontSize: "16px",
            boxShadow: "none",
            color: "#ffffff",
        },
    }))(Button);

    render() {
        return (
            <this.StyledButtonFeedback
                className="button-feedback"
                variant="contained"
                disabled
            >
                {this.props.children}
            </this.StyledButtonFeedback>
        );
    }
}
