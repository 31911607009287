import React, { useState, useEffect } from "react";
import { Provider, useDispatch, useSelector } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";

import { store, persistor } from "./reducers/index";
import Routes, { get } from "./services/api";
import { initLocale } from "./locale";

import "./global.scss";
import { Route, Switch, BrowserRouter, withRouter } from "react-router-dom";
import Layout from "./layouts/layout";
import Error from "./components/error/error";

class ScrollToTop extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0);
		}
	}

	render() {
		return this.props.children;
	}
}

const RoutedScrollToTop = withRouter(ScrollToTop);

const Root = () => {
	const dispatch = useDispatch();

	const language = useSelector(state => state.locale.language);

	const [localeLoaded, setLocaleLoaded] = useState(false);
	const [dataLoaded, setDataLoaded] = useState(false);

	const fetchData = async () => {
		await get(Routes.infos.get.all(), data => {
			dispatch({ type: "SET_INFOS", value: data.infos });
		});

		await get(Routes.homeCategories.get.all(), data => {
            dispatch({ type: "SET_HOME_CATEGORIES", value: data.homeCategories });
            dispatch({ type: "SET_HOME_ADS", value: data.homeAds });
		});

		await get(Routes.adminSettings.get.all(), data => {
            dispatch({ type: "SET_ADMIN_SETTINGS", value: data.adminSettings });
		});
        
		await get(Routes.products.get.all(), data => {
			dispatch({ type: "SET_PRODUCTS", value: data.products });
		});

		await get(Routes.categories.get.all(), data => {
			dispatch({ type: "SET_CATEGORIES", value: data.categories.IVORIAN });
		});

		await get(Routes.subCategories.get.all(), data => {
			dispatch({ type: "SET_SUBCATEGORIES", value: data.subCategories });
		});

		await get(Routes.news.get.all(), data => {
			dispatch({
				type: "SET_NEWS_CATEGORIES",
				value: data.newsCategories
			});
			dispatch({
				type: "SET_NEWS",
				value: data.news
			});
		});
	}

	useEffect(() => {
		//TODO Add loader
		(async () => {
			setDataLoaded(false);
			fetchData();
			setDataLoaded(true);
		})();
	}, [language]);

	useEffect(() => {
		(async () => {
			setLocaleLoaded(false);
			await initLocale(language);
			setLocaleLoaded(true);
		})();
	}, [language]);

	return dataLoaded && localeLoaded ? (
		<React.Fragment>
			<BrowserRouter>
				<RoutedScrollToTop>
					<Switch>
						<Route path="/" render={props => <Layout {...props} />} />
					</Switch>
				</RoutedScrollToTop>
			</BrowserRouter>
			<Error />
		</React.Fragment>
	) : null;
};

export default function App() {
	return (
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<Root />
			</PersistGate>
		</Provider>
	);
}
