import React from 'react';

import Header1 from '../components/header/header1';
import Partners from '../components/partners/partners';
import Areas from '../components/areas/areas';
import LeftImageAction from '../components/left-image-action/left-image-action';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';
// import Spacer from '../components/spacer/spacer';
const env = {
	'title': 'partners',
}
function PartnersPage() {
	return (
		<>
			<Header1 />
			<Partners />
			<Areas />
			<LeftImageAction />
			<IconActions />
			<Footer />
		</>
	)
}

export default PartnersPage