import React from "react";
import { useDispatch, useSelector } from "react-redux";

import "./quotes-filter.scss";
import Button from "@material-ui/core/Button";
import { useLocale } from "../../locale";

export default function OrdersFilter() {
	const dispatch = useDispatch();
	const locales = useLocale();

	const filters = useSelector(state => state.user.filters.orders);

	const setOrdersSort = value => {
		dispatch({ type: "SET_ORDERS_SORT", value });
	}

	const resetFilters = () => {
		dispatch({ type: "RESET_ORDERS_FILTERS" });
	};

	return (
		<div id="quotes-filter">
			<div className="page-container">
				<div
					className={"row filter-content active"}
				>
					<div className="column" style={{ width: "350px" }}>
						<ul>
							<Button value="0" onClick={() => filters.sort === "DATE_DESC" ? resetFilters() : setOrdersSort("DATE_DESC")}>
								<li
									className={filters.sort === "DATE_DESC" ? "selected" : ""}
								>
									{locales.dashboard.orders.sort.date.desc}
								</li>
							</Button>
							<Button value="1" onClick={() => filters.sort === "DATE_ASC" ? resetFilters() : setOrdersSort("DATE_ASC")}>
								<li
									className={filters.sort === "DATE_ASC" ? "selected" : ""}
								>
									{locales.dashboard.orders.sort.date.asc}
								</li>
							</Button>
							<Button value="2" onClick={() => filters.sort === "STATUS" ? resetFilters() : setOrdersSort("STATUS")}>
								<li
									className={filters.sort === "STATUS" ? "selected" : ""}
								>
									{locales.dashboard.orders.sort.status}
								</li>
							</Button>
						</ul>
					</div>
				</div>
			</div>
		</div>
	);
}
