import React from "react";

import "./product-expertise.scss";
import IconActionSmall from "../iconActionSmall/iconActionSmall";
import Icon1 from "../../assets/image/landing-icons-bot-2-copy.svg";
import Icon2 from "../../assets/image/landing-icons-sav.svg";
import Icon3 from "../../assets/image/landing-icons-service-client-2-copy.svg";

import { useLocale } from "../../locale";

function ProductExpertise() {
    const locales = useLocale();

    const iconActions = [
        {
            icon: Icon1,
            title1: locales.home.expertise.info2,
        },
        {
            icon: Icon2,
            title1: locales.home.expertise.info4,
        },
        {
            icon: Icon3,
            title1: locales.home.expertise.info3,
        },
    ];

    return (
        <div id="product-expertise">
            {iconActions.map((item, key) => (
                <IconActionSmall item={item} key={key} />
            ))}
        </div>
    );
}

export default ProductExpertise;
