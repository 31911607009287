const initialState = {
	data: {},
	creditData: {},
	addresses: [],
	orders: [],
	quotes: [],
	filters: {
		orders: {
			sort: "DATE_DESC" // DATE_DESC, DATE_ASC, STATUS
		},
		quotes: {
			sort: "DATE_DESC" // DATE_DESC, DATE_ASC
		}
	}
};

export default function userReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_USER_DATA':
			return { ...state, data: action.value };
		case 'ADD_USER_DATA':
			return {
				...state,
				data: {
					...state.data,
					...action.value
				}
			};
		case 'SET_USER_CREDIT_DATA':
			return { ...state, creditData: action.value };
		case 'SET_USER_ADDRESSES':
			return { ...state, addresses: action.value };
		case 'SET_USER_ORDERS':
			return { ...state, orders: action.value };
		case 'SET_USER_QUOTES':
			return { ...state, quotes: action.value };
		case 'SET_ORDERS_SORT':
			return {
				...state,
				filters: {
					...state.filters,
					orders: {
						...state.filters.orders,
						sort: action.value
					}
				}
			};
		case 'SET_QUOTES_SORT':
			return {
				...state,
				filters: {
					...state.filters,
					quotes: {
						...state.filters.quotes,
						sort: action.value
					}
				}
			};
		case 'RESET_ORDERS_FILTERS':
			return {
				...state,
				filters: {
					...state.filters,
					orders: initialState.filters.orders
				}
			};
		case 'RESET_QUOTES_FILTERS':
			return {
				...state,
				filters: {
					...state.filters,
					quotes: initialState.filters.quotes
				}
			};
		case 'RESET_USER_STORE':
			return initialState;
		default:
			return state;
	}
};
