// key = subobject1.subobject2.[...].string_key without "locales."
export const getLocaleFromStringKey = (locales, key) => {
	const path = key.split(".");
	let value = locales;

	for (const pathPart of path) {
		if (pathPart && value[pathPart]) {
			value = value[pathPart];
		} else {
			value = "";
			break;
		}
	}

	return value;
};

// locale = {zero: "Aucun ...", one: "Un ...", other: "Plusieurs ..."}
export const pluralize = (locale, quantity) =>
	quantity > 1
		? locale.other.replace("%s", quantity)
		: quantity === 1
			? locale.one
			: locale.zero;
