import { createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import shopReducer from "./shop";
import onboardingReducer from "./onboarding";
import userReducer from "./user";
import orderReducer from "./order";
import newsReducer from "./news";
import appReducer from "./app";
import localeReducer from "./locale";
import adminSettingsReducer from "./adminSettings";

const persistConfig = {
	key: "root",
	whitelist: ["user", "shop"],
	storage
};

const store = createStore(
	persistCombineReducers(persistConfig, {
		shop: shopReducer,
		onboarding: onboardingReducer,
		user: userReducer,
		order: orderReducer,
		news: newsReducer,
		app: appReducer,
		locale: localeReducer,
        adminSettings: adminSettingsReducer
	}),
	(process.env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__) && window.__REDUX_DEVTOOLS_EXTENSION__()
);

const persistor = persistStore(store);

export { store, persistor };
