import React from 'react';

import './blog.scss';
import { formatParagraphs } from "../../utils/strings";
import { formatDatetime } from "../../utils/dates";
import config from "../../config";

export default function Blog(props) {
	const { news } = props;

	return (
		<div id="blog">
			<div className="page-container">
				<div className="content">
					<div className="featured-image-area">
						<img src={`${config.files.news}/${news.image}`} alt="" />
					</div>
					<div className="blog-content">
						{formatParagraphs(news.content)}
					</div>
					<div>
						<span style={{ fontSize: '10px', color: '#b0b4b9' }}>{formatDatetime(news.creationDate)}</span>
					</div>
				</div>
			</div>
		</div>
	)
}