import { createSelector } from "reselect";

import { ellipsize } from "../utils/strings";

let NewsSelectors = {};

const getNewsCategories = state => state.news.newsCategories;
const getNews = state => state.news.news;

const appendShort = n => ({
	...n,
	short: ellipsize(n.content, 110)
});

NewsSelectors.byId = (newsId) => state =>
	state.news.news.find(news => news.newsId === +newsId) || {};

NewsSelectors.bySlug = (newsSlug) => state =>
	state.news.news.find(news => news.newsId === +newsSlug.split("-")[0]) || {};

NewsSelectors.byCategory = (newsCategoryId, newsIdToExclude) => state =>
	state.news.news.filter(news =>
		news.newsCategoryId === +newsCategoryId && news.newsId !== +newsIdToExclude
	).map(appendShort);

NewsSelectors.categoryById = (newsCategoryId) => state => ({
	...(state.news.newsCategories.find(newsCategory => newsCategory.newsCategoryId === +newsCategoryId) || {}),
	news: state.news.news.filter(n =>
		n.newsCategoryId === +newsCategoryId
	).map(appendShort)
});

NewsSelectors.categoryBySlug = (newsCategorySlug) => state => ({
	...(state.news.newsCategories.find(newsCategory => newsCategory.newsCategoryId === +newsCategorySlug.split("-")[0]) || {}),
	news: state.news.news.filter(n =>
		n.newsCategoryId === +newsCategorySlug.split("-")[0]
	).map(appendShort)
});

NewsSelectors.categories = createSelector(
	getNewsCategories,
	getNews,
	(newsCategories, news) =>
		newsCategories.map(newsCategory => ({
			...newsCategory,
			news: news.filter(n =>
				n.newsCategoryId === newsCategory.newsCategoryId
			).map(appendShort)
		})).filter(newsCategory =>
			newsCategory.news.length >= 1
		)
);

export default NewsSelectors;
