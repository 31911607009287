import React from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ProductsSelectors from "../selectors/products";

import Header1 from "../components/header/header1";
import SimpleBreadcrumbs from "../components/breadcrumb/breadcrumb";
import SingleProductView from "../components/single-product-view/single-product-view";

import ProductsCarousel from "../components/products-carousel/products-carousel";
import ProductsTile from "../components/products-tile/products-tile";
import LeftImageAction from "../components/left-image-action/left-image-action";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";
import { useLocale } from "../locale";

export default function SingleProductPage() {
    const locales = useLocale();

    const { productId } = useParams();

    const product = useSelector(ProductsSelectors.bySlug(productId));

    const similarProducts = useSelector(
        ProductsSelectors.bySubCategoryIds(
            product.subCategories,
            product.productId
        )
    );

    const filters = useSelector((state) => state.shop.filters);
    const searchProducts = useSelector((state) =>
        ProductsSelectors.applyFilters(state)
    );

    return (
        <>
            <Header1 />
            {filters.search ? (
                <ProductsTile
                    products={searchProducts}
                    style={{ marginTop: 40 }}
                />
            ) : (
                <>
                    <SimpleBreadcrumbs
                        title={product.name}
                        style={{ background: "#ffffff" }}
                    />
                    <SingleProductView product={product} />

                    {similarProducts.length >= 1 ? (
                        <ProductsCarousel
                            category={{ name: locales.products.similar }}
                            products={similarProducts}
                        />
                    ) : null}
                    <LeftImageAction />
                    <IconActions />
                </>
            )}
            <Footer />
        </>
    );
}
