import { createSelector } from "reselect";

import { combineFilters } from "../utils/filters";

const getProducts = state => state.shop.products;
const getSubCategories = state => state.shop.subCategories;
const getFilters = state => state.shop.filters;
const getHomeCategories = state => state.shop.homeCategories;
const getHomeAds = state => state.shop.homeAds;

const filterBySearch = search => products => {
    return products.filter(product => {
        const regex = new RegExp(`.*${search}.*`, "gi");
        const hasMatchingSubCategory = product.subCategoriesNames.some(subCategory => regex.test(subCategory));
        return hasMatchingSubCategory || regex.test(product.name);
    });
};

const filterByPrice = ({ min, max }) => products => {
    return products.filter(product => {
        const priceToFilter = product.reducedPrice ? product.reducedPrice : product.originalPrice;
        return (!min || priceToFilter >= min) && (!max || priceToFilter <= max);
    });
};

const filterBySubCategoryIds = (subCategoryIds, productIdToExclude) => products =>
    subCategoryIds && subCategoryIds.length
        ? products.filter(
              product =>
                  subCategoryIds.some(subCategoryId => product.subCategories.includes(subCategoryId)) &&
                  (!productIdToExclude || product.productId !== productIdToExclude)
          )
        : products;

const filterByDiscountTypes = filteredDiscountTypes => products =>
    filteredDiscountTypes && filteredDiscountTypes.length
        ? products.filter(
              product =>
                  (filteredDiscountTypes.includes("DESTOCKING") && product.isDestocking) ||
                  (filteredDiscountTypes.includes("DISCOUNT") && Number.isFinite(product.reducedPrice))
          )
        : products;

const sortByPrice = sortMode => products => {
    const productsToSort = products.map(product => ({
        ...product,
        priceToSort: product.reducedPrice ? product.reducedPrice : product.originalPrice,
    }));
    if (sortMode === "ASC") {
        return productsToSort.sort((a, b) => a.priceToSort - b.priceToSort);
    } else if (sortMode === "DESC") {
        return productsToSort.sort((a, b) => b.priceToSort - a.priceToSort);
    }
    return products;
};

export default {
    byId: productId => state => state.shop.products.find(product => product.productId === +productId) || null,

    bySlug: productSlug => state =>
        state.shop.products.find(product => product.productId === +productSlug.split("-")[0]) || {},

    bySubCategory: subCategoryId => state =>
        state.shop.products.filter(product => product.subCategories.includes(subCategoryId)),

    bySubCategoryIds: (subCategoryIds, productIdToExclude) => state =>
        filterBySubCategoryIds(subCategoryIds, productIdToExclude)(state.shop.products),

    cart: () => state => state.shop.products.filter(product => product.quantityInCart && product.quantityInCart >= 1),

    byCategory: createSelector(getProducts, getSubCategories, getFilters, (products, subCategories, filters) =>
        products.filter(product =>
            product.subCategories.some(subCategoryId => {
                const subCategory = subCategories.find(sc => sc.subCategoryId === subCategoryId);
                return subCategory && subCategory.categoryId === +filters.category;
            })
        )
    ),

    applyFilters: createSelector(getProducts, getFilters, (products, filters) =>
        combineFilters(
            filterBySearch(filters.search),
            filterBySubCategoryIds(filters.subCategories),
            filterByDiscountTypes(filters.discountTypes),
            filterByPrice(filters.price),
            sortByPrice(filters.price.sort)
        )(products)
    ),

    novelty: createSelector(getProducts, products => 
        products.sort((a, b) => b.productId - a.productId).slice(0, 10)
    ),

    promotions: createSelector(getProducts, products =>
        products
            .filter(p => !!p.reducedPrice)
            .sort((a, b) => b.productId - a.productId)
            .slice(0, 10)
    ),
    topSales: createSelector(getProducts, products =>
        products.sort((a, b) => b.quantitySold - a.quantitySold).slice(0, 10)
    ),
    homeCategories: () => state => getHomeCategories(state),
    homeAds: () => state => getHomeAds(state)
};
