import React from 'react';
import './shipping.scss';
import CustomButton from '../../button/button';
import { useLocale } from "../../../locale";

export default function Shipping(props) {
	const locales = useLocale();

	return (
		<div id="shipping">
			<h3>{locales.delivery.type}</h3>
			<p>{locales.delivery.home.instruction1}</p>
			<CustomButton type="primary" width="100%" height="48px" className="custom-button" onClick={props.handles.nextAction} value="address">{locales.delivery.to_address.title}</CustomButton>
			<CustomButton type="primary" width="100%" height="48px" className="custom-button" onClick={props.handles.nextAction} value="pickup">{locales.delivery.pickup_shop.title}</CustomButton>
		</div>
	)
}