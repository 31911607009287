import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OrdersSelectors from "../../../selectors/orders";
import { formatPrice } from "../../../utils/numeric";
import config from "../../../config";
import Routes, { get, post } from "../../../services/api";
import { useLocale } from "../../../locale";

import "./order-review.scss";
import CustomButton from "../../button/button";

export default function OrderReview(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const order = useSelector(state => state.order);
	const orderData = useSelector(state => OrdersSelectors.orderData(state));
	const products = orderData.products;

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const productIds = products.map(product => product.productId);

		get(Routes.products.get.byIds(productIds), data => {
			dispatch({ type: "ASSIGN_PRODUCTS", value: data.products });
			setLoading(false);
		});
	}, []);

	const deliveryTypeLabel = {
		TO_ADDRESS: locales.delivery.to_address.title,
		PICKUP_SHOP: locales.delivery.pickup_shop.title,
		PICKUP_WORKSHOP: locales.delivery.pickup_workshop.title,
		CLICK_AND_COLLECT: locales.delivery.click_and_collect.title
	}[order.deliveryType] || "";

	const saveOrder = event => {
		event.persist();

		const orderItems = products.map(product => ({
			productId: product.productId,
			quantity: product.quantityInCart
		}));

		post(Routes.orders.post.order({
			deliveryType: order.deliveryType,
			deliveryFormattedAddress: order.deliveryAddress.address,
			deliveryAddressLat: order.deliveryAddress.position ? order.deliveryAddress.position.lat : null,
			deliveryAddressLng: order.deliveryAddress.position ? order.deliveryAddress.position.lng : null,
			phone: order.deliveryAddress.phone1,
			coupon: order.coupon ? order.coupon.couponName : null,
			quoteId: order.quoteId || null
		},
			orderItems
		),
			data => {
				dispatch({
					type: "SET_ORDER_ID",
					value: data.orderId
				});
				props.handles.nextAction(event);
			});
	};

	return (
		<div id="order-review">
			<div className="page-container">
				<h1 className="page-title">{locales.payment.order_summary.title}</h1>
				<p className="page-description">
					{locales.payment.order_summary.info1}
				</p>
				<div className="page-content">
					<div className="cart-container">
						{products.map((item, key) => (
							<div key={key} className="item">
								<div className="item-left">
									<img src={`${config.files.productImages}/${item.images[0]}`} alt="" />
									<div>
										<p className="product-title">{item.name}</p>
										<p className="product-detail">
											<img className="brand-image" src={`${config.files.brands}/${item.logo}`} alt="" />
											<span className="reference">{locales.products.reference}: {item.reference}</span>
										</p>
									</div>
								</div>
								<div className="item-right">
									<span className="quantity">{item.quantityInCart.toString()}x</span>&nbsp;
									<span className="price">
										{formatPrice(item.effectivePrice, true, null)}
										<small>XOF</small>
									</span>
								</div>
							</div>
						))}
					</div>
					<div className="price-container">
						<p className="price-title">{deliveryTypeLabel}</p>
						<div className="order-detail">
							<div className="section-content">
								<div className="address-content">
									{order.deliveryAddress.userAddressName ? (
										<h4 className="address-title">{order.deliveryAddress.userAddressName}</h4>
									) : null}
									<p className="address">
										{order.deliveryAddress.address}
									</p>
									{order.deliveryAddress.phone1 ? (
										<p className="phone-number">{locales.common.number1} : {order.deliveryAddress.phone1}</p>
									) : null}
									{order.deliveryAddress.phone2 ? (
										<p className="phone-number">{locales.common.number2} : {order.deliveryAddress.phone2}</p>
									) : null}
								</div>
							</div>
						</div>
						<div className="billing-detail">
							<div className="price-row">
								<span>{locales.orders.delivery_fees}</span>
								<p className="dark">{locales.common.free}</p>
							</div>
							{orderData.couponEffectiveDiscount ? (
								<div className="price-row border-row">
									<div>
										<span>{locales.orders.coupon}</span>
										<p>{order.coupon.couponName}</p>
									</div>
									<p className="dark">- {formatPrice(orderData.couponEffectiveDiscount, true)}</p>
								</div>
							) : null}
							<div className="price-row">
								<span>{locales.orders.total.to_pay}</span>
								<p>{formatPrice(orderData.totalTTC)}</p>
							</div>
						</div>
						<div className="check-action">
							<CustomButton
								type="primary"
								width="100%"
								height="48px"
								onClick={saveOrder}
								value="paymentChoice"
								loading={loading}
							>
								{locales.delivery.go_to_payment}
							</CustomButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
