const initialState = {
	adminSettings: {}
};

export default function adminSettingsReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_ADMIN_SETTINGS':
			return { ...state, adminSettings: action.value };
		default:
			return state;
	}
};
