import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link, NavLink } from "react-router-dom";
import Logo from "../../assets/logo";
import "./header-main.scss";

import BoutiqueMenu from "./boutique-menu";

import IconProfileActive from "../../assets/image/icons-profile-active.svg";
import ShoppingBagIcon from "../../assets/icons/shopping-bag";
import ProductsSelectors from "../../selectors/products";

import Search from "../forms/search";
import LangSelector from "./LangSelector";
import { useLocale } from "../../locale";

const MenuButton = ({ onClick }) => {
    return (
        <div 
            className="category-menu" 
            onClick={onClick}
        >
            <svg
                version="1.1"
                x="0px"
                y="0px"
                width="18.57px"
                height="15.72px"
                viewBox="0 0 122.879 103.609"
            >
                <g>
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        fill="white"
                        d="M10.368,0h102.144c5.703,0,10.367,4.665,10.367,10.367v0 c0,5.702-4.664,10.368-10.367,10.368H10.368C4.666,20.735,0,16.07,0,10.368v0C0,4.665,4.666,0,10.368,0L10.368,0z M10.368,82.875 h102.144c5.703,0,10.367,4.665,10.367,10.367l0,0c0,5.702-4.664,10.367-10.367,10.367H10.368C4.666,103.609,0,98.944,0,93.242l0,0 C0,87.54,4.666,82.875,10.368,82.875L10.368,82.875z M10.368,41.438h102.144c5.703,0,10.367,4.665,10.367,10.367l0,0 c0,5.702-4.664,10.368-10.367,10.368H10.368C4.666,62.173,0,57.507,0,51.805l0,0C0,46.103,4.666,41.438,10.368,41.438 L10.368,41.438z"
                    />
                </g>
            </svg>
            <span>
                {"Tous les produits"}
            </span>
        </div>
    )
}

const HeaderLogo = ({ onClick }) => (
    <Link to="/" onClick={onClick} style={{ padding: '0 20px' }}>
        <Logo />
    </Link>
)


const HeaderMain = (props) => {
	const dispatch = useDispatch();
    const locales = useLocale();
	const user = useSelector(state => state.user.data);
	const cart = useSelector(ProductsSelectors.cart());
	const location = useLocation();
	const history = useHistory();
	const categories = useSelector(state => state.shop.categories);
	const subCategories = useSelector(state => state.shop.subCategories);

    const [isStickyLeftMenuOpen, setIsStickyLeftMenuOpen] = useState(false)

	const resetProductsSearch = () => {
		dispatch({ type: "SET_PRODUCTS_SEARCH", value: "" });
	};

    const showSearchInput = !/^\/(espacepersonnel|devis|orders|mentions-legales)/.test(location.pathname);

	return (
		<div className="main-header" id="mainHeader">
			
				<div className="row" style={{ backgroundColor: '#343434' }}>
					<div className="column" style={{ flex: 3, padding: '10px 0' }}>
						<HeaderLogo onClick={resetProductsSearch}/>
                        {showSearchInput ? (
							<Search inputWidth={'100%'} containerStyle={{ width: '100%' }}/>
						) : null}
					</div>

					<div className="column" style={{ flex: 1, justifyContent: 'space-around' }}>
						<LangSelector/>
                        <div
                            style={{ height: '100%', display: 'flex', justifyContent: 'center', flex: 1 }}
                            className={
                                "espacePersonnel active-on-hover " + 
                                (/(\/espacepersonnel.*|\/orders|\/devis)/.test(location.pathname) 
                                ? ' active' 
                                : '')
                            }
                            aria-label="delete"
                            size="small"
                            onClick={user.token ? () => history.push("/espacepersonnel") : props.handles.openModal}
                        >
                            {!!user.profilePicture ? (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={user.profilePicture} style={{ width: 52, height: 52, borderRadius: '100%', margin: '0 20px' }} /> 
                                    {/* <span style={{ color: 'white' }}>
                                        {user.email}
                                    </span> */}
                                </div>
                            ) : (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                                    <img src={IconProfileActive} alt="dashboard" width="52"/>
                                    <span style={{ color: 'white', marginRight: '15px' }}>
                                        Connexion
                                    </span>
                                </div>
                            )}
                        </div>
                        <div
                            aria-label="cart"
                            size="small"
                            className="active-on-hover"
                            onClick={props.handles.miniCartHandleOpen}
                            style={{ height: '100%', display: 'flex',  justifyContent: 'center', alignItems: 'center',  marginRight: '20px', flex: 1 }}
                        >
                            {/* when the cart item is bigger than 1 you can add the active */}
                            <ShoppingBagIcon active={cart.length >= 1} />
                            <span style={{ color: 'white' }}>
                                {locales.cart.title}
                            </span>
                        </div>
					</div>
				</div>
                <div className="row" style={{ backgroundColor: '#414141', padding: '5px 0' }}>
                    <div className="main-nav">
                        <nav>
                            <li>
                                <MenuButton
                                    onClick={() => setIsStickyLeftMenuOpen(!isStickyLeftMenuOpen)}
                                />
                            </li>
                        </nav>
                    </div>
                </div>
                <BoutiqueMenu
                    categories={categories}
                    subCategories={subCategories}
                    stickLeft
                    stickLeftActive={isStickyLeftMenuOpen}
                />
		</div>
	);
}

export default HeaderMain;
