import React from 'react';
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import './blog-related.scss';
import BlogCart from '../blog-cart/blog-cart';
import NewsSelectors from "../../selectors/news";

export default function BlogRelated(props) {
	const { id: newsId } = useParams();

	const currentNews = useSelector(NewsSelectors.byId(newsId));
	const relatedNews = useSelector(NewsSelectors.byCategory(currentNews.newsCategoryId, newsId));

	return (
		<div className="blog-related">
			<div className="row">
				{
					relatedNews.map((item, key) => (
						<BlogCart key={key} item={item} />
					))
				}
			</div>
		</div>
	)
}