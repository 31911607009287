import React from "react";

import HomePage from "./pages/home-page";
import ContactPage from "./pages/contact-page";
import BoutiquePage from "./pages/boutique-page";
import ProductsPage from "./pages/products-page";
import PartnersPage from "./pages/partners-page";
import CheckoutPage from "./pages/checkout-page";
import EspacePersonnelPage from "./pages/espace-personnel-page";
import ActualitesPage from "./pages/actualites-page";
import SingleProductPage from "./pages/single-product-page";
import AccountPersonalInfoPage from "./pages/account-personal-info-page";
import AccountEditPasswordPage from "./pages/account-edit-password-page";
import AccountAddressesPage from "./pages/account-addresses-page";
import AccountAddresseEditPage from "./pages/account-address-edit-page";
import AccountAddresseNewPage from "./pages/account-address-new-page";
import AccountDevisPage from "./pages/account-devis-page";
import AccountOrdersPage from "./pages/account-orders-page";
import ComponentsPage from "./pages/components-page";
import CurrencyConvertPage from "./pages/currency-convert-page";
import SubCategoryPage from "./pages/sub-category-page";
import ArticleCategoryPage from "./pages/article-category-page";
import ArticleSubCategoryPage from "./pages/article-subcategory-page";
import FaqPage from "./pages/faq-page";
import InfoPage from "./pages/info-page";

const routes = [
	{ path: "/", name: "Home", component: HomePage },
	{ path: "/contact/:productId?", name: "Contact", component: ContactPage },
	{ path: "/boutique/:categoryId?", name: "Boutique", component: BoutiquePage },
	{ path: "/produits/:feature", name: "Products", component: ProductsPage },
	{ path: "/sub-category/:categoryId?/:subCategoryId?", name: "Sub Category", component: SubCategoryPage },
	{ path: "/partenaires", name: "Partenaires", component: PartnersPage },
	{ path: "/checkout", name: "Checkout", component: CheckoutPage },
	{ path: "/payment/order/end-payment", name: "Checkout", component: () => <CheckoutPage initialScreen="paymentSuccess" /> },
	{
		path: "/espacepersonnel",
		name: "Espace Personnel",
		component: EspacePersonnelPage,
		auth: true
	},
	{
		path: "/produit/:productId",
		name: "Produit",
		component: SingleProductPage
	},
	{
		path: "/espacepersonnel/informationspersonnelles",
		name: "Informations personnelles",
		component: AccountPersonalInfoPage,
		auth: true
	},
	{
		path: "/espacepersonnel/motdepasse",
		name: "Mon mot de passe",
		component: AccountEditPasswordPage,
		auth: true
	},
	{
		path: "/espacepersonnel/carnetdadresses",
		name: "Carnet d'adresses",
		component: AccountAddressesPage,
		auth: true
	},
	{
		path: "/espacepersonnel/carnetdadresses/:id",
		name: "Modification d’une adresse",
		component: AccountAddresseEditPage,
		auth: true
	},
	{
		path: "/espacepersonnel/carnetdadresses-new",
		name: "Création d’une adresse",
		component: AccountAddresseNewPage,
		auth: true
	},
	{ path: "/devis", name: "Devis", component: AccountDevisPage, auth: true },
	{ path: "/orders", name: "Commandes", component: AccountOrdersPage, auth: true },
	{ path: "/components", name: "Components", component: ComponentsPage },
	{ path: "/currency-convert", name: "CurencyConvert", component: CurrencyConvertPage },
	{ path: "/actualites", name: "Article Category", component: ArticleCategoryPage },
	{ path: "/sub-actualites/:newsCategoryId", name: "Article Sub Category", component: ArticleSubCategoryPage },
	{ path: "/actualite/:id", name: "Actualites", component: ActualitesPage },
	{ path: "/faq", name: "Faq", component: FaqPage },
	{ path: "/info/:infoSlug", name: "Info", component: InfoPage },
];

export default routes;
