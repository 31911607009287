import React from "react";
import { useDispatch } from "react-redux";
import "./order-item.scss";

import config from "../../../config";
import { formatPrice } from "../../../utils/numeric";
import CustomButton from "../../button/button";
import PlusIcon from "../../../assets/image/plus-line.svg";
import MinusIcon from "../../../assets/image/minus-line.svg";
import TrashIcon from "../../../assets/image/trash.png";
import { Button } from "@material-ui/core";

export default function OrderItem(props) {
	const dispatch = useDispatch();

	const product = props.product;

	const setQuantityInCart = quantity => {
		if (quantity >= 0) {
			dispatch({
				type: "SET_PRODUCT_QUANTITY_IN_CART",
				value: {
					productId: product.productId,
					quantity
				}
			});
		}
	};

	const handleQuantityTextInput = event => {
		const quantity = parseInt(event.target.value) || 1;
		setQuantityInCart(quantity);
	};

	const effectivePrice = product.reducedPrice
		? product.reducedPrice
		: product.originalPrice;

	return (
		<div className="order-item">
			<div className="item">
				<Button className="image-area">
					<div>
						<img
							src={`${config.files.productImages}/${product.images[0]}`}
							alt=""
						/>
					</div>
				</Button>

				<h3 className="product-title">{product.name}</h3>
				<p className="product-ref">
					<span>
						<img src={`${config.files.brands}/${product.logo}`} alt="" />
					</span>
					<span>REF : {product.reference}</span></p>
				<div className="button-area">
					<div className="add-cart">
						<CustomButton
							onClick={() => setQuantityInCart(product.quantityInCart - 1)}
							width="34px"
							height="34px"
							type="transparent"
						>
							<img src={MinusIcon} alt="" />
						</CustomButton>
						<input type="text" placeholder="" value={product.quantityInCart} onChange={handleQuantityTextInput} />
						<CustomButton
							onClick={() => setQuantityInCart(product.quantityInCart + 1)}
							width="34px"
							height="34px"
							type="transparent"
						>
							<img src={PlusIcon} alt="" />
						</CustomButton>
					</div>
				</div>
				<div className="price-area">
                    <div>
                        <span className="sale-price">
                            {formatPrice(effectivePrice, true)}{" "}
                        </span>
                        <br />
                        <span className="regular-price">
                            {product.reducedPrice ? formatPrice(product.originalPrice, true) : null}
                        </span>               
                    </div>
                    <div>
                        <img 
                            src={TrashIcon} 
                            alt="Delete article"
                            className="trash-icon"
                            onClick={() => setQuantityInCart(0)}
                        />
                    </div>
                </div>
			</div>
		</div>
	);
}
