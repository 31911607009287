import { createSelector } from "reselect";

import { combineFilters } from "../utils/filters";

const getSubCategories = state => state.shop.subCategories;
const getFilters = state => state.shop.filters;

const filterBySubCategoryIds = subCategoryIds => subCategories =>
	subCategoryIds && subCategoryIds.length
		? subCategories.filter(subCategory =>
			subCategoryIds.includes(subCategory.subCategoryId)
		)
		: subCategories;

const filterByCategory = categoryId => subCategories => subCategories.filter(
	subCategory => subCategory.categoryId === +categoryId
);

export default {
	byId: subCategoryId => state =>
		state.shop.subCategories.find(
			subCategory => subCategory.subCategoryId === +subCategoryId
		) || {},

	bySlug: (subCategorySlug = "") => state =>
		state.shop.subCategories.find(subCategory => subCategory.subCategoryId === +subCategorySlug.split("-")[0]) || {},

	byCategory: createSelector(
		getSubCategories,
		getFilters,
		(subCategories, filters) =>
			combineFilters(
				filterByCategory(filters.category)
			)(subCategories)
	),

	applyFilters: createSelector(
		getSubCategories,
		getFilters,
		(subCategories, filters) =>
			combineFilters(
				filterByCategory(filters.category),
				filterBySubCategoryIds(filters.subCategories)
			)(subCategories)
	)
};
