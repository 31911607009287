import React from "react";

import Header1 from "../components/header/header1";
import Account from "../components/account/account";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";

export default function EspacePersonnelPage() {
    return (
        <>
            <Header1 />
            <Account />
            <IconActions />
            <Footer />
        </>
    );
}
