export const encodePaymentParams = (paymentParams) => {
	let str = "";
	for (const key in paymentParams) {
		if (str != "") {
			str += "&";
		}
		str += key + "=" + encodeURIComponent(paymentParams[key]);
	}
	return str;
};
