import { useSelector } from "react-redux";

import { store } from "./reducers/index";
import config from "./config";

const loadLocale = async (language) => {
	const headers = {
		"Accept": "application/json",
		"Content-Type": "application/json",
		"Authorization": `token ${config.phrase.token}`
	};

	const res = await fetch(`https://api.phrase.com/v2/projects/${config.phrase.projectId}/locales/${config.phrase.localeIds[language]}/download?file_format=nested_json`, {
		method: "get",
		headers
	});
	try {
		const keys = await res.json();
		return keys;
	} catch (err) {
		console.error(err);
		return null;
	}
};

export const initLocale = async (language = "fr") => {
	let keys = await loadLocale(language);
	if (keys) {
		let action = {
			type: "SET_LOCALE_KEYS",
			value: keys
		};
		store.dispatch(action);
	}
}

export const useLocale = () => {
	const locales = useSelector((state) => state.locale.keys);
	return locales;
}

export const getUnhookedLocale = () => {
	const { locale: { keys } } = store.getState();
	return keys;
}
