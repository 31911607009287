export default {
	api: {
		base_url:
			process.env.NODE_ENV === "development"
				// ? "http://46.101.53.121:65533"
				? "http://192.168.1.97:65533"
				: "https://api.btpmat.ci"
	},
	s3: {
		accessKey: "AKIA2O6FQGYOL34COHMF",
		secretKey: "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"
	},
	googleApiKey: "AIzaSyCnm6eB2syYfK6TeQZvi73qB1F2mSYN7Jw",
	currency: {
		key: "77cb6320684d530db960ac23d706b532"
	},
	files: {
		productImages: "https://s3.eu-west-3.amazonaws.com/btpmat-products",
		productVideos: "https://s3.eu-west-3.amazonaws.com/btpmat-products",
		productFiles: "https://s3.eu-west-3.amazonaws.com/btpmat-products-files",
		categoryIcons: "https://s3.eu-west-3.amazonaws.com/btpmat-category-icons",
		brands: "https://s3.eu-west-3.amazonaws.com/btpmat-brands",
		reviews: "https://s3.eu-west-3.amazonaws.com/btpmat-reviews",
		quoteExport: "https://s3.eu-west-3.amazonaws.com/btpmat-quotes-exports",
		accountsBooks: "https://s3.eu-west-3.amazonaws.com/btpmat-accounts-books",
		news: "https://s3.eu-west-3.amazonaws.com/btpmat-news",
	},
	phrase: {
		projectId: "8eb220e2f5ee677b8aecf5492627473b",
		token: "92dc3c50299a4edd3c2ed60ff3d1eb4274049b2e6796e66c0c6b42aced1f9419",
		localeIds: {
			fr: "09fe3d22407cf9a47a5efd94e73abee0",
			en: "659a4c346cbe3eb66e46a27a917abb93",
			es: "71a3e6f4bb62a92875cbe5344aaa5093",
			zh: "1d9c7297f54549e3838b56f9557a0e67"
		}
	}
};
