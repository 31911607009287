import React, { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";

import ProductsSelectors from "../selectors/products";
import CategoriesSelectors from "../selectors/categories";
import SubCategoriesSelectors from "../selectors/subCategories";

import Header1 from "../components/header/header1";
import SimpleBreadcrumbs from "../components/breadcrumb/breadcrumb";
import Banner from "../components/banner/banner";
import ProductFilter from "../components/product-filter/product-filter";
import ProductsCarousel from "../components/products-carousel/products-carousel";
import ProductsTile from "../components/products-tile/products-tile";
import LeftImageAction from "../components/left-image-action/left-image-action";
import Spacer from "../components/spacer/spacer";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";

export default function BoutiquePage() {
	const { categoryId = "" } = useParams();

	const dispatch = useDispatch();
    const history = useHistory();
    const filters = useSelector(state => state.shop.filters);
	const category = useSelector(CategoriesSelectors.bySlug(categoryId));
	const subCategories = useSelector(state =>
		SubCategoriesSelectors.applyFilters(state)
	);
	const products = useSelector(state => ProductsSelectors.applyFilters(state));

    if (!categoryId && !filters.search) {       
        history.push("/");
    }

	const [displayedSubCategoriesCount, setDisplayedSubCategoriesCount] = useState(2);

	const subCategoriesFormatted = subCategories
		.map(subCategory => ({
			...subCategory,
			products: products.filter(product =>
				product.subCategories.includes(subCategory.subCategoryId)
			)
		}))
		.filter(subCategory => subCategory.products && subCategory.products.length);

	const displayedSubCategories = subCategoriesFormatted.slice(0, displayedSubCategoriesCount);

	const [hasMoreSubCategories, setHasMoreSubCategories] = useState(subCategoriesFormatted.length > 2);

	const setCurrentCategory = value => dispatch({ type: "SET_CATEGORY_FILTER", value });

	const fetchSubCategories = () => {
		if (displayedSubCategories.length >= subCategoriesFormatted.length) {
			setHasMoreSubCategories(false);
		} else {
			setDisplayedSubCategoriesCount(prev => prev + 1);
		};
	};

	useEffect(() => {
		if (categoryId) {
			setCurrentCategory(categoryId.split("-")[0]);
		}
	}, [categoryId]);

	return (
		<>
			<Header1 />
			{
				categoryId && <>
					<SimpleBreadcrumbs
						title={category.name}
						style={{ background: "#fafbfc" }}
					/>
					<Banner image={category.icon} title={category.name} />
				</>
			}
			{categoryId ? (
				<>
					<ProductFilter showSubCategoriesFilter />
					<InfiniteScroll
						dataLength={displayedSubCategories.length}
						next={fetchSubCategories}
						hasMore={hasMoreSubCategories}
						scrollThreshold={0.35}
						style={{ overflow: "hidden" }}
					>
						{displayedSubCategories.map(subCategory => (
							<ProductsCarousel
								key={subCategory.subCategoryId}
								id={subCategory.subCategoryId}
								category={subCategory}
								parentCategory={category}
								parent={subCategory.categoryId}
								products={subCategory.products}
								showSeeAll
							/>
						))}
					</InfiniteScroll>
				</>
			) : filters.search ? (
                <ProductsTile
                    products={products}
                    style={{ marginTop: 40 }}
                />
            ) : null}
			<Spacer height="30" />
			<LeftImageAction />
			<IconActions />
			<Footer />
		</>
	);
}
