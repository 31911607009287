import React from 'react';
import './pickup.scss';
import CustomButton from '../../button/button';
import { useLocale } from "../../../locale";

export default function Pickup(props) {
	const locales = useLocale();

	return (
		<div id="pickup">
			<div className="page-container">
				<h1>{locales.delivery.pickup_shop.title}</h1>
				<p className="page-discription">{locales.delivery.pickup_shop.instruction1}</p>
				<div className="action-discription">
					<h2>COCODY, RIVIERA PALMERAIE SAINT VIATEUR, Abidjan, Cote d'ivoire</h2>
					<p>07 86 46 03 20</p>
				</div>
				<div className="action-container">
					<CustomButton type="primary" width="362px" height="48px" onClick={props.handles.nextAction} value="orderReview">
						{locales.common.access_summary}
					</CustomButton>
				</div>
			</div>
		</div>
	)
}