import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";

import './payment-review.scss';
import CustomButton from '../../button/button';
import Routes, { post, patch } from "../../../services/api";
import OrdersSelectors from "../../../selectors/orders";
import { resetOrderData } from "../../../utils/orders";
import { formatPrice } from "../../../utils/numeric";
import { formatDate } from "../../../utils/dates";
import { useLocale } from "../../../locale";

export default function PaymentReview(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const user = useSelector(state => state.user);
	const order = useSelector(state => state.order);
	const orderData = useSelector(state => OrdersSelectors.orderData(state));

	const creditBalanceAfterPayment = Math.round(user.creditData.creditBalance - orderData.totalTTC);
	const isCreditBalanceSufficient = creditBalanceAfterPayment >= -user.creditData.overdraftLimit;

	useEffect(() => {
		if (!isCreditBalanceSufficient) {
			dispatch({ type: "SET_ERROR", value: locales.payment.credit_balance.over });
		}
	}, [isCreditBalanceSufficient]);

	const goToNext = event => {
		event.persist();

		post(Routes.users.post.creditOperation(order.orderId), data => {
			patch(Routes.orders.patch.paymentMode(order.orderId, "CREDIT_BALANCE"), data => {
				resetOrderData(dispatch);
				props.handles.nextAction(event);
			});
		})
	};

	return (
		<div id="payment-review">
			<div className="page-container">
				<h1 className="page-title">{locales.payment.credit_balance.title}</h1>
				<p className="page-description">{locales.payment.credit_balance.overdraft_limit_web} -{formatPrice(user.creditData.overdraftLimit)}</p>
				<div className="form-container">
					<div className="card">
						<div>
							<h5>{locales.payment.credit_balance.balance}</h5>
							<h3>{formatPrice(user.creditData.creditBalance)}</h3>
							{user.creditData.lastOperationDate ? (
								<p>{locales.payment.credit_balance.last_update} {formatDate(user.creditData.lastOperationDate)}</p>
							) : null}
						</div>
					</div>
					<div className="card">
						<div>
							<h5>{locales.payment.credit_balance.amount}</h5>
							<h3>{formatPrice(orderData.totalTTC)}</h3>
						</div>
					</div>
					<div className={`card ${isCreditBalanceSufficient ? "" : "error"}`}>
						<div>
							<h5>{locales.payment.credit_balance.next_balance}</h5>
							<h3>{formatPrice(creditBalanceAfterPayment)}</h3>
						</div>
					</div>
					{isCreditBalanceSufficient && (
						<CustomButton type="primary" width="100%" height="48px" value="paymentSuccess" onClick={goToNext}>{locales.payment.validate_order}</CustomButton>
					)}
				</div>
			</div>
		</div>
	)
}