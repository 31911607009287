import React from 'react';
import { useHistory } from "react-router-dom";

import './success.scss';
import CustomButton from "../../button/button";
import { useLocale } from "../../../locale";

export default function Success(props) {
	const history = useHistory();
	const locales = useLocale();

	return (
		<div id="payment-success">
			<div className="page-container">
				<h1 className="page-title">{locales.payment.result.delivery}</h1>
				<div className="form-container">
					<CustomButton
						onClick={() => history.push("/boutique")}
						type="primary"
						width="100%"
						height="48px"
						className="checkout-button"
					>
						{locales.shop.back_to_shop}
					</CustomButton>
				</div>
			</div>
		</div>
	)
}