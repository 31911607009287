import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import "./error.scss";

export default function Error() {
	const dispatch = useDispatch();
	
	const message = useSelector(state => state.app.error);
	
	const dismiss = () => {
		dispatch({ type: "SET_ERROR", value: null });
	};

	return message ? <div onClick={dismiss} className="error-bar">{message}</div> : <></>;
};
