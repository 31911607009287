import React from 'react'

export default function ArrowRightIcon() {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
			<defs>
				<linearGradient id="jiy93f748a" x1="-.001%" x2="99.999%" y1="50.001%" y2="50.001%">
					<stop offset="0%" stopColor="#E8832F" />
					<stop offset="100%" stopColor="#E8592B" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g fill="url(#jiy93f748a)" transform="translate(-458 -130)">
					<g>
						<g>
							<path d="M4.551 6.045L.134 1.3c-.179-.19-.179-.495 0-.685L.404.33c.18-.189.469-.189.648 0L4.875 4.45 8.698.334c.18-.19.468-.19.647 0l.27.285c.18.19.18.496 0 .685L5.2 6.05c-.18.185-.469.185-.648-.004z" transform="rotate(-90 300 -158) translate(1.125 2.813)" />
						</g>
					</g>
				</g>
			</g>
		</svg>
	)
}
