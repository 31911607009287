import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import Header1 from "../components/header/header1";
import Breadcrumb from "../components/breadcrumb/breadcrumb";
import IconActions from "../components/iconActions/iconActions";
import Footer from "../components/footer/footer";
import BlogCart from "../components/blog-cart/blog-cart";
import ProductsTile from "../components/products-tile/products-tile";
import NewsSelectors from "../selectors/news";
import { useProductsSearch } from "../utils/products";

export default function ArticleSubCategoryPage() {
    const { newsCategoryId } = useParams();

    const newsCategory = useSelector(
        NewsSelectors.categoryBySlug(newsCategoryId)
    );

    const { search, products } = useProductsSearch();

    return search ? (
        <>
            <Header1 />
            <ProductsTile products={products} style={{ marginTop: 40 }} />
            <Footer />
        </>
    ) : (
        <>
            <Header1 />
            <Breadcrumb title={newsCategory.newsCategoryName} />
            <div
                style={{
                    paddingLeft: "16px",
                    paddingRight: "16px",
                    marginBottom: "31px",
                    marginTop: "31px",
                }}
            >
                <div className="page-container">
                    <div
                        className="row"
                        style={{
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <h2 style={{ fontWeight: "bold", margin: "10px" }}>
                            {newsCategory.newsCategoryName}
                        </h2>
                    </div>
                    <div className="row" style={{ justifyContent: "center" }}>
                        {newsCategory.news.map((item, key) => (
                            <BlogCart key={key} item={item} />
                        ))}
                    </div>
                </div>
            </div>
            <IconActions />
            <Footer />
        </>
    );
}
