import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./address.scss";
import CustomButton from "../../button/button";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PlaceInput from "../../forms/place-input";
import PhoneInput from "../../forms/phone-input";

import Routes, { get, post } from "../../../services/api";
import { geolocateUser } from "../../../utils/geo";
import { useLocale } from "../../../locale";

export default function ShippingAddress(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const user = useSelector(state => state.user.data);

	const [anchor, setAnchor] = useState(null);

	const [state, setState] = useState({
		userAddressName: "",
		address: "",
		position: {},
		phone1: user.phone || "",
		phone2: "",
		isDefaultAddress: false
	});

	// pour le dropdown "Sélectionner une adresse" si utilisateur connecté
	const [userAddresses, setUserAddresses] = useState([]);

	// si adresse sélectionnée dans "Sélectionner une adresse"
	const [selectedUserAddressId, setSelectedUserAddressId] = useState(null);

	const selectedUserAddress = userAddresses.find(userAddress =>
		userAddress.userAddressId === selectedUserAddressId
	);

	useEffect(() => {
		if (user.token) {
			get(Routes.users.get.addresses(user.userId), data => {
				setUserAddresses(data.userAddresses);
			});
		}
	}, []);

	const handles = {
		inputChange: event => {
			event.persist();
			setState(prev => ({ ...prev, [event.target.name]: event.target.value }));
		}
	};

	const handleClick = event => {
		setAnchor(event.currentTarget);
	};

	const handleClose = () => {
		setAnchor(null);
	};

	// set local autocompleted address
	const setAddress = (address, latLng) => {
		setState(prev => ({
			...prev,
			address,
			position: { ...latLng }
		}));
	};

	const geolocate = async () => {
		const { address, lat, lng } = await geolocateUser();
		setState(prev => ({
			...prev,
			address,
			position: {
				lat,
				lng
			}
		}));
	};

	const setDeliveryAddress = () => {
		dispatch({
			type: "SET_DELIVERY_ADDRESS",
			value: selectedUserAddress ? {
				userAddressName: selectedUserAddress.userAddressName,
				address: selectedUserAddress.address,
				position: {
					lat: selectedUserAddress.lat,
					lng: selectedUserAddress.lng
				},
				phone1: selectedUserAddress.phone1,
				phone2: selectedUserAddress.phone2
			} : state
		});
	};

	const goToNext = event => {
		event.persist();

		if (selectedUserAddressId || (state.userAddressName && state.address && state.phone1)) {
			setDeliveryAddress();
			if (user.token && !selectedUserAddressId) {
				post(
					Routes.users.post.address({
						...state,
						addressName: state.userAddressName,
						mainPhone: state.phone1,
						secondaryPhone: state.phone2
					}), data => {
						props.handles.nextAction(event);
					}, error => {
						dispatch({ type: "SET_ERROR", value: error });
					}
				);
			} else {
				props.handles.nextAction(event);
			}
		} else {
			dispatch({ type: "SET_ERROR", value: locales.errors.required.generic });
		}
	};

	return (
		<div id="shipping-address">
			<h1>{locales.delivery.to_address.title}</h1>
			<p>{locales.delivery.to_address.instruction1}</p>
			{user.token ? (
				<>
					<CustomButton
						type="transparent"
						width="100%"
						height="48px"
						aria-haspopup="true"
						onClick={handleClick}
					>
						{locales.delivery.to_address.select_address}
					</CustomButton>
					<Menu
						id="simple-menu"
						anchorEl={anchor}
						keepMounted
						open={Boolean(anchor)}
						onClose={handleClose}
					>
						{userAddresses.map(userAddress => (
							<MenuItem
								key={userAddress.userAddressId}
								onClick={() => {
									setSelectedUserAddressId(userAddress.userAddressId);
									handleClose();
								}}
							>
								{userAddress.address}
							</MenuItem>
						))}
					</Menu>
					{selectedUserAddress ? (
						<div className="address-content">
							{selectedUserAddress.userAddressName ? (
								<h4 className="address-title">{selectedUserAddress.userAddressName}</h4>
							) : null}
							<p className="address">
								{selectedUserAddress.address}
							</p>
							{selectedUserAddress.phone1 ? (
								<p className="phone-number">{locales.common.number1} : {selectedUserAddress.phone1}</p>
							) : null}
							{selectedUserAddress.phone2 ? (
								<p className="phone-number">{locales.common.number2} : {selectedUserAddress.phone2}</p>
							) : null}
						</div>
					) : null}
					<div className="between-text">{locales.common.or}</div>
				</>
			) : null}
			{selectedUserAddressId ? (
				<CustomButton
					type="transparent"
					width="100%"
					height="48px"
					onClick={() => setSelectedUserAddressId(null)}
				>
					{locales.delivery.to_address.create_new_address}
				</CustomButton>
			) : (
					<>
						<h4>{locales.delivery.to_address.create_address}</h4>
						<div className="form-group">
							<label>{locales.inputs.address_name} *</label>
							<input
								type="text"
								name="userAddressName"
								className="form-control"
								value={state.userAddressName}
								onChange={handles.inputChange}
							/>
						</div>
						<div className="form-group">
							<CustomButton onClick={geolocate} type="transparent" width="100%" height="48px">
								{locales.inputs.geolocation}
							</CustomButton>
						</div>
						<div className="form-group">
							<label>{locales.common.address} *</label>
							<PlaceInput
								placeholder=""
								address={state.address || ""}
								selectPlaceCallback={setAddress}
								onChangeCallback={newAddress => {
									setState(prev => ({ ...prev, address: newAddress }));
								}}
							/>
						</div>
						<div className="form-group">
							<label>{locales.common.main_phone_number} *</label>
							<PhoneInput
								value={state.phone1}
								onChange={phone => setState(prev => ({
									...prev,
									phone1: phone
								}))}
							/>
						</div>
						<div className="form-group">
							<label>{locales.common.secondary_phone_number}</label>
							<PhoneInput
								value={state.phone2}
								onChange={phone => setState(prev => ({
									...prev,
									phone2: phone
								}))}
							/>
						</div>
						<div className="form-group">
							<div className="check-control">
								<FormControlLabel
									name="isDefaultAddress"
									value={state.isDefaultAddress}
									control={<Checkbox color="default" />}
									label={locales.delivery.default_address}
									labelPlacement="end"
									checked={state.isDefaultAddress}
									onChange={() =>
										setState(prev => ({
											...prev,
											isDefaultAddress: !prev.isDefaultAddress
										}))
									}
								/>
							</div>
						</div>
					</>
				)}
			<div className="form-group">
				<CustomButton
					type="primary"
					width="100%"
					height="48px"
					onClick={e => {
						goToNext(e);
					}}
					value="orderReview"
				>
					{locales.common.access_summary}
				</CustomButton>
			</div>
		</div>
	);
}
