import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import './address-edit.scss';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomButton from '../../button/button';
import PlaceInput from "../../forms/place-input";
import PhoneInput from "../../forms/phone-input";
import Routes, { patch } from "../../../services/api";
import UserAddressesSelectors from "../../../selectors/userAddresses";
import { geolocateUser } from "../../../utils/geo";
import { useLocale } from "../../../locale";

export default function AddressEdit(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const locales = useLocale();

	const { id: userAddressId } = useParams();

	const userAddress = useSelector(UserAddressesSelectors.byId(userAddressId));

	const [address, setAddress] = useState(userAddress);

	const handles = {
		inputChange: (event) => {
			event.persist();
			setAddress(prev => ({ ...prev, [event.target.name]: event.target.value }));
		}
	};

	const geolocate = async () => {
		const { address, lat, lng } = await geolocateUser();
		setAddress(prev => ({
			...prev,
			address,
			lat,
			lng
		}));
	};

	const editAddress = () => {
		if (address.userAddressName && address.address && address.phone1) {
			patch(Routes.users.patch.address(userAddressId, {
				addressName: address.userAddressName,
				address: address.address,
				position: {
					lat: address.lat,
					lng: address.lng
				},
				mainPhone: address.phone1,
				secondaryPhone: address.phone2,
				isDefaultAddress: address.isDefaultAddress
			}), data => {
				history.goBack();
			}, error => {
				dispatch({ type: "SET_ERROR", value: error });
			});
		} else {
			dispatch({ type: "SET_ERROR", value: locales.errors.required.generic });
		}
	};

	return (
		<div id="address-edit">
			<div className="form-area">
				<h1>{locales.dashboard.addresses.edit.title}</h1>
				<div className="form-group">
					<label>{locales.inputs.address_name} *</label>
					<input type="text" name="userAddressName" className="form-control" value={address.userAddressName || ""} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<CustomButton onClick={geolocate} type="transparent" width="100%" height="48px">
						{locales.inputs.geolocation}
					</CustomButton>
				</div>
				<div className="form-group">
					<label>{locales.common.address} *</label>
					<PlaceInput
						placeholder=""
						address={address.address || ""}
						selectPlaceCallback={(address, latLng) => setAddress(prev => ({
							...prev,
							address,
							...latLng
						}))}
						onChangeCallback={newAddress => {
							setAddress(prev => ({ ...prev, address: newAddress }));
						}}
					/>
				</div>
				<div className="form-group">
					<label>{locales.common.main_phone_number} *</label>
					<PhoneInput
						value={address.phone1}
						onChange={phone => setAddress(prev => ({
							...prev,
							phone1: phone
						}))}
					/>
				</div>
				<div className="form-group">
					<label>{locales.common.secondary_phone_number}</label>
					<PhoneInput
						value={address.phone2}
						onChange={phone => setAddress(prev => ({
							...prev,
							phone2: phone
						}))}
					/>
				</div>
				<div className="form-group">
					<div className="check-control">
						<FormControlLabel
							checked={!!address.isDefaultAddress}
							onChange={event => setAddress(prev => ({
								...prev,
								isDefaultAddress: event.target.checked
							}))}
							control={<Checkbox color="default" />}
							label={locales.delivery.default_address}
							labelPlacement="end"
						/>
					</div>
				</div>
				<div className="form-group">
					<CustomButton onClick={editAddress} type="primary" width="100%" height="48px">
						{locales.dashboard.addresses.edit.validate}
					</CustomButton>
				</div>
			</div>
		</div>
	)
}