import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./forget.scss";
import BackIcon from "../../../assets/image/icons-back-black.svg";
import CustomButton from "../../button/button";

import Routes, { post } from "../../../services/api";

import Patterns from "../../../constants/patterns";
import { useLocale } from "../../../locale";

export default function Forget(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const [username, setUsername] = useState("");

	const resetPassword = async () => {
		const isUsernamePhone = Patterns.phone.test(username);
		post(
			Routes.users.post.askResetPassword({
				[isUsernamePhone ? "phone" : "email"]: username
			}),
			data => {
				props.setCurrentScreen(`forget-result-${isUsernamePhone ? "phone" : "email"}`);
			},
			error => {
				dispatch({ type: "SET_ERROR", value: error });
			}
		);
	};

	return (
		<div id="forget">
			<button className="back-button" onClick={props.stateSwitch} value="login">
				<img src={BackIcon} alt="" />
			</button>
			<h1>{locales.onboarding.forgotten_password.title}</h1>
			<div className="form">
				<div className="row">
					<div className="form-group">
						<label htmlFor="address">
							{locales.inputs.email_or_phone}
						</label>
						<input
							className="form-control"
							placeholder={locales.common.email_or_phone}
							name="user"
							id="user"
							onChange={e => setUsername(e.target.value)}
							value={username}
						/>
					</div>
				</div>
				<div className="row">
					<CustomButton onClick={resetPassword} type="glassDark" width="100%" height="48px">
						{locales.common.reset}
					</CustomButton>
				</div>
			</div>
		</div>
	);
}
