import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";

import './header-top.scss';
import FrIcon from '../../assets/icons/locales/fr.svg';
import EnIcon from '../../assets/icons/locales/en.svg';
import EsIcon from '../../assets/icons/locales/es.svg';
import ZhIcon from '../../assets/icons/locales/zh.svg';
import { useLocale } from "../../locale";

const Languages = {
	"fr": FrIcon,
	"en": EnIcon,
	"es": EsIcon,
	"zh": ZhIcon
};

const LangSelector = () => {
    const dispatch = useDispatch();
	const locales = useLocale();

	const language = useSelector(state => state.locale.language);

	const [showLanguageOptions, setShowLanguageOptions] = useState(false);

	const setLanguage = value => {
		setShowLanguageOptions(false);
		dispatch({ type: "SET_LANGUAGE", value });
	};

	return (
		<div 
            style={{ margin: '0 15px', flex: 1, height: '100%' }} 
            className="locale-select" 
            onClick={() => setShowLanguageOptions(!showLanguageOptions)}
        >
            <div
                className="locale-current active-on-hover"
                style={{ display: 'flex', alignItems: 'center', padding: '3px', height: '100%' }}
                onClick={() => setShowLanguageOptions(prev => !prev)}
            >
                <img src={Languages[language]} alt={locales.locales[language]} width="50"/>
                <span style={{ color: 'white', margin: '0 20px' }}>
                    {locales.locales[language].slice(0,2).toUpperCase()}
                </span>
            </div>
            <div className={"locale-options" + (showLanguageOptions ? " active" : "")}>
                {Object.keys(Languages).map(value => (
                    <div key={value}
                        style={{ 
                            display: 'flex', 
                            alignItems: 'center', 
                            marginBottom: '10px' 
                        }}
                        className="locale-option" 
                        onClick={() => setLanguage(value)}
                    >
                        <img src={Languages[value]} alt={locales.locales[value]} width="50"/>
                        <span style={{ color: 'white', margin: '0 20px' }}>
                            {locales.locales[value]}
                        </span>
                    </div>
                ))}
            </div>
        </div>
	);
}

export default LangSelector;