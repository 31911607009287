import React from 'react';

import Header1 from '../components/header/header1';
import PersonalInfo from '../components/account/personal-info/personal-info';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';

export default function AccountPersonnelInfoPage() {
	return (
		<>
			<Header1 />
			<PersonalInfo />
			<IconActions />
			<Footer />
		</>
	)
}