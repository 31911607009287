import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

import "./devis.scss";
import QuotesFilter from "../../quotes-filter/quotes-filter";
import HourglassHalfIcon from "../../../assets/image/hourglass-half.svg";
import CustomButton from "../../button/button";
import Button from "@material-ui/core/Button";
import Routes, { get } from "../../../services/api";
import QuotesSelectors from "../../../selectors/quotes";
import { useLocale } from "../../../locale";
import { getOrderData } from "../../../utils/orders";
import { formatPrice } from "../../../utils/numeric";
import { formatDate } from "../../../utils/dates";
import config from "../../../config";

const StatusBadge = ({ status }) => {
    const locales = useLocale();

    return (
        <div>
            {
                {
                    AWAITING: (
                        <span className="badge badge-primary">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;{locales.quotes.badges.awaiting}
                        </span>
                    ),
                    ACCEPTED: (
                        <span className="badge badge-success">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;{locales.quotes.badges.accepted}
                        </span>
                    ),
                    PROCESSING: (
                        <span className="badge badge-warning">
                            <img src={HourglassHalfIcon} alt="" />
                            &nbsp;&nbsp;{locales.quotes.badges.processing}
                        </span>
                    ),
                }[status]
            }
        </div>
    );
};

export default function Devis(props) {
    const dispatch = useDispatch();
    const history = useHistory();
    const locales = useLocale();

    const user = useSelector((state) => state.user.data);
    const quotes = useSelector((state) => QuotesSelectors.applyFilters(state));

    const [currentQuoteId, setCurrentQuoteId] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    const [displayedQuotesCount, setDisplayedQuotesCount] = useState(20);
	const [hasMoreQuotes, setHasMoreQuotes] = useState(quotes.length > 20);

    useEffect(() => {
        get(Routes.quotes.get.byUser(user.userId), (data) => {
            dispatch({ type: "SET_USER_QUOTES", value: data.quotes });
        });
    }, []);

    useEffect(() => {
        setCurrentQuoteId(quotes[0] ? quotes[0].quoteId : null);
    }, [quotes]);

    const currentQuote = quotes.find(
        (quote) => quote.quoteId === currentQuoteId
    );

    const displayedQuotes = quotes.slice(0, displayedQuotesCount);

    const showMoreQuotes = () => {
		if (displayedQuotes.length >= quotes.length) {
			setHasMoreQuotes(false);
		} else {
			setDisplayedQuotesCount(prev => prev + 10);
		};
	};

    const showFile = async () => {
        await window.open(
            `${config.files.quoteExport}/devis_${currentQuote.quoteId}.pdf`
        );
    };

    const goToOrder = () => {
        if (currentQuote) {
            const cartProducts = currentQuote.products.map((product) => ({
                productId: product.productId,
                quantityInCart: product.quantity,
            }));
            dispatch({ type: "ASSIGN_PRODUCTS", value: cartProducts });
            if (currentQuote.coupon) {
                dispatch({ type: "SET_COUPON", value: currentQuote.coupon });
            }
            dispatch({
                type: "SET_ORDER_QUOTE_ID",
                value: currentQuote.quoteId,
            });
            history.push("/checkout");
        }
    };

    const quoteData = currentQuote
        ? getOrderData(
              currentQuote.products || [],
              currentQuote.coupon,
              currentQuote
          )
        : null;

    return (
        <div id="devis">
            <div className="page-container">
                <div className="row">
                    {quotes.length >= 1 && (
                        <div className="left">
                            <div className="right-nav-area">
                                <CustomButton
                                    onClick={() =>
                                        setShowFilters((prev) => !prev)
                                    }
                                    width="100%"
                                    height="48px"
                                    type="glass"
                                >
                                    {locales.common.sort}
                                </CustomButton>
                                {showFilters && <QuotesFilter />}
                                <div className="nav-actions">
                                    <InfiniteScroll
                                        dataLength={displayedQuotes.length}
                                        next={showMoreQuotes}
                                        hasMore={hasMoreQuotes}
                                    >
                                        {displayedQuotes.map((quote, key) => (
                                            <Button
                                                key={key}
                                                onClick={() =>
                                                    setCurrentQuoteId(
                                                        quote.quoteId
                                                    )
                                                }
                                                style={
                                                    quote.quoteId ===
                                                    currentQuoteId
                                                        ? {
                                                              background:
                                                                  "rgba(0,0,0,0.04)",
                                                          }
                                                        : {}
                                                }
                                            >
                                                <div className="action-button">
                                                    <div>
                                                        <h4>
                                                            {
                                                                locales.common
                                                                    .quote
                                                            }{" "}
                                                            #{quote.quoteId}
                                                        </h4>
                                                        {Number.isFinite(
                                                            quote.totalTTC
                                                        ) ||
                                                        Number.isFinite(
                                                            quote.totalEstimate
                                                        ) ? (
                                                            <p>
                                                                {formatDate(
                                                                    quote.creationDate
                                                                )}{" "}
                                                                –{" "}
                                                                {formatPrice(
                                                                    quote.type ===
                                                                        "FROM_CART"
                                                                        ? quote.totalTTC
                                                                        : quote.totalEstimate
                                                                )}
                                                            </p>
                                                        ) : (
                                                            <p>
                                                                {formatDate(
                                                                    quote.creationDate
                                                                )}
                                                            </p>
                                                        )}
                                                    </div>
                                                    <StatusBadge
                                                        status={quote.status}
                                                    />
                                                </div>
                                            </Button>
                                        ))}
                                    </InfiniteScroll>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="right">
                        {currentQuote ? (
                            <div className="billing-cart">
                                <StatusBadge status={currentQuote.status} />
                                <h3 className="cart-title">
                                    {locales.common.quote} #
                                    {currentQuote.quoteId}
                                </h3>
                                {currentQuote.type === "FROM_CART" && (
                                    <>
                                        <div className="product-detail">
                                            <h6 className="section-title">
                                                {locales.orders.your_order}
                                            </h6>
                                            <div className="section-content">
                                                {quoteData
                                                    ? quoteData.products.map(
                                                          (item, key) => (
                                                              <div
                                                                  key={key}
                                                                  className="item"
                                                              >
                                                                  <div className="item-left">
                                                                      <Button
                                                                          style={{
                                                                              padding:
                                                                                  "0px",
                                                                          }}
                                                                          disabled
                                                                      >
                                                                          <img
                                                                              src={`${config.files.productImages}/${item.image1}`}
                                                                              alt=""
                                                                          />
                                                                      </Button>
                                                                      <div>
                                                                          <p className="product-title">
                                                                              {
                                                                                  item.name
                                                                              }
                                                                          </p>
                                                                          <p className="product-detail">
                                                                              <img
                                                                                  className="brand-image"
                                                                                  src={`${config.files.brands}/${item.logo}`}
                                                                                  alt=""
                                                                              />
                                                                              <span className="reference">
                                                                                  REF:{" "}
                                                                                  {
                                                                                      item.reference
                                                                                  }
                                                                              </span>
                                                                          </p>
                                                                      </div>
                                                                  </div>
                                                                  <div className="item-right">
                                                                      <span className="quantity">
                                                                          {item.quantity.toString()}
                                                                          x
                                                                      </span>
                                                                      &nbsp;
                                                                      <span className="price">
                                                                          {formatPrice(
                                                                              item.effectivePrice,
                                                                              true,
                                                                              null
                                                                          )}
                                                                          <small>
                                                                              {" "}
                                                                              XOF
                                                                          </small>
                                                                      </span>
                                                                  </div>
                                                              </div>
                                                          )
                                                      )
                                                    : null}
                                            </div>
                                        </div>
                                        <div className="billing-detail">
                                            <div className="price-row">
                                                <span>
                                                    {
                                                        locales.orders
                                                            .delivery_fees
                                                    }
                                                </span>
                                                <p className="dark">
                                                    {locales.common.free}
                                                </p>
                                            </div>
                                            {currentQuote.coupon ? (
                                                <div className="price-row border-row">
                                                    <div>
                                                        <span>
                                                            {
                                                                locales.orders
                                                                    .coupon
                                                            }
                                                        </span>
                                                        <p>
                                                            {
                                                                currentQuote
                                                                    .coupon
                                                                    .couponName
                                                            }
                                                        </p>
                                                    </div>
                                                    <p className="dark">
                                                        -{" "}
                                                        {formatPrice(
                                                            quoteData.couponEffectiveDiscount,
                                                            true
                                                        )}
                                                    </p>
                                                </div>
                                            ) : null}
                                            <div className="price-row">
                                                <span>
                                                    {
                                                        locales.orders.total
                                                            .to_pay
                                                    }
                                                </span>
                                                <p>
                                                    {formatPrice(
                                                        quoteData.totalTTC
                                                    )}
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="check-action">
                                    <CustomButton
                                        onClick={goToOrder}
                                        type="primary"
                                        width="100%"
                                        height="48px"
                                    >
                                        {locales.dashboard.quotes.go_to_order}
                                    </CustomButton>
                                    {currentQuote.type === "FROM_CART" && (
                                        <>
                                            <div style={{ height: "8px" }} />
                                            <CustomButton
                                                onClick={showFile}
                                                type="transparent"
                                                width="100%"
                                                height="48px"
                                            >
                                                {
                                                    locales.dashboard.quotes
                                                        .show_file
                                                }
                                            </CustomButton>
                                        </>
                                    )}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}
