import React, { useState, useEffect } from "react";
import Slider from "react-slick";

import "./reviews.scss";
import Review from "../review/review";
import CustomButton from '../button/button';
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Routes, { get } from "../../services/api";
import { useLocale } from "../../locale";

function Reviews() {
	const locales = useLocale();

	const [reviews, setReviews] = useState([]);

	useEffect(() => {
		get(Routes.reviews.get.all(), data => {
			setReviews([...data.reviews]);
		});
	}, []);

	var settings = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 3,
		initialSlide: 0,
		// autoplay: true,
		// autoplaySpeed: 4000,
		responsive: [
			{
				breakpoint: 1030,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					infinite: true,
					dots: true
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	};

	return (
		<div id="reviews">
			<div className="page-container">
				<div className="row">
					<div className="back-bar" />
					<div className="center-block">
						<h3>{locales.shop.reviews_web.suptitle}</h3>
						<h1>{locales.shop.reviews_web.title}</h1>
					</div>
					<div className="back-bar" />
				</div>
			</div>
			<div className="page-container">
				<Slider {...settings}>
					{reviews.map((review, index) => (
						<Review data={review} key={index} />
					))}
				</Slider>
				<div className="text-center">
					<div className="row">
						<div className="row">
							<CustomButton width="276px" height="48px" type="primary" href="#contact">
								{locales.shop.ask_quote}
							</CustomButton>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Reviews;
