import React from 'react'

export default function ShoppingBagIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="52" height="52" viewBox="0 0 32 32">
			<defs>
				<linearGradient id="wesssfwa8a" x1="89.506%" x2="10.494%" y1="-7.957%" y2="100%">
					<stop offset="0%" stopColor="#A1ADBE" />
					<stop offset="100%" stopColor="#68758A" />
				</linearGradient>
				<linearGradient id="skkxu1i58b" x1="50%" x2="50%" y1=".137%" y2="99.879%">
					<stop offset=".069%" stopColor="#FF5A5B" />
					<stop offset="99.939%" stopColor="#FF4F50" />
				</linearGradient>
			</defs>
			<g fill="none" fillRule="evenodd">
				<g>
					<g fill="url(#wesssfwa8a)" transform="translate(8 7)">
						<path d="M15.997 17.408L14.592 4.38c-.03-.272-.266-.48-.547-.48h-2.641v-.586C11.404 1.487 9.877 0 8 0S4.596 1.487 4.596 3.314v.587h-2.64c-.282 0-.519.207-.548.48L.003 17.407c-.016.151.034.302.138.415.105.113.253.177.41.177H15.45c.156 0 .304-.064.409-.177.104-.113.154-.264.138-.415zM5.697 3.314C5.696 2.078 6.73 1.071 8 1.071s2.304 1.007 2.304 2.243v.587H5.696v-.587z" />
					</g>
					{
						props.active && <circle cx="23" cy="12" r="3" fill="url(#skkxu1i58b)" stroke="#FFF" />
					}
				</g>
			</g>
		</svg>
	)
}
