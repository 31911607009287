import React, { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import "./products-tile.scss";
import ProductCard from "../product-card/product-card";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";

export default function ProductsTile({ products = [], style }) {
	const [displayedProductsCount, setDisplayedProductsCount] = useState(10);
	const [hasMore, setHasMore] = useState(products.length > 10);

	const displayedProducts = products.slice(0, displayedProductsCount);

	const fetchData = () => {
		if (displayedProducts.length >= products.length) {
			setHasMore(false);
		} else {
			setDisplayedProductsCount(prev => prev + 10);
		};
	};

	return (
		<div className="products-tile" style={style}>
			<div className="page-container">
				<div className="row">
					<InfiniteScroll
						dataLength={displayedProducts.length}
						next={fetchData}
						hasMore={hasMore}
                        scrollThreshold={0.35}
					>
						{displayedProducts.map((product, key) => (
							<ProductCard state={product} key={key} inGrid />
						))}
					</InfiniteScroll>
				</div>
			</div>
		</div>
	);
}
