import React, { useState } from 'react';
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import './address-new.scss';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomButton from '../../button/button';
import PlaceInput from "../../forms/place-input";
import PhoneInput from "../../forms/phone-input";
import Routes, { post } from "../../../services/api";
import { geolocateUser } from "../../../utils/geo";
import { useLocale } from "../../../locale";

export default function AddressNew(props) {
	const dispatch = useDispatch();
	const history = useHistory();
	const locales = useLocale();

	const [address, setAddress] = useState({
		addressName: "",
		position: { lat: null, lng: null },
		address: "",
		mainPhone: "",
		secondaryPhone: "",
		isDefaultAddress: false
	});

	const handles = {
		inputChange: (event) => {
			event.persist();
			setAddress(prev => ({ ...prev, [event.target.name]: event.target.value }));
		}
	};

	const geolocate = async () => {
		const { address, lat, lng } = await geolocateUser();
		setAddress(prev => ({
			...prev,
			address,
			lat,
			lng
		}));
	};

	const saveAddress = () => {
		if (address.addressName && address.address && address.mainPhone) {
			post(Routes.users.post.address(address), data => {
				history.goBack();
			}, error => {
				dispatch({ type: "SET_ERROR", value: error });
			});
		} else {
			dispatch({ type: "SET_ERROR", value: locales.errors.required.generic });
		}
	};

	return (
		<div id="address-new">
			<div className="form-area">
				<h1>{locales.dashboard.addresses.new.title}</h1>
				<div className="form-group">
					<label>{locales.inputs.address_name} *</label>
					<input type="text" name="addressName" className="form-control" value={address.addressName} onChange={handles.inputChange} />
				</div>
				<div className="form-group">
					<CustomButton onClick={geolocate} type="transparent" width="100%" height="48px">
						{locales.inputs.geolocation}
					</CustomButton>
				</div>
				<div className="form-group">
					<label>{locales.common.address} *</label>
					<PlaceInput
						placeholder=""
						address={address.address || ""}
						selectPlaceCallback={(address, latLng) => setAddress(prev => ({
							...prev,
							address,
							position: { ...latLng }
						}))}
						onChangeCallback={newAddress => {
							setAddress(prev => ({ ...prev, address: newAddress }));
						}}
					/>
				</div>
				<div className="form-group">
					<label>{locales.common.main_phone_number} *</label>
					<PhoneInput
						value={address.mainPhone}
						onChange={phone => setAddress(prev => ({
							...prev,
							mainPhone: phone
						}))}
					/>
				</div>
				<div className="form-group">
					<label>{locales.common.secondary_phone_number}</label>
					<PhoneInput
						value={address.secondaryPhone}
						onChange={phone => setAddress(prev => ({
							...prev,
							secondaryPhone: phone
						}))}
					/>
				</div>
				<div className="form-group">
					<div className="check-control">
						<FormControlLabel
							checked={!!address.isDefaultAddress}
							onChange={event => setAddress(prev => ({
								...prev,
								isDefaultAddress: event.target.checked
							}))}
							control={<Checkbox color="default" />}
							label={locales.delivery.default_address}
							labelPlacement="end"
						/>
					</div>
				</div>
				<div className="form-group">
					<CustomButton onClick={saveAddress} type="primary" width="100%" height="48px">
						{locales.dashboard.addresses.new.validate}
					</CustomButton>
				</div>
			</div>
		</div>
	)
}