import React from 'react';

import './left-image-action.scss';
import Button from '@material-ui/core/Button';
import AppScreenImage from '../../assets/image/app-screen.png';
import AppStoreImage from '../../assets/image/appstore.png';
import PlayStoreImage from '../../assets/image/playstore.png';
import { emphasizeWord } from "../../utils/strings";
import { useLocale } from "../../locale";

export default function LeftImageAction(props) {
	const locales = useLocale();

	return (
		<div id="left-image-action">
			<div className="page-container">
				<div className="row">
					<div className="column">
						<div className="image-area">
							<img className="img-big" src={AppScreenImage} alt="" />
						</div>
					</div>
					<div className="column">
						<div className="action-area">
							<h3>{emphasizeWord(locales.home.app_info.title, "BTPMAT")}</h3>
							<p>{locales.home.app_info.info1}</p>
							<div className="app-button-area">
								<Button href="https://apps.apple.com/ca/app/btpmat/id1507413020?ign-mpt=uo%3D2">
									<img src={AppStoreImage} alt="" />
								</Button>
								<Button href="https://play.google.com/store/apps/details?id=ci.btpmat.app">
									<img src={PlayStoreImage} alt="" />
								</Button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}