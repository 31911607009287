import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./product-card.scss";
import ShoppingBag from "../../assets/image/shopping-bag.svg";
import PlusIcon from "../../assets/image/plus.svg";
import MinusIcon from "../../assets/image/minus.svg";
import CustomButton from "../button/button";
import ButtonFeedback from "../button-feedback/button-feedback";
import NoPhoto from "../../assets/image/nophoto.png";

import config from "../../config";
import { formatPrice } from "../../utils/numeric";
import { ellipsize } from "../../utils/strings";
import { useLocale } from "../../locale";

export default function ProductCard(props) {
    const dispatch = useDispatch();
    const locales = useLocale();

    const product = props.state;

    const [quantity, setQuantity] = useState(product.quantityInCart || 1);
    const [feedbackLabel, setFeedbackLabel] = useState("");

    useEffect(() => {
        setQuantity(product.quantityInCart || 1);
    }, [product.quantityInCart]);

    const setQuantityInCart = event => {
        const actionType = product.quantityInCart ? "edit" : "add";
        if (quantity >= 1) {
            dispatch({
                type: "SET_PRODUCT_QUANTITY_IN_CART",
                value: {
                    productId: product.productId,
                    quantity,
                },
            });

            setFeedbackLabel(
                {
                    add: locales.products.added,
                    edit: locales.products.quantity_set,
                }[actionType]
            );
            setTimeout(() => setFeedbackLabel(""), 3000);
        }

        event.stopPropagation();
        event.preventDefault();
        return false;
    };

    const onQuantityInputChange = event => {
        setQuantity(parseInt(event.target.value) || 1);

        event.stopPropagation();
        event.preventDefault();
        return false;
    };

    const effectivePrice = product.reducedPrice ? product.reducedPrice : product.originalPrice;

    return (
        <div className={`product-card ${props.inGrid ? 'in-grid' : ''}`}>
            <Link
                to={`/produit/${product.productId}-${product.slug}`}
                onClick={() => dispatch({ type: "RESET_FILTERS" })}
            >
                <div className="card-container">
                    <div className="featured-area">
                        {product.images[0] !== undefined ? (
                            <div
                                className="featured-image-box"
                                style={{
                                    backgroundImage:
                                        "url(" +
                                        config.files.productImages +
                                        "/" +
                                        product.images[0] +
                                        ")",
                                }}
                            />
                        ) : (
                            <div
                                className="featured-image-box"
                                style={{
                                    backgroundImage: "url(" + NoPhoto + ")",
                                }}
                            />
                        )}
                    </div>

                    <div className="brand-area">
                        <img
                            className="brand-image"
                            src={`${config.files.brands}/${product.logo}`}
                            alt=""
                        />
                    </div>
                    <div className="title-area">
                        <h3>{product.name}</h3>
                    </div>
                    <div className="title-area-mobile">
                        <h3>{ellipsize(product.name, 36)}</h3>
                    </div>
                    <div className="sku-area">
                        <p>{product.reference}</p>
                    </div>
                    {effectivePrice ? (
                        <div className="price-area">
                            <p className="sale-price">{formatPrice(effectivePrice, true)}</p>
                            {product.reducedPrice ? (
                                <p className="regular-price">
                                    {formatPrice(product.originalPrice, true)}
                                </p>
                            ) : null}
                        </div>
                    ) : null}
                </div>
            </Link>
            {!props.hideControls ? (
                <div className="action-container">
                    {effectivePrice ? (
                        <>
                            <div className="quantity-area">
                                <CustomButton
                                    onClick={event => {
                                        setQuantity(prev => prev - 1);
                                        event.stopPropagation();
                                        event.preventDefault();
                                        return false;
                                    }}
                                    width="34px"
                                    height="34px"
                                    type="primary"
                                    hidden={quantity <= 1}
                                >
                                    <img src={MinusIcon} alt="" />
                                </CustomButton>
                                <input
                                    type="text"
                                    value={quantity}
                                    placeholder=""
                                    onClick={event => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        return false;
                                    }}
                                    onChange={onQuantityInputChange}
                                />
                                <CustomButton
                                    onClick={event => {
                                        setQuantity(prev => prev + 1);
                                        event.stopPropagation();
                                        event.preventDefault();
                                        return false;
                                    }}
                                    width="34px"
                                    height="34px"
                                    type="primary"
                                >
                                    <img src={PlusIcon} alt="" />
                                </CustomButton>
                            </div>
                            <div className="action-area">
                                {feedbackLabel ? (
                                    <ButtonFeedback width="124px" height="34px">
                                        <img src={ShoppingBag} alt="" />
                                        {feedbackLabel}
                                    </ButtonFeedback>
                                ) : (
                                    <CustomButton
                                        onClick={setQuantityInCart}
                                        width="124px"
                                        height="34px"
                                        type="primary"
                                        title={
                                            product.quantityInCart
                                                ? locales.products.set
                                                : locales.products.add
                                        }
                                    >
                                        <img src={ShoppingBag} alt="" />
                                        <span className="set-quantity-label">
                                            {product.quantityInCart
                                                ? locales.products.set
                                                : locales.products.add}
                                        </span>
                                    </CustomButton>
                                )}
                            </div>
                        </>
                    ) : (
                        <CustomButton
                            href={`/contact/${product.productId}`}
                            width="100%"
                            height="34px"
                            type="primary"
                            className="ask-quotation-button"
                        >
                            {locales.shop.quotation.title}
                        </CustomButton>
                    )}
                </div>
            ) : null}
        </div>
    );
}
