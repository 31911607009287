import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Header1 from "../components/header/header1";
import Hero from "../components/hero/hero";
import Supplier from "../components/supplier/supplier";
import Expertise from "../components/expertise/expertise";
import Reviews from "../components/reviews/reviews";
import Contact from "../components/contact/contact";
import LeftImageAction from "../components/left-image-action/left-image-action";
import ProductsTile from "../components/products-tile/products-tile";
import Footer from "../components/footer/footer";
import Spacer from "../components/spacer/spacer";
import ProductsSelectors from "../selectors/products";

import Routes, { post, get } from "../services/api";
import HomeCategories from "../components/home-categories/home-categories";
import HomeOffers from "../components/home-offers/home-offers";

const env = {
    title: "main",
};

function HomePage() {
    const products = useSelector(state => ProductsSelectors.applyFilters(state));
    
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    useEffect(() => {
        if (user && user.userId) {
            get(Routes.products.get.withDiscounts(user.userId), data => {
                dispatch({ type: "SET_PRODUCTS", value: data.products });
            }, err => console.log('errrrrr home', err));
        }
    })

    const filters = useSelector(state => state.shop.filters);
    return (
        <>
            <Header1 />
            {filters.search ? (
                <ProductsTile products={products} style={{ marginTop: 40 }} />
            ) : (
                <>
                    <Hero/>
                    <HomeOffers />
                    <HomeCategories/>
                    <Supplier />
                    <Expertise />
                    <Reviews />
                    <Spacer height="122" />
                    <Contact pageData={env} />
                    <div className="home-left-image-box">
                        <LeftImageAction />
                    </div>
                </>
            )}
            <Footer />
        </>
    );
}

export default HomePage;
