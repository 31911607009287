import React from 'react';
import './mini-cart-header.scss';
import PhoneHelpIcon from '../../../assets/image/icons-phone-help.svg';
import BackIcon from '../../../assets/image/icons-back-black.svg';
import CustomButton from '../../button/button';
import IconButton from '@material-ui/core/IconButton';
import { useLocale } from "../../../locale";

export default function MiniCartHeader(props) {
	const locales = useLocale();

	return (
		<div id="mini-cart-header">
			<div className="row">
				<IconButton aria-label="delete" size="small" onClick={props.handles.closeModal} className="leftPosition">
					<img src={BackIcon} className="back-icon" alt="" />
				</IconButton>
				<h3 className="title">{locales.cart.title}</h3>
				<CustomButton href="/contact" type="primary" width="100px" height="34px" className="rightPosition help">
					<img src={PhoneHelpIcon} alt="" />
					{locales.common.help}
				</CustomButton>
			</div>
		</div>
	)
}