import axios from "axios";

import config from "../config";

export const getAddressFromLatLng = async ({ lat, lng }) => {
	try {
		const res = await axios({
			method: "get",
			url: "https://maps.googleapis.com/maps/api/geocode/json",
			params: {
				latlng: [lat, lng].join(","),
				key: config.googleApiKey
			}
		});

		if (res.status === 200 && res.data.status === "OK") {
			return res.data.results[0].formatted_address;
		} else {
			throw new Error();
		}
	} catch (err) {
		console.error(err);
	}
}

export const geolocateUser = async () => {
	const getCurrentPosition = () => new Promise((resolve, reject) => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(position => {
				resolve({
					lat: position.coords.latitude,
					lng: position.coords.longitude
				});
			});
		} else {
			reject("unsupported");
		}
	})

	// works only in HTTPS
	const position = await getCurrentPosition();

	//TODO TEST
	// const position = { lat: 48.871662099999995, lng: 2.3504359 };

	const address = await getAddressFromLatLng(position)

	// console.log({
	// 	address,
	// 	...position
	// })

	return {
		address,
		...position
	};
};