import React, { useState } from "react";
import { useDispatch } from "react-redux";

import "./single-product-view.scss";
import SingleProductGallery from "../single-product-gallery/single-product-gallery";
import ProductExpertise from "../product-expertise/product-expertise";
import PlusIcon from "../../assets/image/plus.svg";
import MinusIcon from "../../assets/image/minus.svg";
import ShoppingBag from "../../assets/image/shopping-bag.svg";

import PlusSectionIcon from '../../assets/image/plus.png';
import MinusSectionIcon from '../../assets/image/minus-sign.png';

import CustomButton from "../button/button";
import ButtonFeedback from "../button-feedback/button-feedback";

import config from "../../config";
import { formatPrice } from "../../utils/numeric";
import { formatText } from "../../utils/strings";
import { useLocale } from "../../locale";

export default function SingleProductView({ product }) {
    const dispatch = useDispatch();
    const locales = useLocale();

    const [quantity, setQuantity] = useState(product.quantityInCart || 1);
    const [feedbackLabel, setFeedbackLabel] = useState("");
    const [foldableHeight, setFoldableHeight] = useState('0px')

    const setQuantityInCart = () => {
        const actionType = product.quantityInCart ? "edit" : "add";
        if (quantity >= 1) {
            dispatch({
                type: "SET_PRODUCT_QUANTITY_IN_CART",
                value: {
                    productId: product.productId,
                    quantity,
                },
            });

            setFeedbackLabel(
                {
                    add: locales.products.added,
                    edit: locales.products.quantity_set,
                }[actionType]
            );
            setTimeout(() => setFeedbackLabel(""), 3000);
        }
    };

    const productImages = product.images.map(
        (image) => `${config.files.productImages}/${image}`
    );

    const effectivePrice = product.reducedPrice
        ? product.reducedPrice
        : product.originalPrice;

    let discount = 0;

    if (product.reducedPrice) {
        discount = Math.round(
            (1 - product.reducedPrice / product.originalPrice) * 100
        );
    }

    return (
        <div id="single-product-view">
            <div className="page-container">
                <div className="row">
                    <div className="column">
                        <div>
                            <SingleProductGallery
                                images={productImages}
                                video={
                                    product.video
                                        ? `${config.files.productVideos}/${product.video}`
                                        : null
                                }
                                featuredImage={productImages[0]}
                            />
                        </div>
                    </div>
                    <div className="column">
                        <div className="brand-area">
                            <img
                                src={`${config.files.brands}/${product.logo}`}
                                alt=""
                            />
                        </div>
                        <div className="title-area">
                            <p>{product.name}</p>
                        </div>
                        <div className="sku-area">
                            <p>
                                {locales.products.reference}:{" "}
                                {product.reference}
                            </p>
                        </div>
                        <div className="description-area">
                            {formatText(product.description)}
                        </div>
                        <div className="tag-area">
                            <ProductExpertise />
                        </div>
                    </div>
                    <div className="column text-center">
                        {discount ? (
                            <div className="label-area">
                                <span className="badge-danger">
                                    -{discount}%
                                </span>
                            </div>
                        ) : null}
                        {effectivePrice ? (
                            <>
                                <div className="price-area">
                                    <p className="sale-price">
                                        {formatPrice(
                                            effectivePrice,
                                            true,
                                            null
                                        )}
                                        <small className="currency">XOF</small>
                                    </p>
                                    {product.reducedPrice ? (
                                        <p className="regular-price">
                                            {formatPrice(
                                                product.originalPrice,
                                                true,
                                                null
                                            )}
                                            <small className="currency">
                                                XOF
                                            </small>
                                        </p>
                                    ) : null}
                                </div>
                                <div className="quantity-area">
                                    <CustomButton
                                        type="primary"
                                        onClick={() =>
                                            setQuantity((prev) => prev - 1)
                                        }
                                        className={`minus-button ${
                                            quantity > 1 ? "" : "hidden"
                                        }`}
                                    >
                                        <img src={MinusIcon} alt="" />
                                    </CustomButton>
                                    <input
                                        type="text"
                                        value={quantity}
                                        placeholder=""
                                        onChange={(e) =>
                                            setQuantity(
                                                parseInt(e.target.value) || 1
                                            )
                                        }
                                    />
                                    <CustomButton
                                        type="primary"
                                        onClick={() =>
                                            setQuantity((prev) => prev + 1)
                                        }
                                        className="plus-button"
                                    >
                                        <img src={PlusIcon} alt="" />
                                    </CustomButton>
                                </div>
                            </>
                        ) : null}
                        <div className="button-area">
                            {effectivePrice ? (
                                <>
                                    {feedbackLabel ? (
                                        <ButtonFeedback
                                            width="268px"
                                            height="34px"
                                            marginTop="12px"
                                        >
                                            <img src={ShoppingBag} alt="" />
                                            {feedbackLabel}
                                        </ButtonFeedback>
                                    ) : (
                                        <CustomButton
                                            type="primary"
                                            onClick={setQuantityInCart}
                                            className="addCart"
                                            disabled={!!feedbackLabel}
                                        >
                                            <img src={ShoppingBag} alt="" />
                                            {product.quantityInCart
                                                ? locales.products.set_cart
                                                : locales.products.add_to_cart}
                                        </CustomButton>
                                    )}
                                </>
                            ) : (
                                <CustomButton
                                    type="primary"
                                    className="askQuotation"
                                    href={`/contact/${product.productId}`}
                                >
                                    <span>
                                        {locales.shop.quotation.title}
                                    </span>
                                </CustomButton>
                            )}
                            {/* <CustomButton
                                type="transparent"
                                className="addWishlist"
                            >
                                <img src={heartIcon} alt="" />
                                <span>{locales.products.add_to_wishlist}</span>
                            </CustomButton> */}
                            {product.file ? (
                                <CustomButton
                                    type="transparent"
                                    className="downloadProductFile"
                                    href={`${config.files.productFiles}/${product.file}`}
                                    target="_blank"
                                >
                                    <span>
                                        {locales.products.download_file}
                                    </span>
                                </CustomButton>
                            ) : null}
                        </div>
                    </div>
                </div>
            </div>
            <div className="page-container ">
                <div className="technologies">
                    <div 
                        className="collapsible" 
                        onClick={() => setFoldableHeight(foldableHeight === '0px' ? '100vh' : '0px')}
                    >
                        <div style={{ flex: 1 }}/>
                        <h3 style={{ flex: 1 }}>{locales.products.specs}</h3>
                        <div 
                            className="collapsible-arrow-container"
                        >
                            <img 
                                src={foldableHeight === '0px' ? PlusSectionIcon : MinusSectionIcon} 
                                alt=""
                                style={{ transform: `rotate(${foldableHeight === '0px' ? '0' : '360deg'})`, width: '30px', height: '30px' }}
                            />
                        </div>
                    </div>
                    <div className="collapsible-content" style={{ maxHeight: foldableHeight }}>
                        <div className="content-inner">
                            <div>{formatText(product.technicalSpecifications)}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
