const initialState = {
	orderId: null,
	email: "",
	phone: "",
	quoteId: null, // utilisé pour régler un devis provenant d'une demande de devis
	coupon: null,
	deliveryType: "", // TO_ADDRESS|PICKUP_SHOP|PICKUP_WORKSHOP|CLICK_AND_COLLECT
	deliveryPartnerId: null,
	deliveryAddress: {
		userAddressName: "",
		address: "",
		position: { lat: null, lng: null },
		phone1: "",
		phone2: ""
	}
};

export default function orderReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_ORDER_ID":
			return { ...state, orderId: action.value };
		case "SET_ORDER_EMAIL":
			return { ...state, email: action.value };
		case "SET_ORDER_PHONE":
			return { ...state, phone: action.value };
		case "SET_ORDER_QUOTE_ID":
			return { ...state, quoteId: action.value };
		case "RESET_ORDER_ID":
			return { ...state, orderId: initialState.orderId };
		case "SET_COUPON":
			return { ...state, coupon: action.value };
		case "RESET_COUPON":
			return { ...state, coupon: initialState.coupon };
		case 'SET_DELIVERY_TYPE':
			return { ...state, deliveryType: action.value };
		case "SET_DELIVERY_PARTNER":
			return { ...state, deliveryPartnerId: action.value };
		case "SET_DELIVERY_ADDRESS":
			return { ...state, deliveryAddress: action.value };
		case 'RESET_PAYMENT_DATA':
			return {
				...state,
				orderId: initialState.orderId,
				email: initialState.email,
				phone: initialState.phone,
				quoteId: initialState.quoteId
			};
		case "RESET_ORDER_DATA":
			return initialState;
		default:
			return state;
	}
}
