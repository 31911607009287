import { useState, useEffect } from "react";

const useResize = (ref) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    useEffect(() => {
        if (ref.current) {
            setWidth(ref.current ? ref.current.offsetWidth : 0);
            setHeight(ref.current ? ref.current.offsetHeight : 0);
        }

        const onResize = () => {
            setWidth(ref.current ? ref.current.offsetWidth : 0);
            setHeight(ref.current ? ref.current.offsetHeight : 0);
        };

        window.addEventListener("resize", onResize);

        return () => {
            window.removeEventListener("resize", onResize);
        };
    }, [ref]);

    return { width, height };
};

export { useResize };
