import React from 'react';
import './empty.scss';
import EmptyCartImage from '../../../assets/image/empty-cart.svg';
import { useLocale } from "../../../locale";
import { renderAsTextComponent } from "../../../utils/strings";

export default function MiniCartEmpty(props) {
	const locales = useLocale();

	return (
		<div>
			<div className="image-container">
				<img src={EmptyCartImage} alt="" />
			</div>
			<p>{renderAsTextComponent(locales.cart.fallback)}</p>
		</div>
	)
}