import React from 'react';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';

import './breadcrumb.scss';
import { useLocale } from "../../locale";

export default function SimpleBreadcrumbs(props) {
	const location = useLocation();
	const locales = useLocale();

	const path = location.pathname.split("/")[1];

	const rootLink = {
		"boutique": "/boutique",
		"sub-category": "/boutique",
		"produit": "/boutique",
		"actualites": "/actualites",
		"actualite": "/actualites",
		"sub-actualites": "/actualites",
	}[path] || "/boutique";

	return (
		<div id="simple-breadcrumbs" style={props.style}>
			<div className="page-container">
				<Breadcrumbs aria-label="breadcrumb">
					<Link color="inherit" to={rootLink}>
						{
							["boutique", "sub-category", "produit"].includes(path)
								? locales.menus.shop
								: locales.menus.news
						}
					</Link>
					<p color="textPrimary">{props.title}</p>
				</Breadcrumbs>
			</div>
		</div>
	);
}