import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./login.scss";

import Routes, { post, get } from "../../../services/api";
import { useLocale } from "../../../locale";

import CustomButton from "../../button/button";

export default function Login(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");

	const login = async () => {
		post(Routes.users.post.login({ username, password }), data => {
			dispatch({ type: "SET_USER_DATA", value: data.user });  
            get(Routes.products.get.withDiscounts(data.user.userId), data => {
                dispatch({ type: "SET_PRODUCTS", value: data.products });
            }, err => console.log('errrrrr', err));
            props.setCurrentScreen("login-result");
		}, error => {
			console.log("error", error)
			dispatch({ type: "SET_ERROR", value: error });
		});
	};

	return (
		<div id="login">
			<h1>{locales.onboarding.go_to_login}</h1>
			<div className="form">
				<div className="row">
					<div className="form-group">
						<label htmlFor="address">
							{locales.inputs.email_or_phone}
						</label>
						<input
							className="form-control"
							placeholder={locales.common.email_or_phone}
							name="user"
							id="user"
							onChange={e => setUsername(e.target.value)}
							value={username}
						/>
					</div>
				</div>
				<div className="row">
					<div className="form-group">
						<label htmlFor="address">{locales.inputs.password}</label>
						<input
							className="form-control"
							placeholder="•••••••••"
							name="password"
							id="password"
							type="password"
							onChange={e => setPassword(e.target.value)}
							value={password}
						/>
					</div>
				</div>
				<div className="row">
					<button
						className="normal-link"
						onClick={props.stateSwitch}
						value="forget"
					>
						{locales.onboarding.reset_password}
					</button>
				</div>
				<div className="row">
					<CustomButton onClick={login} type="glassDark" width="100%" height="48px">
						{locales.onboarding.go_to_login}
					</CustomButton>
				</div>
				<div className="row">
					<span className="explain-text">
						{locales.onboarding.login.go_to_signup.instruction1}
						<button
							className="normal-link"
							onClick={props.stateSwitch}
							value="role"
						>
							{locales.onboarding.login.go_to_signup.cta1}
						</button>
					</span>
				</div>
			</div>
		</div>
	);
}
