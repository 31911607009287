const combineFilters = (...filterFunctions) => data => {
	let newData = data;
	for (const filterFunction of filterFunctions) {
		if (filterFunction) {
			newData = filterFunction(newData);
		}
	}
	return newData;
};

export {
	combineFilters
};
