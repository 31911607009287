import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import "./footer.scss";

import CustomButton from "../../button/button";
import { Link } from "react-router-dom";
import Coupon from "../coupon/coupon";
import { useLocale } from "../../../locale";

export default function MiniCartFooter({ step, setStep, productsCount, totalPrice, exportAsQuote }) {
	const history = useHistory();
	const locales = useLocale();

	const user = useSelector(state => state.user.data);

	const [exporting, setExporting] = useState(false);

	const onCheckoutButtonClick = () => {
		if (user.token) {
			history.push("/checkout");
		} else {
			setStep("auth");
		}
	};

	const onExportButtonClick = async () => {
		if (user.token) {
			setExporting(true);
			await exportAsQuote(() => setExporting(false));
		} else {
			setStep("auth_export");
		}
	};

	const StepsComponents = {
		default: () =>
			productsCount === 0 ? (
				<div>
					<CustomButton
						href="/boutique"
						type="primary"
						width="100%"
						height="48px"
					>
						{locales.onboarding.go_to_shop}
					</CustomButton>
					<Link to="/contact">
						<CustomButton
							type="transparent"
							width="100%"
							height="48px"
							className="checkout-button"
						>
							{locales.shop.ask_quote}
						</CustomButton>
					</Link>
				</div>
			) : (
					<>
						<Coupon cartTotalPrice={totalPrice} />
						<div>
							<CustomButton
								onClick={onExportButtonClick}
								type="transparent"
								width="100%"
								height="48px"
								loading={exporting}
							>
								{locales.cart.export_quote.title}
							</CustomButton>
							<CustomButton
								onClick={onCheckoutButtonClick}
								type="primary"
								width="100%"
								height="48px"
								className="checkout-button"
							>
								{locales.cart.go_to_order.title}
							</CustomButton>
						</div>
					</>
				),
	};

	const ContentComponent = StepsComponents[step];

	return step && ContentComponent ? (
		<div id="mini-cart-footer">
			<ContentComponent />
		</div>
	) : null;
}
