import React from 'react';
import { useDispatch, useSelector } from "react-redux";

import './confirm.scss';
import CustomButton from '../../button/button';
import Routes, { patch } from "../../../services/api";
import { resetOrderData } from "../../../utils/orders";
import { useLocale } from "../../../locale";

export default function PaymentConfirm(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const order = useSelector(state => state.order);

	const goToNext = event => {
		event.persist();

		patch(Routes.orders.patch.paymentMode(order.orderId, "SHOP"), data => {
			resetOrderData(dispatch);
			props.handles.nextAction(event);
		});
	};

	return (
		<div id="payment-confirm">
			<div className="page-container">
				<h1 className="page-title">{locales.common.confirmation}</h1>
				<p className="page-description">{locales.payment.shop.instruction1}</p>
				<div className="form-container">
					<h2>COCODY, RIVIERA PALMERAIE SAINT VIATEUR, Abidjan, Cote d'ivoire</h2>
					<p className="payment-number">07 86 46 03 20</p>
					<p className="payment-explain">{locales.payment.shop.instruction2}</p>
					<CustomButton type="primary" width="100%" height="48px" onClick={goToNext} value="paymentSuccess">{locales.payment.validate_order}</CustomButton>
				</div>
			</div>
		</div>
	)
}