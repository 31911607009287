import React from 'react';
import './modal.scss';

import WhiteLogo from '../../assets/image/white-logo.svg';
import CloseIcon from '../../assets/image/close-button.svg';
import IconButton from '@material-ui/core/IconButton';

export default class Modal extends React.Component {
	constructor(props) {
		super(props);
		this.state = { width: 0, height: 0 };
		this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	}

	componentDidMount() {
		this.updateWindowDimensions();
		window.addEventListener('resize', this.updateWindowDimensions);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.updateWindowDimensions);
	}

	updateWindowDimensions() {
		this.setState({ width: window.innerWidth, height: window.innerHeight });
	}

	render() {
		return (
			<>
				<div className={this.props.state.isModal ? "modal-area active-modal" : "modal-area"} style={this.state}>
					<div className="modal modal-large">
						<div className="modal-header">
							<img src={WhiteLogo} alt="" />
						</div>
						<div className="modal-body" style={{ width: this.state.width }}>
							<IconButton aria-label="delete" className="close-button" size="small" onClick={this.props.handles.closeModal}>
								<img src={CloseIcon} alt="" className="close-icon" />
							</IconButton>
							{this.props.children}
						</div>
					</div>
				</div>
			</>
		)
	}
}