import React from 'react';
import './expertise.scss';
import CustomButton from '../button/button';

import LeftTopImage from '../../assets/image/landing-icons-bot-2-copy.svg';
import Cart2Image from '../../assets/image/welcome3.jpg';
import Cart3Image from '../../assets/image/welcome2.jpg';
import Cart4Image from '../../assets/image/landing-icons-fast-2-copy.svg';
import Cart5Image from '../../assets/image/welcome4.jpg';
import Cart6Image from '../../assets/image/welcome1.jpg';
import Cart7Image from '../../assets/image/landing-icons-service-client-2-copy.svg';

import { useLocale } from "../../locale";

function Expertise() {
	const locales = useLocale();

	return (
		<div id="expertise">
			<div className="page-container">
				<div className="row">
					<div className="back-bar"></div>
					<div className="center-block">
						<h3>{locales.home.expertise.suptitle_web}</h3>
						<h1>{locales.home.expertise.title_web}</h1>
					</div>
					<div className="back-bar"></div>
				</div>
			</div>
			<div className="expertise-container">
				<div className="row">
					<div className="expertise-cart cart1">
						<h4>
							{locales.home.expertise.info1}
						</h4>
						<img src={LeftTopImage} alt="" className="left-top" />
					</div>
					<div className="expertise-cart cart2">
						<img src={Cart2Image} alt="" />
					</div>
				</div>
				<div className="row">
					<div className="expertise-cart cart3">
						<img src={Cart3Image} alt="" />
					</div>
					<div className="expertise-cart cart4">
						<div>
							<img src={Cart4Image} alt="" />
							<h4>
								{locales.home.expertise.info2}
							</h4>
						</div>
					</div>
					<div className="expertise-cart cart5">
						<img src={Cart5Image} alt="" />
					</div>
				</div>
				<div className="row">
					<div className="expertise-cart cart6">
						<img src={Cart6Image} alt="" />
					</div>
					<div className="expertise-cart cart7">
						<div>
							<img src={Cart7Image} alt="" />
							<h4>
								{locales.home.expertise.info3}
							</h4>
						</div>
					</div>
				</div>
			</div>
			<div className="page-container text-center">
				<div className="row">
					<CustomButton width="276px" height="48px" type="primary" href="#contact">
						{locales.shop.ask_quote}
					</CustomButton>
				</div>
			</div>
		</div>
	);
}

export default Expertise