import React from 'react';

import Header1 from '../components/header/header1';
import Devis from '../components/account/devis/devis';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';

export default function AccountDevisPage() {
	return (
		<>
			<Header1 />
			<Devis />
			<IconActions />
			<Footer />
		</>
	)
}