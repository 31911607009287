import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./coupon.scss";
import TextField from "@material-ui/core/TextField";

import Routes, { get } from "../../../services/api";
import OrdersSelectors from "../../../selectors/orders";
import { isCouponGlobal } from "../../../utils/orders";
import { formatPrice } from "../../../utils/numeric";
import { useLocale } from "../../../locale";

const isCouponApplicable = (cart, coupon) => {
    if (isCouponGlobal(coupon)) {
        return true;
    }

    const cartData = cart.reduce(
        (acc, product) => ({
            productsIds: [...acc.productsIds, product.productId],
            categoriesIds: [...acc.categoriesIds, ...product.categories],
            subCategoriesIds: [...acc.subCategoriesIds, ...product.subCategories]
        }),
        {
            productsIds: [],
            categoriesIds: [],
            subCategoriesIds: []
        }
    );

    return (
        (coupon.products && coupon.products.some(id => cartData.productsIds.includes(id))) || 
        (coupon.categories && coupon.categories.some(id => cartData.categoriesIds.includes(id))) ||
        (coupon.subCategories && coupon.subCategories.some(id => cartData.subCategoriesIds.includes(id)))
    );
};

export default function Coupon() {
	const dispatch = useDispatch();
	const locales = useLocale();

	const user = useSelector(state => state.user.data);
	const coupon = useSelector(state => state.order.coupon);
	
	const orderData = useSelector(state => OrdersSelectors.orderData(state));
	
	const [couponName, setCouponName] = useState("");

	const submitCoupon = () => {
		get(
			Routes.coupons.get.couponByName(couponName, user.userId),
			data => {
				if (isCouponApplicable(orderData.products, data.coupon)) {
					dispatch({ type: "SET_COUPON", value: data.coupon });
					dispatch({ type: "SET_ERROR", value: null });
                } else {
					dispatch({ type: "SET_ERROR", value: locales.cart.coupon.errors.specific });
				}
			},
			error => {
				setCouponName("");
				dispatch({ type: "SET_ERROR", value: error });
			}
		);
	};

	const resetCoupon = () => {
		dispatch({ type: "RESET_COUPON" });
	};

	return (
		<div id="coupon">
			{coupon ? (
				<>
					<p>
						<span className="coupon-name">{coupon.couponName}</span>{" "}
						{formatPrice(-orderData.couponEffectiveDiscount, true)}
					</p>
					<button onClick={resetCoupon} className="reset-coupon">
						{locales.common.delete}
					</button>
				</>
			) : (
					<div className="form-group">
						<TextField
							id="standard-basic"
							label={locales.cart.coupon.input}
							className="form-control"
							value={couponName}
							onChange={e => setCouponName(e.target.value)}
						/>
						<button onClick={submitCoupon} className="submit-coupon">
							{locales.common.validate}
						</button>
					</div>
				)}
		</div>
	);
}
