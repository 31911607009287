const initialState = {
	language: "fr",
	keys: {}
};

export default function localeReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_LANGUAGE':
			return {
				...state,
				language: action.value
			};
		case 'SET_LOCALE_KEYS':
			return {
				...state,
				keys: action.value
			};
		default:
			return state;
	}
};
