import React, { useState } from "react";
import { useDispatch } from "react-redux";

import "./register.scss";
import BackIcon from "../../../assets/image/icons-back-black.svg";
import PeopleIcon from "../../../assets/image/icons-people-particulier.svg";
import ApartmentIcon from "../../../assets/image/icons-apartment-company.svg";
import Button from "@material-ui/core/Button";
import CustomButton from "../../button/button";
import PhoneInput from "../../forms/phone-input";

import Routes, { post, get } from "../../../services/api";
import Patterns from "../../../constants/patterns";
import { isPhoneValid } from "../../../utils/inputs";
import { useLocale } from "../../../locale";

export default function Register(props) {
	const dispatch = useDispatch();
	const locales = useLocale();

	const [ssn, setSsn] = useState({
		ssn1: "",
		ssn2: "",
		ssn3: "",
		ssn4: ""
	});

	const [email, setEmail] = useState("");
	const [phone, setPhone] = useState("");
	const [phoneAreaCode, setPhoneAreaCode] = useState("");
	const [countryCode, setCountryCode] = useState("CI");
	const [password, setPassword] = useState("");
	const [type, setType] = useState("");

	const handles = {
		inputChange: event => {
			var name = event.target.name;
			var value = parseInt(event.target.value, 10) % 10;
			var index = name.split("-")[1];
			if (parseInt(index, 10) <= 4) {
				// Get the next input field

				let nextSibling = document.querySelector(
					`input[name=ssn-${parseInt(index, 10) + 1}]`
				);
				if (parseInt(index, 10) == 4) {
					nextSibling = document.querySelector(
						`input[name=ssn-${parseInt(index, 10) - 3}]`
					);
				}
				// If found, focus the next field
				if (nextSibling !== null) {
					nextSibling.focus();
				}
			}
			setSsn(prev => ({
				...prev,
				[`ssn${index}`]: value
			}));
		}
	};

	const sendPhoneVerification = onSuccess => {
		post(
			Routes.users.post.sendPhoneVerification(phone),
			data => {
				onSuccess();
			},
			error => {
				dispatch({ type: "SET_ERROR", value: error });
			}
		);
	};

	const verifyPhone = async event => {
		event.persist();

		const code = [1, 2, 3, 4].reduce(
			(acc, index) => `${acc}${ssn[`ssn${index}`]}`,
			""
		);
		post(
			Routes.users.post.verifyPhone(phone, code),
			async data => {
				await createUser(true, () =>
					props.setCurrentScreen(event.target.value)
				);
			},
			error => {
				dispatch({ type: "SET_ERROR", value: error });
			}
		);
	};

	const createUser = async (phoneValidated, onSuccess) => {
		post(
			Routes.users.post.signup({
				email,
				phone: phoneValidated ? phone : null,
				phoneAreaCode,
				countryCode,
				password,
				type
			}),
			data => {
				dispatch({ type: "SET_USER_DATA", value: data.user });
                get(Routes.products.get.withDiscounts(data.user.userId), data => {
                    dispatch({ type: "SET_PRODUCTS", value: data.products });
                }, err => console.log('errrr', err));
				onSuccess();
			},
			error => {
				dispatch({ type: "SET_ERROR", value: error });
			}
		);
	};

	const onUsernameEntered = (event) => {
		if (Patterns.email.test(email) || isPhoneValid(phone, phoneAreaCode)) {
			props.stateSwitch(event)
		} else {
			dispatch({ type: "SET_ERROR", value: locales.errors.required.email_or_phone });
		}
	};

	const onPasswordEntered = async e => {
		const nextScreen = e.target.value;

		if (Patterns.password.test(password)) {
			if (isPhoneValid(phone, phoneAreaCode)) {
				sendPhoneVerification(() => props.setCurrentScreen(nextScreen));
			} else {
				await createUser(false, () => props.setCurrentScreen(nextScreen));
			}
		} else {
			dispatch({ type: "SET_ERROR", value: locales.errors.patterns.password });
		}
	};

	const renderRegister = param => {
		switch (param) {
			case "role":
				return (
					<div id="register">
						<button
							className="back-button"
							onClick={props.stateSwitch}
							value="login"
						>
							<img src={BackIcon} alt="" />
						</button>
						<h1>{locales.onboarding.signup.type.instruction1}</h1>
						<div className="form">
							<div className="row">
								<div className="form-group">
									<div className="role-area">
										<Button
											className="role-button"
											onClick={e => {
												setType("COMPANY");
												props.stateSwitch(e);
											}}
											value="user"
											variant="contained"
										>
											<div>
												<img src={ApartmentIcon} alt="" />
												<p>{locales.onboarding.signup.type.company}</p>
											</div>
										</Button>
										<Button
											className="role-button"
											onClick={e => {
												setType("INDIVIDUAL");
												props.stateSwitch(e);
											}}
											value="user"
											variant="contained"
										>
											<div>
												<img src={PeopleIcon} alt="" />
												<p>{locales.onboarding.signup.type.individual}</p>
											</div>
										</Button>
									</div>
								</div>
							</div>
						</div>
					</div>
				);
			case "user":
				return (
					<div id="register">
						<h1>{locales.onboarding.signup.username.title}</h1>
						<button
							className="back-button"
							onClick={props.stateSwitch}
							value="role"
						>
							<img src={BackIcon} alt="" />
						</button>
						<div className="form">
							<div className="row">
								<div className="form-group">
									<label htmlFor="email">{locales.inputs.email}</label>
									<input
										className="form-control"
										placeholder={locales.common.email}
										name="email"
										id="email"
										onChange={e => setEmail(e.target.value)}
										value={email}
									/>
								</div>
							</div>
							<div className="between-text">{locales.common.or}</div>
							<div className="row">
								<div className="form-group">
									<label htmlFor="phone">{locales.inputs.phone}</label>
									<PhoneInput
										value={phone}
										country={countryCode}
										onChange={(phone, areaCode, countryCode) => {
											setPhone(phone);
											setPhoneAreaCode(areaCode);
											setCountryCode(countryCode);
										}}
									/>
								</div>
							</div>
							<div className="row">
								<CustomButton
									type="glassDark"
									width="100%"
									height="48px"
									onClick={onUsernameEntered}
									value="password"
								>
									{locales.common.next_step}
								</CustomButton>
							</div>
						</div>
					</div>
				);
			case "password":
				return (
					<div id="register">
						<button
							className="back-button"
							onClick={props.stateSwitch}
							value="user"
						>
							<img src={BackIcon} alt="" />
						</button>
						<h1>{locales.onboarding.signup.password.title}</h1>
						<div className="form">
							<div className="row">
								<div className="form-group">
									<label htmlFor="address">{locales.inputs.password}</label>
									<input
										className="form-control"
										placeholder="•••••••••"
										name="password"
										id="password"
										type="password"
										onChange={e => setPassword(e.target.value)}
										value={password}
									/>
								</div>
							</div>
							<div className="row">
								<CustomButton
									type="glassDark"
									width="100%"
									height="48px"
									onClick={onPasswordEntered}
									value={
										isPhoneValid(phone, phoneAreaCode) ? "verification" : "success"
									}
								>
									{locales.common.next_step}
								</CustomButton>
							</div>
						</div>
					</div>
				);
			case "verification":
				return (
					<div id="register">
						<button
							className="back-button"
							onClick={props.stateSwitch}
							value="password"
						>
							<img src={BackIcon} alt="" />
						</button>
						<h1 className="verification-title">
							{locales.onboarding.signup.phone_confirm.title}
						</h1>
						<div className="form">
							<div className="row">
								<div className="form-group">
									<p>{locales.onboarding.signup.phone.instruction2}</p>
								</div>
								<div className="form-group">
									<div className="verify-area">
										<input
											maxLength={1}
											placeholder="1"
											type="number"
											value={`${ssn.ssn1}`}
											min="0"
											max="9"
											className="verify-input"
											name="ssn-1"
											onChange={handles.inputChange}
										/>
										<input
											maxLength={1}
											placeholder="2"
											type="number"
											value={`${ssn.ssn2}`}
											min="0"
											max="9"
											className="verify-input"
											name="ssn-2"
											onChange={handles.inputChange}
										/>
										<input
											maxLength={1}
											placeholder="3"
											type="number"
											value={`${ssn.ssn3}`}
											min="0"
											max="9"
											className="verify-input"
											name="ssn-3"
											onChange={handles.inputChange}
										/>
										<input
											maxLength={1}
											placeholder="4"
											type="number"
											value={`${ssn.ssn4}`}
											min="0"
											max="9"
											className="verify-input"
											name="ssn-4"
											onChange={handles.inputChange}
										/>
									</div>
								</div>
							</div>
							<div className="row">
								<CustomButton
									type="glassDark"
									width="100%"
									height="48px"
									onClick={verifyPhone}
									value="success"
								>
									{locales.onboarding.phone.check}
								</CustomButton>
							</div>
						</div>
					</div>
				);
			default:
				return <></>;
		}
	};

	return <>{renderRegister(props.state.currentScreen)}</>;
}
