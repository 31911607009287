import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import "./mini-cart.scss";
import MiniCartHeader from "./mini-cart-header/mini-cart-header";
import MiniCartEmpty from "./empty/empty";
import MiniCartFooter from "./footer/footer";
import OrderTotalHeader from "./order-total-header/order-total-header";
import OrderItem from "./order-item/order-item";
import OrderRegister from "./register/register";
import Routes, { post } from "../../services/api";
import ProductsSelectors from "../../selectors/products";
import { getOrderData } from "../../utils/orders";
import config from "../../config";

export default function MiniCart(props) {
	const dispatch = useDispatch();

	const order = useSelector(state => state.order);
	const products = useSelector(ProductsSelectors.cart());

	const [step, setStep] = useState("default");

	const productsCount = products.length;

	const orderData = getOrderData(
		products.map(product => ({ ...product, quantity: product.quantityInCart })),
		order.coupon
	);

	const cartData = products.map(product => ({
		productId: product.productId,
		quantity: product.quantityInCart
	}));

	const exportRoute = Routes.quotes.export(cartData, order.coupon ? order.coupon.couponName : null);

	const exportAsQuote = (onClose) => {
		dispatch({ type: "SET_LOADING", value: true });
		post(exportRoute, async data => {
			await window.open(`${config.files.quoteExport}/devis_${data.quoteId}.pdf`, "_blank");
		},
			error => dispatch({ type: "SET_ERROR", value: error }),
			() => {
				dispatch({ type: "SET_LOADING", value: false });
				if (onClose) {
					onClose();
				}
			}
		);
	};

	return (
		<>
			<div id="mini-cart" className={props.state.isMiniCart ? "open" : ""}>
				<MiniCartHeader handles={props.handles} />
				<div className="mini-cart-container">
					<OrderTotalHeader
						productsCount={productsCount}
						totalPrice={orderData.totalTTC}
					/>
					{products.length === 0 ? (
						<MiniCartEmpty />
					) : (
                        products.map(product => <OrderItem key={product.productId} product={product} />)
					)}
				</div>

				{["auth", "auth_export"].includes(step) ? (
					<OrderRegister step={step} setStep={setStep} exportAsQuote={exportAsQuote} />
				) : (
						<MiniCartFooter
							step={step}
							setStep={setStep}
							productsCount={productsCount}
							totalPrice={orderData.totalTTC}
							exportAsQuote={exportAsQuote}
						/>
					)}
			</div>
			{props.state.isOverlay ? (
				<div className="overLay" onClick={props.handles.closeModal} />
			) : (
					<></>
				)}
		</>
	);
}
