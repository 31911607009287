import { useSelector } from "react-redux";

import ProductsSelectors from "../selectors/products";

export const useProductsSearch = () => {
    const search = useSelector((state) => state.shop.filters.search);
    const products = useSelector((state) =>
        ProductsSelectors.applyFilters(state)
    );

    return { search, products };
};
