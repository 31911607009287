const initialState = {
    error: null,
    loading: false,
	infos: []
};

export default function appReducer(state = initialState, action) {
    switch (action.type) {
        case "SET_ERROR":
            return { ...state, error: action.value };
        case "SET_LOADING":
            return { ...state, loading: action.value };
        case "SET_INFOS":
            return { ...state, infos: action.value };
        default:
            return state;
    }
}
