const initialState = {
	signup: {},
	login: {}
};

export default function onboardingReducer(state = initialState, action) {
	switch (action.type) {
		case 'SET_SIGNUP_DATA':
			return {
				...state,
				signup: {
					...state.signup,
					[action.value.key]: action.value.value
				}
			};
		case 'RESET_SIGNUP_DATA':
			return {
				...state,
				signup: initialState.signup
			};
		case 'SET_LOGIN_DATA':
			return {
				...state,
				login: {
					...state.login,
					[action.value.key]: action.value.value
				}
			};
		case 'RESET_LOGIN_DATA':
			return {
				...state,
				login: initialState.login
			};
		default:
			return state;
	}
};
