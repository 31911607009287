import React, { useState, useEffect } from "react";

import "./faq-page.scss";
import Header1 from "../components/header/header1";
import Footer from "../components/footer/footer";
import Routes, { get } from "../services/api";
import { formatParagraphs } from "../utils/strings";

export default function FaqPage(props) {
    const [questions, setQuestions] = useState([]);

    useEffect(() => {
        get(Routes.webHelpQuestions.get.all(), (data) => {
            setQuestions(data.webHelpQuestions);
        });
    }, []);

    return (
        <>
            <Header1 />
            <div className="page-container faq">
                <div className="questions">
                    {questions.map((question) => (
                        <div
                            key={question.webHelpQuestionId}
                            className="question"
                        >
                            <h2>{question.question}</h2>
                            {formatParagraphs(question.answer)}
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </>
    );
}
