import React from 'react';

import './iconActions.scss';
import IconAction from '../iconAction/iconAction';
import Icon1 from '../../assets/image/landing-icons-bot-2-copy.svg';
import Icon2 from '../../assets/image/landing-icons-fast-2-copy.svg';
import Icon3 from '../../assets/image/landing-icons-service-client-2-copy.svg';

import { useLocale } from "../../locale";

export default function IconActions(props) {
	const locales = useLocale();

	const iconActions = [
		{
			'icon': Icon1,
			'title1': locales.home.expertise.info1
		},
		{
			'icon': Icon2,
			'title1': locales.home.expertise.info2
		},
		{
			'icon': Icon3,
			'title1': locales.home.expertise.info3
		}
	];
	return (
		<div id="icon-actions">
			<div className="page-container">
				<div className="row">
					{
						iconActions.map((item, key) => (
							<IconAction item={item} key={key} />
						))
					}
				</div>
			</div>
		</div>
	)
}