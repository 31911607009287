import React from "react";

import Header1 from "../components/header/header1";
import Footer from "../components/footer/footer";
import Contact from "../components/contact/contact";
import IconActions from "../components/iconActions/iconActions";
import LeftImageAction from "../components/left-image-action/left-image-action";
import ProductsTile from "../components/products-tile/products-tile";
import { useProductsSearch } from "../utils/products";

export default function ContactPage(props) {
    const { search, products } = useProductsSearch();

    const env = {
        title: "contact",
    };

    return (
        <>
            <Header1 />
            {search ? (
                <ProductsTile products={products} style={{ marginTop: 40 }} />
            ) : (
                <>
                    <Contact pageData={env} />
                    <div className="home-left-image-box">
                        <LeftImageAction />
                    </div>
                    <IconActions />
                </>
            )}
            <Footer />
        </>
    );
}
