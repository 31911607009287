import { createSelector } from "reselect";

import { getOrderData } from "../utils/orders";

const getOrders = state => state.user.orders;
const getOrdersSort = state => state.user.filters.orders.sort;

const DeliveryStatuses = ["AWAITING", "PREPARING", "DELIVERING", "DELIVERED"];

const getCartProducts = state =>
	state.shop.products.filter(
		product => product.quantityInCart && product.quantityInCart >= 1
	);

const getCoupon = state => state.order.coupon;

//TODO
const getQuote = state => null;

export default {
	orderData: createSelector(
		getCartProducts,
		getCoupon,
		getQuote,
		(products, coupon, quote) => {
			const formattedProducts = products.map(product => ({
				...product,
				quantity: product.quantityInCart
			}));
			return getOrderData(formattedProducts, coupon, quote);
		}
	),

	applyFilters: createSelector(
		getOrders,
		getOrdersSort,
		(orders, ordersSort) => {
			if (ordersSort === "DATE_DESC") {
				return [...orders].sort(
					(a, b) =>
						a.datetime > b.datetime ? -1 : a.datetime < b.datetime ? 1 : 0
				);
			} else if (ordersSort === "DATE_ASC") {
				return [...orders].sort(
					(a, b) =>
						a.datetime < b.datetime ? -1 : a.datetime > b.datetime ? 1 : 0
				);
			} else if (ordersSort === "STATUS") {
				return [...orders].sort(
					(a, b) =>
						DeliveryStatuses.indexOf(a.deliveryStatus) -
						DeliveryStatuses.indexOf(b.deliveryStatus)
				);
			} else {
				return orders;
			}
		}
	)
};
