import React from 'react';
import Header1 from '../components/header/header1';
import AddresseEdit from '../components/account/address-edit/address-edit';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';
export default function AddressEditPage() {
	return (
		<>
			<Header1 />
			<AddresseEdit />
			<IconActions />
			<Footer />
		</>
	)
}