import React from 'react';

import Header1 from '../components/header/header1';
import EditPassword from '../components/account/edit-password/edit-password';
import IconActions from '../components/iconActions/iconActions';
import Footer from '../components/footer/footer';

export default function AccountEditPasswordPage() {
	return (
		<>
			<Header1 />
			<EditPassword />
			<IconActions />
			<Footer />
		</>
	)
}